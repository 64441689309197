import { find } from 'lodash';
import generatePath from 'urlcat';
import routes from 'routes';
import { flattenRoutes } from './flattenRoutes';

export declare type Params<Key extends string = string> = {
    readonly [key in Key]: string | undefined;
};

export const createRoute = (name: string, params: Params = {}) => {
    const flattened = flattenRoutes(routes);
    const foundRoute = find(flattened, { name });
    if (foundRoute) return generatePath(foundRoute.path, params);
    throw new Error('path not found!');
};
