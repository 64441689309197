import React, { useState } from 'react';
import { useClearUserHistory, useDeleteUserById, User } from 'api';
import { TableRow, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from 'components';
import { useToggle } from 'react-use';
import { useSnackbar } from 'hooks/useSnackbar';
import { LoadingButton } from '@mui/lab';
import { deleteUserCacheUpdate } from 'utils/cache/user';
import { createRoute } from 'utils/createRoute';
import dayJs from 'dayjs';

type Props = {
    user: User;
};

type ActionType = () => any;

export function UsersListRow({ user }: Props) {
    const [open, toggleConfirmation] = useToggle(false);
    const [action, setAction] = useState<ActionType>(() => () => undefined);
    const navigate = useNavigate();

    const {
        mutate: deleteUser,
        variables,
        isLoading: deleteUserIsLoading,
    } = useDeleteUserById();

    const {
        mutate: resetUser,
        variables: resetVars,
        isLoading: resetUserIsLoading,
    } = useClearUserHistory();

    const [enqueueSnackbar] = useSnackbar();
    const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> =
        event => {
            event.preventDefault();
            setAction(() => () => handleDeleteUser(user.id!));
            toggleConfirmation();
        };

    const handleClose = toggleConfirmation;
    const handleResetClick: React.MouseEventHandler<HTMLButtonElement> =
        event => {
            event.preventDefault();
            setAction(
                () => () => handleResetUser(user?.profile?.public?.email || '')
            );
            toggleConfirmation();
        };

    const handleDeleteUser = (userId: string) => {
        handleClose();
        deleteUser(
            {
                id: userId,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar(`User deleted successfully.`);
                    deleteUserCacheUpdate(userId);
                },
                onError: () => {
                    enqueueSnackbar(
                        `Something went wrong when trying to delete user`
                    );
                },
                onSettled: () => {},
            }
        );
    };

    const handleResetUser = (userEmail: string) => {
        handleClose();
        resetUser(
            {
                email: userEmail,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar(`User reset successfully.`);
                },
                onError: () => {
                    enqueueSnackbar(
                        `Something went wrong when trying to reset user`
                    );
                },
                onSettled: () => {},
            }
        );
    };

    const isDeleting = (userId?: string) => {
        if (!userId) return false;
        return variables?.id === userId && deleteUserIsLoading;
    };

    const isResetting = (userEmail?: string) => {
        if (!userEmail) return false;
        return resetVars?.email === userEmail && resetUserIsLoading;
    };

    const getStyles = (styles?: Record<string, any>) => {
        const name = user!.profile!.public!.name!;
        const email = user!.profile!.public!.email!;

        if (
            name?.indexOf('Test') > -1 ||
            name?.startsWith('staging-') ||
            email.endsWith('@heylama.app')
        ) {
            return {
                opacity: 0.4,
                transition: '300ms',
                '&:hover': {
                    opacity: 1,
                },
                ...styles,
            };
        }
        return styles;
    };

    const editUser = () =>
        navigate(createRoute('viewUser', { userId: user.id }));

    return (
        <TableRow
            hover
            key={user.id}
            onDoubleClick={editUser}
            sx={getStyles({
                cursor: 'pointer',
            })}
        >
            <TableCell>{user?.id}</TableCell>
            <TableCell>{user?.profile?.public?.name}</TableCell>
            <TableCell>{user?.profile?.public?.email}</TableCell>
            <TableCell>
                {dayJs(user?.profile?.private?.created).format(
                    'YYYY.MMM.DD ~ HH:MM'
                )}
            </TableCell>

            <TableCell>
                <LoadingButton
                    color='error'
                    variant='outlined'
                    onClick={handleResetClick}
                    loading={isResetting(user?.profile?.public.email)}
                    sx={{ mr: 1 }}
                >
                    Reset
                </LoadingButton>
                <LoadingButton
                    color='error'
                    variant='outlined'
                    onClick={handleDeleteClick}
                    loading={isDeleting(user.id)}
                >
                    Delete
                </LoadingButton>
                <ConfirmModal
                    title='Are you sure?'
                    description="This action can't be undone"
                    onConfirm={() => action()}
                    onCancel={handleClose}
                    open={open}
                />
            </TableCell>
        </TableRow>
    );
}
