import Fuse from 'fuse.js';
import { flow, map, curry } from 'lodash/fp';
import { NO_UNICODE_LETTERS } from 'utils/re';

/* TODO => review this file again */

const performSearch = <T extends any[]>(
    items: any[],
    options: Fuse.IFuseOptions<any>,
    query: string
) =>
    flow(
        cleanSearchQuery,
        setupSearch(items, options),
        map((result: Fuse.FuseResult<T>) => result.item)
    )(query);

const cleanSearchQuery = (query: string) =>
    query.replace(NO_UNICODE_LETTERS, '');

const setupSearch = curry((items: object[], options: {}, query: string) => {
    const fuse = new Fuse(items, options);
    return fuse.search(query);
});

export function search<T>(
    items: T[],
    query: string,
    options: Fuse.IFuseOptions<T>
) {
    if (!query) return items;

    return performSearch(items, options, query) as T[];
}
