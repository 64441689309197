import React from 'react';
import { Routes, useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { create } from 'jss';
import { ThemeProvider } from 'styled-components/macro';

import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import './i18n';
import createTheme from './theme';
import routes from './routes';

import useTheme from './hooks/useTheme';
import { store } from './redux/store';

import { AuthProvider } from './contexts/JWTContext';
import { ReactQueryService } from 'services/reactQuery';
import { SnackbarComponent, SnackbarProvider } from 'hooks/useSnackbar';
import { ErrorBoundary } from 'components';
import { LanguagesProvider } from 'hooks/useLanguages';

const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById('jss-insertion-point')!,
});

function App() {
    const content = useRoutes(routes, { basename: process.env.PUBLIC_URL });

    const { theme } = useTheme();

    return (
        <HelmetProvider>
            <Helmet
                titleTemplate='%s | Heylama Dashboard'
                defaultTitle='Heylama Dashboard'
            />
            <ReactQueryService>
                <Provider store={store}>
                    <StylesProvider jss={jss}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <StyledEngineProvider injectFirst>
                                <MuiThemeProvider theme={createTheme(theme)}>
                                    <ThemeProvider theme={createTheme(theme)}>
                                        <AuthProvider>
                                            <SnackbarProvider>
                                                <SnackbarComponent />
                                                <ErrorBoundary>
                                                    <LanguagesProvider>
                                                        {content}
                                                    </LanguagesProvider>
                                                </ErrorBoundary>
                                            </SnackbarProvider>
                                        </AuthProvider>
                                    </ThemeProvider>
                                </MuiThemeProvider>
                            </StyledEngineProvider>
                        </LocalizationProvider>
                    </StylesProvider>
                </Provider>
            </ReactQueryService>
        </HelmetProvider>
    );
}

export default App;
