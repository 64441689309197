import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { DEFAULT_PAGINATION_PER_PAGE } from 'constants/configs';

function paginate<T>(array: T[], perPage: number, pageNumber: number) {
    return array.slice((pageNumber - 1) * perPage, pageNumber * perPage);
}

const _PER_PAGE_OPTIONS_ = [10, 25, 50, 100];

const initialPaginationValues: URLSearchParamsInit = {
    page: '1',
    /*  perPage: _PER_PAGE_OPTIONS_[1].toString(), */
    searchQuery: '',
};

export const usePagination = () => {
    let [searchParams, setPageParams] = useSearchParams(
        initialPaginationValues
    );

    const { page, searchQuery, ...pageParams } = queryString.parse(
        searchParams.toString()
    ) as {
        page: string;
        searchQuery: string;
    };

    const onPageChange = (event: React.ChangeEvent<unknown>, _page: number) => {
        setPageParams({
            ...pageParams,
            ...(Boolean(searchQuery) && { searchQuery }),
            page: _page.toString(),
        });
    };

    const onSearch = (_search: string) => {
        setPageParams({
            ...pageParams,
            page: '1',
            ...(Boolean(_search) && { searchQuery: _search }),
        });
    };

    const PER_PAGE = DEFAULT_PAGINATION_PER_PAGE;

    return {
        page: parseInt(page),
        setPageParams,
        onPageChange,
        paginate,
        PER_PAGE,
        onSearch,
        searchQuery,
    };
};
