import React from 'react';

import { IconButton, Button as MuiButton, Snackbar } from '@mui/material';
import { X as CloseIcon } from 'react-feather';
import { createStateContext } from 'react-use';

type SnackbarState = {
    message: string;
    open: boolean;
};
const [useSnackbarState, SnackbarProvider] = createStateContext<SnackbarState>({
    open: false,
    message: '',
});

const useSnackbar = () => {
    const [, setSnackbar] = useSnackbarState();

    const equeueSnackbar = (message: string) => {
        setSnackbar({ message, open: true });
    };

    const closeSnackbar = (
        event: React.SyntheticEvent | React.MouseEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbar({ message: '', open: false });
    };

    return [equeueSnackbar, closeSnackbar] as const;
};

function SnackbarComponent() {
    const [{ message, open }] = useSnackbarState();
    const [, handleClose] = useSnackbar();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            message={message}
            action={
                <React.Fragment>
                    <IconButton
                        size='small'
                        aria-label='close'
                        color='inherit'
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
}
export { SnackbarProvider, useSnackbar, SnackbarComponent };
