import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { CircularProgress, Stack } from '@mui/material';
import GlobalStyle from './GlobalStyle';

const Root = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
`;

type Props = {
    fullpage?: boolean;
};
function Loader({ fullpage = false }: Props) {
    if (fullpage) {
        return (
            <Fragment>
                <GlobalStyle />

                <Stack
                    sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <CircularProgress color='secondary' />
                </Stack>
            </Fragment>
        );
    }
    return (
        <Root>
            <CircularProgress color='secondary' />
        </Root>
    );
}

export default Loader;
