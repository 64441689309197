import { User, getGetUsersQueryKey, UserSuccessResponseDto } from 'api';
import { isArray } from 'lodash';
import { queryClient } from 'services/reactQuery';
import produce from 'immer';

export const deleteUserCacheUpdate = (userId: string) => {
    const allUsers = queryClient.getQueryData(
        getGetUsersQueryKey()
    ) as UserSuccessResponseDto;

    /* Removing deleted user from cache */
    if (allUsers?.result && isArray(allUsers?.result)) {
        queryClient.setQueryData(
            getGetUsersQueryKey(),
            produce(allUsers, draftState => {
                draftState.result = draftState.result.filter(
                    user => user.id !== userId
                );
            })
        );
    }
};
