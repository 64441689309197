import React from 'react';
import styled from 'styled-components/macro';

import { Alert as MuiAlert, Typography } from '@mui/material';
import { spacing } from '@mui/system';

const Alert = styled(MuiAlert)(spacing);

function ProtectedPage() {
    return (
        <React.Fragment>
            <Typography variant='h1' mb={8}>
                👋 Welcome to our beautiful Heylama CMS 🦙💨
            </Typography>
            <Alert mb={4} severity='info'>
                For detailed instructions please refer to the documentation on
                Notion: <br />
                <a
                    href='https://www.notion.so/heylama/Heylama-CMS-1a315b533e6c42d8b83a0a3f01cf907f'
                    target='_blank'
                >
                    https://www.notion.so/heylama/Heylama-CMS-1a315b533e6c42d8b83a0a3f01cf907f
                </a>
            </Alert>
        </React.Fragment>
    );
}

export default ProtectedPage;
