import { Fragment } from 'react';
import { Loader, ServerError } from 'components';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import { UserVocabsListRow } from './UserVocabsListRow';
import { useGetUserVocabsByUserId, UserVocab } from 'api';

type Props = {
    userId: string;
};

export function UserVocabsList({ userId }: Props) {
    const {
        data: userVocabs = [],
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useGetUserVocabsByUserId(userId, {
        query: {
            select: data => data.result,
        },
    });

    if (isFetching || isLoading) {
        return <Loader />;
    }

    console.log({ userVocabs });

    if (isError || !userVocabs) {
        return <ServerError onRetry={refetch} />;
    }

    if (!userVocabs.length) {
        return <Fragment>Student has no vocabs yet.</Fragment>;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Created at</TableCell>
                    <TableCell>Literal</TableCell>
                    <TableCell>Progress</TableCell>
                    <TableCell>Practiced</TableCell>
                    <TableCell>Next Review</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {userVocabs!.map((userVocab, index) => (
                    <UserVocabsListRow
                        index={index}
                        userVocab={userVocab}
                        key={index}
                    />
                ))}
            </TableBody>
        </Table>
    );
}
