/* Components */
import {
    Alert as MuiAlert,
    Autocomplete,
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    Chip,
    CircularProgress,
    FormHelperText,
    Grid,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    ConfirmModal,
    FormTextField,
    LemmaBar,
    StatusIndicator,
    TranscriptionOverrideField,
    WithLinguistics,
} from 'components';

/* Modules */
import styled from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { spacing, SpacingProps } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { createRoute } from 'utils/createRoute';
import { CreateVocabDto } from 'api';
import CEFR_LEVELS from 'constants/cefrLevels';
import { FormikSelectOption } from 'types/formik';
import { useToggle } from 'react-use';
import { Fragment, useCallback, useState } from 'react';
import { UploadSingleFile } from 'components/common/upload/UploadSingleFile';
import { Taxonomy, useGetTaxonomies } from 'api';
import resultQuery from 'utils/resultQuery';
import { get, isArray, map, property } from 'lodash';

interface ButtonProps extends SpacingProps {
    component?: string;
}

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(FormTextField)<{ my?: number }>(spacing);

const getOptionLabel: any = property('label');

type Props = {
    words: FormikSelectOption[];
    vocabs: FormikSelectOption[];
    isEdit?: boolean;
};

export function AddVocabFormView({ words, isEdit = false, vocabs }: Props) {
    const formik = useFormikContext<CreateVocabDto & WithLinguistics>();
    const navigate = useNavigate();
    const params = useParams();
    const [lemmasInvalidated, setLemmasInvalidated] = useState(false);
    const [open, toggleOpen] = useToggle(false);

    const {
        isSubmitting,
        handleSubmit,
        status,
        setFieldValue,
        values,
        touched,
        errors,
    } = formik;

    const {
        data: taxonomies = [],
        isError: isTaxosError,
        isLoading: isTaxosLoading,
    } = useGetTaxonomies(resultQuery);

    const handleDiscard = () => navigate(createRoute('vocabs'));
    const handleClose = toggleOpen;
    const invalidateLemmas = (event: any) => {
        setLemmasInvalidated(true);
        return true;
    };

    const editToken = (tokenIndex: string) => (event: any) => {
        navigate(
            createRoute('editVocabToken', {
                ...params,
                tokenIndex,
            })
        );
    };

    const handleDrop = useCallback(
        acceptedFiles => {
            const file = acceptedFiles[0];
            if (file) {
                setFieldValue('image', {
                    file,
                    preview: URL.createObjectURL(file),
                });
            }
        },
        [setFieldValue]
    );

    const WordChip = (props: any) => {
        /* 
        const classes = useStyles();
        className={classes.root} 
        */
        return <Chip {...props} />;
    };

    return (
        <Card mb={6}>
            <CardContent>
                {status.sent && (
                    <Alert severity='success' mb={3}>
                        Vocab successfully {isEdit ? `updated` : `added`}!
                    </Alert>
                )}

                {status.error && (
                    <Alert severity='error' mb={3}>
                        {status.error}
                    </Alert>
                )}

                {errors &&
                    map(errors, (error, index) => (
                        <Alert severity='error' mb={3} key={index}>
                            {error}
                        </Alert>
                    ))}

                {isSubmitting ? (
                    <Box display='flex' justifyContent='center' my={6}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <Stack
                            spacing={5}
                            direction={{ xs: 'column', md: 'row' }}
                            my={5}
                        >
                            <TextField
                                name='literal'
                                label='The vocab'
                                placeholder='E.g. keinen Bock haben'
                                onInput={invalidateLemmas}
                                fullWidth
                            />
                            <TextField select name='language' label='Language'>
                                <MenuItem key='de' value='de'>
                                    German
                                </MenuItem>
                                <MenuItem key='en' value='en'>
                                    English
                                </MenuItem>
                                <MenuItem key='fr' value='fr'>
                                    French
                                </MenuItem>
                                <MenuItem key='ru' value='ru'>
                                    Russian
                                </MenuItem>
                            </TextField>
                        </Stack>

                        {!lemmasInvalidated && values.linguistics && (
                            <Stack spacing={10} mb={10}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant='caption'>
                                        Lemmas: &nbsp;&nbsp;
                                    </Typography>
                                    <LemmaBar
                                        onEdit={editToken}
                                        linguistics={values?.linguistics}
                                    />
                                </Grid>
                            </Stack>
                        )}

                        <Grid container spacing={5} alignItems='center' mb={5}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name='translation'
                                    label='English translation'
                                    placeholder='E.g. to not feel like doing something'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction='row' spacing={2}>
                                    <TextField
                                        name='definition.literal'
                                        label='Definition'
                                        placeholder='translation or definition'
                                        fullWidth
                                    />
                                    <TextField
                                        fullWidth
                                        label='Definition language'
                                        select
                                        name='definition.language'
                                    >
                                        <MenuItem key='de' value='de'>
                                            German
                                        </MenuItem>
                                        <MenuItem key='en' value='en'>
                                            English
                                        </MenuItem>
                                    </TextField>
                                </Stack>
                            </Grid>
                        </Grid>

                        {isEdit && (
                            <Stack spacing={5} mb={5}>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    disableCloseOnSelect
                                    options={words}
                                    defaultValue={
                                        values.required_words as any[]
                                    }
                                    getOptionLabel={getOptionLabel}
                                    filterSelectedOptions
                                    isOptionEqualToValue={(o, v) =>
                                        o.value === v.value
                                    }
                                    ChipProps={{ size: 'small' }}
                                    onChange={(event, value) =>
                                        setFieldValue(
                                            'required_words',
                                            value.map(word => word.value)
                                        )
                                    }
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.value}>
                                                {option.label}
                                            </li>
                                        );
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            onChange={() => {}}
                                            name='required'
                                            label='Required words'
                                            placeholder='Which words are needed to form this vocab?'
                                        />
                                    )}
                                    renderTags={(tagValue, getTagProps) => {
                                        return tagValue.map((option, index) => (
                                            <Tooltip
                                                key={index}
                                                title={
                                                    <Fragment>
                                                        <strong>ID</strong>:{' '}
                                                        {option.value}
                                                    </Fragment>
                                                }
                                            >
                                                <Box>
                                                    <WordChip
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        label={getOptionLabel(
                                                            option
                                                        )}
                                                    />
                                                </Box>
                                            </Tooltip>
                                        ));
                                    }}
                                />
                            </Stack>
                        )}

                        <Stack spacing={5} mb={5}>
                            <Autocomplete
                                multiple
                                fullWidth
                                disableCloseOnSelect
                                options={vocabs}
                                defaultValue={values.related_vocabs as any[]}
                                getOptionLabel={getOptionLabel}
                                filterSelectedOptions
                                isOptionEqualToValue={(o, v) =>
                                    o.value === v.value
                                }
                                ChipProps={{ size: 'small' }}
                                onChange={(event, value) => {
                                    setFieldValue(
                                        'related_vocabs',
                                        value.map(option => option.value)
                                    );
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        onChange={() => {}}
                                        name='related_vocabs'
                                        label='Related vocabs'
                                        placeholder='Any vocabs that are conveying the same meaning?'
                                    />
                                )}
                            />
                        </Stack>

                        <Stack spacing={5} mb={5}>
                            <TextField
                                name='tts_text'
                                label='Alternative input for audio creation (optional)'
                                placeholder={values.literal}
                                fullWidth
                            />
                        </Stack>

                        <Stack spacing={5} mb={5} mt={5}>
                            <TextField
                                name='status'
                                select
                                label={'Status (' + values.status + ')'}
                            >
                                <MenuItem key='draft' value='draft'>
                                    <Stack direction='row' alignItems='center'>
                                        <StatusIndicator status='draft' /> draft
                                    </Stack>
                                </MenuItem>
                                <MenuItem key='published' value='published'>
                                    <Stack direction='row' alignItems='center'>
                                        <StatusIndicator status='published' />{' '}
                                        published
                                    </Stack>
                                </MenuItem>
                            </TextField>
                        </Stack>

                        <Stack mb={4} spacing={2}>
                            <TranscriptionOverrideField name='transcription_overrides' />
                            <Box>
                                <Typography>Image:</Typography>
                                <UploadSingleFile
                                    maxSize={2_000_000 /* 2 MB */}
                                    accept='image/*'
                                    file={values?.image as any}
                                    onDrop={handleDrop}
                                    error={Boolean(
                                        touched.image && errors.image
                                    )}
                                    sx={{ width: 500 }}
                                />
                                {touched.image && errors.image && (
                                    <FormHelperText error sx={{ px: 2 }}>
                                        {touched.image && errors.image}
                                    </FormHelperText>
                                )}
                            </Box>
                        </Stack>
                        <Stack direction='row' spacing={5}>
                            <Button
                                variant='outlined'
                                color='error'
                                onClick={toggleOpen}
                            >
                                Discard
                            </Button>

                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Save changes
                            </Button>
                        </Stack>
                    </form>
                )}
            </CardContent>

            <ConfirmModal
                title='Are you sure?'
                description='Your unsaved data will be lost'
                onConfirm={handleDiscard}
                onCancel={handleClose}
                open={open}
            />
        </Card>
    );
}
