export type Type = {
    [key: string]: string;
};

export const UPOS_MAP = {
    ADJ: 'adjective',
    ADP: 'adposition',
    ADV: 'adverb',
    AUX: 'auxiliary',
    CCONJ: 'coordinating conjunction',
    DET: 'determiner',
    INTJ: 'interjection',
    NOUN: 'noun',
    NUM: 'numeral',
    PART: 'particle',
    PRON: 'pronoun',
    PROPN: 'proper noun',
    PUNCT: 'punctuation',
    SCONJ: 'subordinating conjunction',
    SYM: 'symbol',
    VERB: 'verb',
    'nsubj:pass': 'nominal subjects of passive verbs',
    'csubj:pass': 'clausal subjects of passive verbs',
    'obl:agent': 'agents of passive verbs',
    'obl:arg': 'prepositional objects',
    'expl:pv': 'reflexive clitics of inherently reflexive verbs',
    'aux:pass': 'passive auxiliaries',
    'compound:prt': 'separable verb prefixes',
    'det:poss': 'possessive determiners',
    'nmod:poss': 'possessive modifier phrases',
    X: 'other',
};

export const XPOS_MAP = {
    $: 'symbol',
    ADJA: 'attributive adjective',
    ADJD: 'predicate adjective; adjective used adverbially',
    ADV: 'adverb',
    APPO: 'postposition',
    APPR: 'preposition left hand part of double preposition',
    APPRART: 'preposition with fused article',
    APZR: 'right hand part of double preposition',
    ART: 'article',
    CARD: 'cardinal number',
    CC: 'coordinating conjunction',
    CD: 'cardinal number',
    DT: 'determiner',
    EX: 'existential there',
    FM: 'foreign words',
    FW: 'foreign word',
    IN: 'preposition or subordinating conjunction',
    ITJ: 'interjection',
    JJ: 'adjective',
    JJR: 'adjective, comparative',
    JJS: 'adjective, superlative',
    KOKOM: 'comparative conjunction or particle',
    KON: 'co-ordinating conjunction',
    KOUI: 'preposition used to introduce infinitive clause',
    KOUS: 'subordinating conjunction',
    LS: 'list item marker',
    LT: 'single letter',
    MD: 'modal',
    NA: 'adjective used as noun',
    NE: 'names and other proper nouns',
    NN: 'noun, singular or mass',
    NNP: 'proper noun, singular',
    NNPS: 'proper noun, plural',
    NNS: 'noun, plural',
    PAV: 'pronominal adverb',
    PAVREL: 'pronominal adverb used as relative',
    PDAT: 'demonstrative determiner',
    PDS: 'demonstrative pronoun',
    PDT: 'predeterminer',
    PIAT: 'indefinite determiner',
    PIS: 'indefinite pronoun',
    POS: 'possessive ending',
    PPER: 'personal pronoun',
    PPOSAT: 'possessive determiner',
    PPOSS: 'possessive pronoun',
    PRELAT: 'relative depending on a noun',
    PRELS: 'relative pronoun',
    PRF: 'reflexive pronoun',
    PRP$: 'possessive pronoun',
    PRP: 'personal pronoun',
    PTKA: 'particle with adjective or adverb',
    PTKANT: 'answer particle',
    PTKNEG: 'negative particle',
    PTKREL: 'indeclinable relative particle',
    PTKVZ: 'separable prefix',
    PTKZU: 'particle of verb, infinitive',
    PWAT: 'interrogative determiner',
    PWAV: 'interrogative adverb',
    PWAVREL: 'interrogative adverb used as relative',
    PWREL: 'interrogative pronoun used as relative',
    PWS: 'interrogative pronoun',
    RB: 'adverb',
    RBR: 'adverb, comparative',
    RBS: 'adverb, superlative',
    RP: 'particle',
    SYM: 'symbol',
    TO: 'to',
    TRUNC: 'truncated form of compound',
    UH: 'interjection',
    VAFIN: 'finite auxiliary verb',
    VAIMP: 'imperative of auxiliary',
    VAINF: 'infinitive of auxiliary',
    VAPP: 'past participle of auxiliary',
    VB: 'verb, base form',
    VBD: 'verb, past tense',
    VBG: 'verb, gerund or present participle',
    VBN: 'verb, past participle',
    VBP: 'verb, non-3rd person singular present',
    VBZ: 'verb, 3rd person singular present',
    VMFIN: 'finite modal verb',
    VMINF: 'infinitive of modal',
    VMPP: 'past participle of auxiliary',
    VVFIN: 'finite full verb',
    VVIMP: 'imperative of full verb',
    VVINF: 'infinitive of full verb',
    VVIZU: 'infinitive with incorporated zu',
    VVPP: 'past participle of full verb',
    WDT: 'wh-determiner',
    WP$: 'possessive wh-pronoun',
    WP: 'wh-pronoun',
    WRB: 'wh-adverb',
};

export const MORPH_MAP = {
    poss: 'possessive',
    reflex: 'reflexive',
    trans: 'transitional',
    foreign: 'loan word',
};

export const CASE_MAP = {
    nom: 'nominative',
    gen: 'genitive',
    acc: 'accusative',
    dat: 'dative',
    abl: 'ablative',
    loc: 'locative',
};

export const DEFINITE_MAP = {
    def: 'definite',
    ind: 'indefinite',
};

export const MOOD_MAP = {
    ind: 'indicative',
    imp: 'imperative',
    sub: 'subjonctive',
};

export const GENDER_MAP = {
    fem: 'feminine',
    masc: 'masculine',
    neut: 'neutral',
};

export const NUMBER_MAP = {
    plur: 'plural',
    sing: 'singular',
};

export const TENSE_MAP = {
    past: 'past',
    pres: 'present',
    futu: 'future',
};

export const VERBFORM_MAP = {
    inf: 'infinitive',
    fin: 'conjugated',
    part: 'particle',
};

export const PRONTYPE_MAP = {
    art: 'article',
    dem: 'demonstrative',
    emp: 'empathic',
    exc: 'exclamative',
    ind: 'indefinite',
    int: 'interrogative',
    neg: 'negative',
    prs: 'personal/possessive',
    rcp: 'reciprocal',
    rel: 'relative',
    tot: 'total (collective)',
};

export const DEGREE_MAP = {
    pos: 'positive (1st degree)',
    equ: 'equative',
    cmp: 'comparative (2nd degree)',
    sup: 'superlative (3rd degree)',
    abs: 'absolute superlative',
};
