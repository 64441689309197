import { useState } from 'react';
import {
    Box,
    Button,
    Modal,
    Stack,
    MenuItem,
    Typography,
    TextField,
} from '@mui/material';
import { User } from 'api';
import { Messenger } from 'types/messengers';
import { capitalize } from 'lodash';

type Props = {
    user: User;
    open: boolean;
    onClose: () => void;
    onSend: (message: string, environment: string) => void;
    messenger?: Messenger;
};

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export function SendMessageModal({
    user,
    open,
    onClose,
    onSend,
    messenger,
}: Props) {
    const [message, setMessage] = useState('');
    const [environment, setEnv] = useState('staging');

    return (
        <Modal
            onClick={(event: any) => event.preventDefault()}
            onClose={onClose}
            open={open}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
        >
            <Box sx={modalStyle}>
                <Typography variant='h6' component='h2'>
                    Send message to {user?.profile?.public?.email}'s{' '}
                    {capitalize(messenger)}
                </Typography>
                <Stack direction='row' spacing={1} mt={2}>
                    <TextField
                        label='Message'
                        name='message'
                        fullWidth
                        placeholder={`Hi, ${user?.profile?.public?.name}, …`}
                        onChange={(event: any) =>
                            setMessage(event.target.value)
                        }
                    />
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => onSend(message, environment)}
                    >
                        Send!
                    </Button>
                </Stack>
                <Stack mt={2}>
                    <TextField
                        select
                        label='On'
                        defaultValue={'staging'}
                        onChange={(event: any) => setEnv(event.target.value)}
                    >
                        <MenuItem value='staging'>staging</MenuItem>
                        <MenuItem value='production'>production</MenuItem>
                    </TextField>
                </Stack>
            </Box>
        </Modal>
    );
}
