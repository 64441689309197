/* Components */
import { Container, Grid, styled, Typography, Box, Stack } from '@mui/material';

/* Modules */
import dayJs from 'dayjs';
import { useGetLatestSignal, getLatestSignal } from 'api';
import { useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { RefreshCcw } from 'react-feather';
import { useQueryClient } from 'react-query';

const Wrapper = styled('div')(({ theme }) => ({
    background: theme.palette.success.light,
    cursor: 'pointer',
    borderRadius: `5px`,
}));

/* 20 seconds  */
const refetchInterval = 20 * 1000;

const useForcedUpdate = () => {
    const handleUpdate = () => window.location.reload();
    const [lastUpdate, setLastUpdate] = useState<string | undefined>(undefined);
    const resetLastUpdate = () =>
        getLatestSignal({ is_admin: 1 }).then(d =>
            setLastUpdate(d?.result?.updated_at)
        );
    const { data, isLoading } = useGetLatestSignal(
        { is_admin: 1 },
        {
            query: {
                refetchInterval,
                select: d => d.result,
            },
        }
    );

    useEffectOnce(() => {
        getLatestSignal({ is_admin: 1 }).then(d =>
            setLastUpdate(d?.result?.updated_at)
        );
    });
    const isUpdated =
        !isUndefined(lastUpdate) && !dayJs(lastUpdate).isSame(data?.updated_at);
    return { isLoading, isUpdated, handleUpdate, resetLastUpdate };
};

export function ForcedUiUpdatesBanner() {
    const [__] = useTranslation();
    const { isUpdated, resetLastUpdate } = useForcedUpdate();
    const queryClient = useQueryClient();
    useEffect(() => {
        if (isUpdated) {
            queryClient.invalidateQueries();
            resetLastUpdate();
        }
    }, [isUpdated]);

    /*  if (isUpdated) {
        return (
            <Wrapper onClick={handleUpdate}>
                <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    justifyContent='flex-start'
                    onClick={handleUpdate}
                    sx={{ p: 2 }}
                >
                    <Box
                        sx={{
                            height: 46,
                            width: 46,
                            borderRadius: '100%',
                            background: 'white',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 1.2,
                            color: t => t.palette.success.light,
                            display: 'flex',
                        }}
                    >
                        <RefreshCcw />
                    </Box>
                    <Box>
                        <Typography
                            variant='body2'
                            fontWeight={500}
                            sx={{
                                color: t => t.palette.grey[100],
                            }}
                        >
                            There's an update available
                        </Typography>

                        <Typography
                            variant='caption'
                            sx={{
                                color: t => t.palette.grey[100],
                            }}
                        >
                            Click to update
                        </Typography>
                    </Box>
                </Stack>
            </Wrapper>
        );
    } */
    return <></>;
}
