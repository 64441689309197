import { Fragment } from 'react';
import dayJs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Box, Stack, TableRow, TableCell, Tooltip } from '@mui/material';
import { STATES_PROGRESS } from 'types/srs';
import { UserVocab } from 'api';
import { range } from 'lodash';

dayJs.extend(duration);
dayJs.extend(relativeTime);

type UVProps = {
    index?: number;
    userVocab: UserVocab;
};

export function UserVocabsListRow({ index, userVocab }: UVProps) {
    const state = userVocab.spaced_repetition?.state || 0;
    const progress = STATES_PROGRESS[state];

    return (
        <TableRow>
            <TableCell>
                {dayJs
                    .duration(dayJs(userVocab.created_at).diff())
                    .humanize(true)}
            </TableCell>
            <TableCell>{userVocab.literal}</TableCell>
            <TableCell>
                <Tooltip title={`${Math.round(progress * 100)}%`}>
                    <Box>
                        <StrengthBoxes count={4} progress={progress} />
                    </Box>
                </Tooltip>
            </TableCell>
            <TableCell>
                {userVocab.spaced_repetition?.turns?.length || '0'}x
            </TableCell>
            <TableCell>
                {dayJs(userVocab.spaced_repetition?.due_date).format(
                    'DD.MM.YY ~ HH:MM'
                )}
            </TableCell>
        </TableRow>
    );
}

type StrengthProps = {
    progress?: number;
    count?: number;
};

const getBoxStyles = (
    active: boolean,
    index: number = 0,
    count: number = 0
) => ({
    width: '6px',
    height: `${10 * ((index + 1) / (count - 1))}px`,
    backgroundColor: active ? '#57d049' : '#ddd',
});

function StrengthBoxes({ count = 1, progress = 0 }: StrengthProps) {
    const increment = 1 / (count - 1);

    return (
        <Stack direction='row' spacing={0.5} alignItems='baseline'>
            {range(count).map(index => (
                <Box
                    key={index}
                    sx={getBoxStyles(
                        progress > increment * index,
                        index,
                        count
                    )}
                />
            ))}
        </Stack>
    );
}
