import React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';

import { Button as MuiButton, Typography } from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';

interface ButtonProps extends SpacingProps {
    component?: React.ElementType;
    to?: string;
    target?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const Wrapper = styled.div`
    height: 100%;
    padding: ${props => props.theme.spacing(6)};
    text-align: center;
    background: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    ${props => props.theme.breakpoints.up('md')} {
        padding: ${props => props.theme.spacing(10)};
    }
`;

type Props = {
    code?: number;
    title?: string;
    description?: string;
    isLoading?: boolean;
    onRetry: () => void;
    retryText?: string;
};

const DEFAULT_DESCRIPTION = `The server encountered something unexpected that didn't allow it to complete the request.`;

export function ServerError({
    code = 500,
    title = `Internal server error.`,
    description = DEFAULT_DESCRIPTION,
    onRetry,
    retryText = 'retry',
}: Props) {
    return (
        <Wrapper>
            <Helmet title={`${code} Error`} />
            <Typography component='h1' variant='h1' align='center' gutterBottom>
                {code}
            </Typography>
            <Typography component='h2' variant='h5' align='center' gutterBottom>
                {title}
            </Typography>
            <Typography
                component='h2'
                variant='body1'
                align='center'
                gutterBottom
            >
                {description}
            </Typography>

            <Button
                variant='contained'
                color='secondary'
                mt={2}
                onClick={onRetry}
            >
                {retryText}
            </Button>
        </Wrapper>
    );
}
