import {
    getGetVocabByIdQueryKey,
    getGetVocabsQueryKey,
    SingleVocabSuccessResponseDto,
    VocabSuccessResponseDto,
} from 'api';
import produce from 'immer';
import { findIndex } from 'lodash';
import { queryClient } from 'services/reactQuery';

export const editVocabCacheUpdate = (
    _updatedVocab: SingleVocabSuccessResponseDto
) => {
    const { id: vocabId } = _updatedVocab.result;
    const allVocabs = queryClient.getQueryData(
        getGetVocabsQueryKey()
    ) as VocabSuccessResponseDto;

    const updatedVocabIndex = findIndex(allVocabs?.result, {
        id: vocabId,
    });

    /*   const vocabInCache = queryClient.getQueryData(
        getGetVocabByIdQueryKey(vocabId!)
    ) as SingleVocabSuccessResponseDto;

    if (_updatedVocab.success && vocabInCache?.result) {
        queryClient.setQueryData(
            getGetVocabByIdQueryKey(vocabId!),
            produce(vocabInCache, draftState => {
                draftState.result = _updatedVocab.result;
            })
        );
    } */

    if (
        allVocabs &&
        _updatedVocab.success &&
        updatedVocabIndex > -1 /* meaning, we found the vocab in the list  */
    ) {
        /* Update in the list  */
        queryClient.setQueryData(
            getGetVocabsQueryKey(),
            produce(allVocabs, draftState => {
                draftState.result[updatedVocabIndex] = _updatedVocab.result;
            })
        );
    }
};
