import { Button, Stack, Box, Typography } from '@mui/material';
import { FormTextField } from 'components';
import { Field, FieldHookConfig } from 'formik';
import { get } from 'lodash';
import { useFieldArray } from './useFieldArray';

type FieldValue = { replace: string; by: string };

export function TranscriptionOverrideField(
    props: FieldHookConfig<FieldValue[]>
) {
    const [field, , { remove, push }] = useFieldArray(props);
    const values = get(field, 'value', []);

    return (
        <div style={{ width: '100%' }}>
            <Typography>Transcription Overrides</Typography>

            {values.map((friend, index) => (
                <Stack
                    direction='row'
                    spacing={2}
                    mb={2}
                    key={index}
                    sx={{
                        button: {
                            display: 'none',
                        },
                        '&:hover button': {
                            display: 'block',
                        },
                    }}
                >
                    <FormTextField
                        required
                        name={`transcription_overrides.${index}.replace`}
                    />

                    <FormTextField
                        required
                        name={`transcription_overrides.${index}.by`}
                    />

                    <Button
                        variant='contained'
                        type='button'
                        onClick={() => remove(index)}
                    >
                        -
                    </Button>
                </Stack>
            ))}

            <Stack spacing={2} direction='row'>
                <Button
                    onClick={() => push('')}
                    type='button'
                    variant='contained'
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    Click to add new pair
                </Button>
            </Stack>
        </div>
    );
}
