import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const _10_mins = 10 * 60 * 1000;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            staleTime: _10_mins,
        },
    },
});

export const ReactQueryService: React.FC = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};
