import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { TestParsing } from 'components/testing/TestParsing';

export default function Testing() {
    return (
        <Fragment>
            <Helmet title='Testing' />
            <Container>
                <TestParsing />
            </Container>
        </Fragment>
    );
}
