import { Fragment, useState } from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { UserVocabsList } from './UserVocabsList';
import { SendMessageModal } from 'components';
import { xhrServiceInstance } from '../../../services/xhr';
import { Messenger } from 'types/messengers';
import {
    User,
    impersonateUser,
    sendTelegramMessage,
    SingleTelegramMessageSuccessResponseDto,
} from 'api';
import { useSnackbar } from 'hooks/useSnackbar';

const TG_MESSAGE_API_URL = '/api/v2/telegram/messages/send';

type Props = {
    user: User;
};

import { ReactComponent as TelegramIconSVG } from 'assets/TGIcon.svg';
import { ReactComponent as WhatsappIconSVG } from 'assets/WAIcon.svg';
import { useNavigate } from 'react-router-dom';

const TelegramIcon = (props: any) => (
    <Box {...props}>
        <TelegramIconSVG
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
            }}
        />
    </Box>
);
const WhatsappIcon = (props: any) => (
    <Box {...props}>
        <WhatsappIconSVG
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
            }}
        />
    </Box>
);

const BOT_STAGING_LINK = 'https://staging.langage.app/api/v2/';
const BOT_PROD_LINK = 'https://web.heylama.app/api/v2/';

export function UserView({ user }: Props) {
    const [enqueueSnackbar] = useSnackbar();
    const [messenger, setMessenger] = useState<Messenger>();
    const [sendMessageOpen, setSendMessageOpen] = useState(false);
    const tgBotConnection = user.profile?.private?.connections?.telegram;
    const waBotConnection = user.profile?.private?.connections?.whatsapp;
    const isBotConnected =
        !!(tgBotConnection?.user_id || waBotConnection?.user_id) || true;

    const startMessage = (messenger: Messenger) => {
        setMessenger(messenger);
        setSendMessageOpen(true);
    };

    const loginAs = async (userId: string) => {
        const response = await impersonateUser({
            user_id: userId,
        });
        if (response.success) {
            window.open(
                `https://staging.langage.app/auth/login?accessToken=${response
                    .result.accessToken!}`
            );
        }
    };

    const sendMessage = async (message: string, environment: string) => {
        if (tgBotConnection) {
            try {
                const sendMessageDto = {
                    message,
                    userId: tgBotConnection?.user_id,
                    token: 'hbGciOiJIUzI1NiIsInR5cCI00046IkpXVCJ9eyJp12',
                };
                if (environment === 'staging') {
                    await sendTelegramMessage(sendMessageDto);
                } else {
                    await xhrServiceInstance<SingleTelegramMessageSuccessResponseDto>(
                        {
                            baseURL: 'https://web.heylama.app',
                            url: TG_MESSAGE_API_URL,
                            method: 'post',
                            data: sendMessageDto,
                        }
                    );
                }
            } catch (error) {
                enqueueSnackbar('Oh no! 😢 ' + (error as string));
            }
        } else {
            alert('Sending failed! User not connected to either bots…');
        }
        setSendMessageOpen(false);
    };

    return (
        <Fragment>
            <Paper sx={{ p: '20px' }}>
                <Button variant='contained' onClick={() => loginAs(user.id!)}>
                    Login as this user
                </Button>
            </Paper>
            {isBotConnected && (
                <Paper sx={{ p: '20px' }}>
                    <SendMessageModal
                        open={sendMessageOpen}
                        messenger={messenger}
                        onClose={() => setSendMessageOpen(false)}
                        onSend={sendMessage}
                        user={user}
                    />
                    <Typography variant='h3' mb={2}>
                        Bots
                    </Typography>
                    <Stack pl={2} spacing={2} maxWidth={400} direction='row'>
                        {tgBotConnection && (
                            <Button
                                variant='contained'
                                onClick={() => startMessage('telegram')}
                            >
                                <TelegramIcon
                                    style={{
                                        position: 'relative',
                                        width: '16px',
                                        height: '16px',
                                        border: '2px solid #fff',
                                        borderRadius: '100%',
                                        marginRight: '10px',
                                    }}
                                />
                                Send message
                            </Button>
                        )}
                        {waBotConnection && (
                            <Button
                                variant='contained'
                                onClick={() => startMessage('whatsapp')}
                            >
                                <WhatsappIcon
                                    style={{
                                        position: 'relative',
                                        width: '16px',
                                        height: '16px',
                                        border: '2px solid #fff',
                                        borderRadius: '100%',
                                        marginRight: '10px',
                                    }}
                                />
                                Send message
                            </Button>
                        )}
                    </Stack>
                </Paper>
            )}
            <Paper sx={{ p: '20px' }}>
                <Typography variant='h3'>Vocabs</Typography>
                <UserVocabsList userId={user.id!} />
            </Paper>
        </Fragment>
    );
}
