import { Tooltip } from '@mui/material';

type Props = {
    status: string;
};

export function StatusIndicator({ status }: Props) {
    return (
        <Tooltip title={status}>
            <div
                style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '20px',
                    background: status === 'published' ? 'green' : 'crimson',
                    margin: '0 12px',
                }}
            ></div>
        </Tooltip>
    );
}
