import styled from 'styled-components';
import { Chip as MuiChip, Tooltip } from '@mui/material';
import { Fragment } from 'react';
import { UPOS_MAP, XPOS_MAP } from './TokenMaps';
import { renderJson } from 'utils/renderJson';
import { get } from 'lodash';

const POSColors: { [key: string]: string } = {
    CCONJ: 'yellow',
    SCONJ: 'yellow',
    SYM: '#ddd',
    NUM: '#ddd',
    PRON: 'orange',
    AUX: '#ff7272',
    PART: '#ff7272',
    VERB: '#ff7272',
    ADP: '#ff90ff',
    ADV: '#ABC',
    INTJ: '#DAA',
    PUNCT: '#ddd',
    PROPN: '#d2ff8a',
    NOUN: '#aded47',
    DET: '#DCDCDC',
};

export type WithLinguistics = {
    linguistics?: any[];
    onEdit?: (index: string) => (event: any) => void;
};

export function LemmaBar({ linguistics, onEdit }: WithLinguistics) {
    return (
        <Fragment>
            {linguistics?.map((entry, index: number) => {
                const Chip = styled(MuiChip)`
                    background: ${POSColors[entry.pos as string]};
                    margin-right: 3px;
                `;
                const summary = (
                    <Fragment>
                        {entry.key && (
                            <i>
                                {entry.key}
                                <br />
                            </i>
                        )}
                        <b>{entry.literal}</b> {entry.id ? `(${entry.id})` : ''}{' '}
                        {entry.deprel ? `[${entry.deprel}]` : ''}
                        <br />
                        <b>
                            {get(
                                UPOS_MAP,
                                entry.pos as keyof typeof UPOS_MAP,
                                entry.pos
                            )}
                        </b>{' '}
                        – {XPOS_MAP[entry.xpos as keyof typeof XPOS_MAP]}
                        <br />
                        {entry.morphological && (
                            <Fragment>
                                {renderJson(entry.morphological)}
                            </Fragment>
                        )}
                    </Fragment>
                );

                return (
                    <Tooltip key={`${index}-${entry.lemma}`} title={summary}>
                        <Chip
                            onDoubleClick={onEdit && onEdit(String(index + 1))}
                            label={entry.lemma}
                        />
                    </Tooltip>
                );
            })}
        </Fragment>
    );
}
