/* Components */
import { AddVocabValidationSchema } from './AddVocabValidationSchema';
import { AddVocabFormView } from './AddVocabFormView';
import { Loader, ServerError } from 'components';

/* Modules */
import { FormikProvider, useFormik } from 'formik';
import { get, isArray, isUndefined, merge, sortBy } from 'lodash';
import { FormikSelectOption, FormikSubmitFunction } from 'types/formik';
import {
    CreateVocabDto,
    useCreateVocab,
    Word,
    getGetVocabsQueryKey,
    VocabSuccessResponseDto,
    Vocab,
} from 'api';
import { getErrorMessage } from 'utils/getErrorMessage';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { createRoute } from 'utils/createRoute';
import produce from 'immer';

const initialValues: CreateVocabDto = {
    literal: '',
    definition: {
        literal: '',
        language: 'de',
    },
    example: '',
    language: 'de',
    translation: '',
    tts_text: '',
    status: 'draft',
    required_words: [],
    related_vocabs: [],
    taxonomies: {},
    transcription_overrides: [],
};

export const convertWordsIntoSelectableOptions = (
    words: Word[] | Vocab[]
): FormikSelectOption[] =>
    words?.map?.(({ id, literal }) => ({
        label: literal as string,
        value: id as string,
    }));

type Props = {
    vocabs: Vocab[] | undefined;
};

export function AddVocabForm({ vocabs }: Props) {
    const navigate = useNavigate();
    const { mutate: createVocab } = useCreateVocab();
    /*   const [literal] = useQueryParam('literal', StringParam); */

    /*    if (literal) {
        initialValues.literal = literal!;
    } */

    const queryClient = useQueryClient();

    const onSubmit: FormikSubmitFunction<typeof initialValues> = (
        data,
        { resetForm, setStatus, setSubmitting }
    ) => {
        createVocab(
            { data: merge(data, { image: get(data, 'image.file') }) },
            {
                onSuccess: response => {
                    resetForm();
                    setStatus({ sent: true, error: '' });

                    const allVocabs = queryClient.getQueryData(
                        getGetVocabsQueryKey()
                    ) as VocabSuccessResponseDto;

                    if (response.success && response.result) {
                        if (
                            !isUndefined(allVocabs) &&
                            isArray(allVocabs?.result)
                        ) {
                            queryClient.setQueryData(
                                getGetVocabsQueryKey(),

                                produce(allVocabs, draftState => {
                                    draftState.result = [
                                        ...allVocabs.result,
                                        response.result,
                                    ];
                                })
                            );
                        }
                        navigate(
                            createRoute('editVocab', {
                                vocabId: response.result.id,
                            })
                        );
                    }
                    setSubmitting(false);
                },
                onError: (error: any) => {
                    setStatus({
                        sent: false,
                        error: getErrorMessage(error),
                    });
                    setSubmitting(false);
                },
            }
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: AddVocabValidationSchema,
        onSubmit,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        initialStatus: {
            error: '',
            sent: false,
        },
    });

    const vocabOptions = convertWordsIntoSelectableOptions(vocabs!);
    return (
        <FormikProvider value={formik}>
            <AddVocabFormView vocabs={vocabOptions} words={[]} />
        </FormikProvider>
    );
}
