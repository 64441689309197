import { createContext, ReactNode, useEffect, useReducer } from 'react';

import { JWTContextType, ActionMap, AuthState, AuthUser } from '../types/auth';

import { signInUser, getMyAcount } from 'api';
import { isValidToken, setSession } from '../utils/jwt';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';

type AuthActionTypes = {
    [INITIALIZE]: {
        isAuthenticated: boolean;
        user: AuthUser;
    };
    [SIGN_IN]: {
        user: AuthUser;
    };
    [SIGN_OUT]: undefined;
    [SIGN_UP]: {
        user: AuthUser;
    };
};

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const JWTReducer = (
    state: AuthState,
    action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
            };
        case 'SIGN_IN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case 'SIGN_OUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };

        case 'SIGN_UP':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };

        default:
            return state;
    }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(JWTReducer, initialState);
    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken =
                    window.localStorage.getItem('admin-accessToken');

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);

                    const { success, result } = await getMyAcount();
                    if (success) {
                        const { user } = result;

                        dispatch({
                            type: INITIALIZE,
                            payload: {
                                isAuthenticated: true,
                                user,
                            },
                        });
                    }
                } else {
                    dispatch({
                        type: INITIALIZE,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const signIn = async (email: string, password: string) => {
        const { result, success } = await signInUser({
            email,
            password,
            is_admin_login: true,
        } as any);

        const { user, accessToken } = result;

        if (accessToken) {
            setSession(accessToken);
            dispatch({
                type: SIGN_IN,
                payload: {
                    user,
                },
            });
        }
    };

    const signOut = async () => {
        setSession(null);
        dispatch({ type: SIGN_OUT });
    };

    const resetPassword = (email: string) => console.log(email);

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'jwt',
                signIn,
                signOut,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };

export type ResponseType<T> = {
    status: number;
    success: boolean;
} & T;
