import {
    Box,
    Button,
    MenuItem,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { AlertTriangle } from 'react-feather';
import { LanguageSchema, useGetVocabs, Vocab } from 'api';
import { Loader, ServerError } from 'components';
import { useLanguages } from 'hooks/useLanguages';
import { usePagination } from 'hooks/usePagination';
import { Fragment, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'hooks/useSnackbar';
import { useToggle } from 'react-use';
import { createRoute } from 'utils/createRoute';
import { search } from 'utils/search';
import { VocabsListRow } from './VocabsListRow';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { ByStatusFilter } from 'components/common/filters/ByStatusFilter';
import { highlightVocab } from 'utils/highlightVocab';
import { filterMatcher } from 'utils/matching';
import { find, filter, isEmpty, map, reverse, some } from 'lodash';

export function renderArrayCell<T>(arg: T[], renderFn: (e: T[]) => ReactNode) {
    if (!isEmpty(arg)) return renderFn(arg);
    return `-`;
}

const initialFilterQueryValues: URLSearchParamsInit = {};

export function VocabsList() {
    const [enqueueSnackbar] = useSnackbar();
    const {
        page,
        onPageChange,
        paginate,
        PER_PAGE,
        onSearch,
        searchQuery,
        setPageParams,
    } = usePagination();

    // const [errorFilter, toggleErrorFilter] = useToggle(false);
    const [searchParams, setSearchParams] = useSearchParams(
        initialFilterQueryValues
    );
    
    const { loading: languagesLoading, languages } = useLanguages();
    
    const { byStatus, byLanguage, onlyErroneous, ...pageParams } = queryString.parse(
        searchParams.toString()
    ) as {
        byStatus: string;
        byLanguage: string;
        onlyErroneous: string;
    };

    const query: Record<string, any> = {};

    if(byLanguage) {
        query.language = byLanguage;
    }

    const {
        data: vocabs = [],
        isLoading,
        isError,
        refetch: refetchVocabs,
    } = useGetVocabs({
        query: JSON.stringify(query)
    }, {
        query: {
            select: data => data.result,
        },
    });

    const navigate = useNavigate();

    if (isLoading) {
        return <Loader />;
    }

    if (isError || !vocabs) {
        return <ServerError onRetry={refetchVocabs} />;
    }

    const addVocab = () =>
        navigate(createRoute('addVocab') + `?literal=${searchQuery}`);

    if (!vocabs.length) {
        return (
            <ServerError
                code={404}
                title='No vocabs found'
                description=''
                onRetry={addVocab}
                retryText='Add Vocab'
            />
        );
    }

    if (languagesLoading) {
        return <Fragment>Loading…</Fragment>;
    }

    const filterVocabs = (vocabs: Vocab[]) => {
        let filteredVocabs = [...vocabs];

        if (!isEmpty(byStatus)) {
            filteredVocabs = filteredVocabs.filter(
                ({ status }) => status === byStatus
            );
        }

        if (!isEmpty(onlyErroneous)) {
            const results = filteredVocabs.filter(vocab => {
                const language = find(languages, {
                    lang_code: vocab.language!,
                }) as LanguageSchema;

                const requiredWords = filter(vocab.linguistics, (token: any) =>
                    map(vocab.required_words, 'id')?.includes(token.id)
                );
                const parts = highlightVocab({
                    phrase: vocab.literal!,
                    linguistics: vocab.linguistics!,
                    requiredWords,
                    language,
                });
                return !some(parts, 'isVocab');
            });

            if (results.length) {
                filteredVocabs = results;
            } else {
                setSearchParams({
                    ...pageParams,
                    page: '1',
                });

                enqueueSnackbar(
                    'No vocabs with malfunctioning required words found ❤️ Good job!'
                );
            }
        }

        return filteredVocabs;
    };

    const filteredVocabs = search(filterVocabs(reverse(vocabs)), searchQuery, {
        keys: [
            'linguistics.literal',
            'linguistics.lemma',
            'literal',
            'definition.literal',
        ],
        threshold: 0.1,
    });

    const pageCount = Math.ceil(filteredVocabs.length / PER_PAGE);

    const renderPagination = () => {
        if (filteredVocabs.length > PER_PAGE + 1)
            return (
                <Pagination
                    sx={{ my: 5, ml: 'auto' }}
                    onChange={onPageChange}
                    defaultPage={page}
                    count={pageCount}
                />
            );
        return <Fragment />;
    };

    const renderVocabs = () => {
        if (!filteredVocabs.length)
            return (
                <Fragment>
                    <Typography sx={{ mb: 6 }} align='center' fontSize={20}>
                        No vocabs found
                    </Typography>
                    {searchQuery.length && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'middle',
                                justifyContent: 'center',
                            }}
                        >
                            <Button variant='contained' onClick={addVocab}>
                                Add vocab "{searchQuery}"
                            </Button>
                        </Box>
                    )}
                </Fragment>
            );

        return (
            <Fragment>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Published</TableCell>
                            <TableCell>🔊</TableCell>
                            <TableCell>Vocab</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>Definition</TableCell>
                            {/* <TableCell>Level</TableCell> */}
                            <TableCell>Taxonomies</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {paginate(filteredVocabs, PER_PAGE, page).map(
                            (vocab, index) => {
                                return (
                                    <VocabsListRow key={index} vocab={vocab} />
                                );
                            }
                        )}
                    </TableBody>
                </Table>

                <Stack>{renderPagination()}</Stack>
            </Fragment>
        );
    };

    return (
        <Stack my={6}>
            <Paper sx={{ p: 1 }}>
                <Stack
                    mb={4}
                    justifyContent='stretch'
                    direction='row'
                    spacing={2}
                >
                    <TextField
                        placeholder='Search...'
                        sx={{ width: 300, flexGrow: 2 }}
                        value={searchQuery}
                        onChange={event => onSearch(event.target.value)}
                    />
                    <Typography
                        variant='overline'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 10,
                        }}
                    >
                        Filter…
                    </Typography>
                    <TextField
                        select
                        label="🏳️‍⚧️"
                        sx={{ minWidth: '100px' }}
                        onChange={(event) => {
                            let language = event.target.value;

                            setSearchParams({
                                ...pageParams,
                                byLanguage: language === 'all' ? '' : language,
                            });
                        }}
                        value={byLanguage}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="en">EN</MenuItem>
                        <MenuItem value="de">DE</MenuItem>
                        <MenuItem value="fr">FR</MenuItem>
                        <MenuItem value="es">ES</MenuItem>
                        <MenuItem value="it">IT</MenuItem>
                        <MenuItem value="ru">RU</MenuItem>
                    </TextField>
                    <Tooltip title='only broken vocabs'>
                        <ToggleButton
                            selected={!!onlyErroneous}
                            onChange={event => {
                                setSearchParams(
                                    onlyErroneous
                                        ? { ...pageParams }
                                        : {
                                              ...pageParams,
                                              onlyErroneous: '1',
                                              page: '1',
                                          }
                                );
                            }}
                            value=''
                        >
                            <Box component={AlertTriangle} />
                        </ToggleButton>
                    </Tooltip>
                    <ByStatusFilter
                        onChange={byStatus =>
                            byStatus === 'all'
                                ? setSearchParams({
                                      ...pageParams,
                                  })
                                : setSearchParams({
                                      ...pageParams,
                                      byStatus,
                                      page: '1',
                                  })
                        }
                        value={byStatus}
                    />
                </Stack>

                {renderVocabs()}
            </Paper>
        </Stack>
    );
}
