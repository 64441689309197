import { Fragment } from 'react';
import { useGetUsers } from 'api';
import { Loader, ServerError } from 'components';
import {
    Table,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
} from '@mui/material';
import { UsersListRow } from './UsersListRow';

export function UsersList() {
    const {
        data: users,
        isLoading,
        refetch: refetchUsers,
        isError,
    } = useGetUsers({
        query: { select: data => data.result },
    });

    if (isLoading) {
        return <Loader />;
    }

    if (isError || !users) {
        return <ServerError onRetry={refetchUsers} />;
    }

    return (
        <Fragment>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Joined at</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {/* {renderJson(users)} */}
                    {users.map(user => (
                        <UsersListRow key={'user-' + user.id} user={user} />
                    ))}
                </TableBody>
            </Table>
        </Fragment>
    );
}
