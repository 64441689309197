import * as Yup from 'yup';

export const AddVocabValidationSchema = Yup.object().shape({
    literal: Yup.string().required('Required'),
    definition: Yup.object()
        .shape({
            literal: Yup.string(),
            language: Yup.string(),
        })
        .required('Required'),
    language: Yup.string().required('Required'),
    translation: Yup.string().required('Required'),
    tts_text: Yup.string(),
    level: Yup.string(),
    required_words: Yup.array(),
    taxonomies: Yup.object(),
});
