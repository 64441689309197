import async from './components/Async';
import { RouteObject, Navigate, generatePath } from 'react-router-dom';
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Guards
import AuthGuard from 'components/guards/AuthGuard';
import GuestGuard from 'components/guards/GuestGuard';

// Auth components
const SignIn = async(() => import('./pages/auth/SignIn'));
const ResetPassword = async(() => import('./pages/auth/ResetPassword'));
const Page404 = async(() => import('./pages/auth/Page404'));
const Page500 = async(() => import('./pages/auth/Page500'));

// Vocabs
const AddVocab = async(() => import('./pages/vocabs/AddVocab'));
const Vocabs = async(() => import('./pages/vocabs/Vocabs'));
const EditVocab = async(() => import('./pages/vocabs/EditVocab'));

const EditVocabToken = async(
    () => import('./pages/linguistics/EditVocabToken')
);

const CSVImport = async(() => import('pages/import/ImportCSV'));

const ForceUpdate = async(() => import('./pages/settings/ForceUpdate'));
import Testing from 'pages/testing/Testing';

import ProtectedPage from './pages/protected/ProtectedPage';

// Users
const UsersList = async(() => import('./pages/users/Users'));
const UserView = async(() => import('./pages/users/UserView'));

const routes: RouteObject[] = [
    {
        path: 'auth',
        element: <AuthLayout />,
        name: 'authentication',
        children: [
            {
                path: 'sign-in',
                element: <SignIn />,
                name: 'signIn',
            },
            {
                path: 'reset-password',
                element: <ResetPassword />,
                name: 'resetPassword',
            },
            {
                path: '404',
                element: <Page404 />,
                name: 'notFound',
            },
            {
                path: '500',
                element: <Page500 />,
                name: 'serverError',
            },
        ],
    },

    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        name: 'dashboard',
        children: [
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        name: 'users',
                        element: <UsersList />,
                    },
                    {
                        path: ':userId',
                        name: 'viewUser',
                        element: <UserView />,
                    },
                ],
            },
            {
                path: 'vocabs',
                name: 'vocabs',
                children: [
                    {
                        path: '',
                        element: <Vocabs />,
                        name: 'vocabs',
                    },
                    {
                        path: 'add',
                        element: <AddVocab />,
                        name: 'addVocab',
                    },
                    {
                        path: 'edit/:vocabId',
                        element: <EditVocab />,
                        name: 'editVocab',
                    },
                    {
                        path: 'edit/:vocabId/token/:tokenIndex',
                        element: <EditVocabToken />,
                        name: 'editVocabToken',
                    },
                    {
                        path: 'import/csv',
                        element: <CSVImport />,
                        name: 'importCSV',
                    },
                ],
            },
            {
                path: 'testing',
                name: 'testing',
                children: [
                    {
                        path: '',
                        element: <Testing />,
                        name: 'testing-panel',
                    },
                ],
            },
            {
                path: 'settings',
                name: 'settings',
                children: [
                    {
                        path: 'force-update',
                        element: <ForceUpdate />,
                        name: 'force-update',
                    },
                ],
            },
            {
                path: '',
                element: <ProtectedPage />,
                name: 'protectedPage',
            },
        ],
    },

    {
        path: '*',
        name: 'redirection',
        element: <Navigate to='dashboard' />,
    },
];

export default routes;
