import { ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ServerError } from 'components';

export function ErrorBoundary(props: { children: ReactNode }): JSX.Element {
    return (
        <ReactErrorBoundary
            FallbackComponent={({ error, resetErrorBoundary }) => (
                <ServerError
                    title={error.name}
                    description={error.message}
                    onRetry={resetErrorBoundary}
                />
            )}
        >
            {props.children}
        </ReactErrorBoundary>
    );
}
