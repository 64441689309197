import localeEmoji from 'locale-emoji';
import { LoadingButton } from '@mui/lab';
import { Stack, styled, TableCell, TableRow, Tooltip } from '@mui/material';
import {
    getGetVocabsQueryKey,
    LanguageSchema,
    useDeleteVocabById,
    Vocab,
    VocabSuccessResponseDto,
} from 'api';
import { AudioPlayer, ConfirmModal, StatusIndicator } from 'components';
import { useLanguages } from 'hooks/useLanguages';
import { useSnackbar } from 'hooks/useSnackbar';
import produce from 'immer';
import { filter, find, isArray, map } from 'lodash';
import { Fragment } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';
import { createRoute } from 'utils/createRoute';
import { withBaseURL } from 'utils/withBaseURL';
import { highlightVocab } from 'utils/highlightVocab';
import { HighlightedVocab } from 'utils/highlightVocab';
import { renderObjectCell } from 'utils/renderObjectCell';

export const Highlight = styled('u')`
    background: #ffee72;
    padding: 3px 8px;
    border-radius: 50px;
    text-decoration: none;
`;

type Props = {
    vocab: Vocab;
};

export function VocabsListRow({ vocab }: Props) {
    const [open, toggleOpen] = useToggle(false);

    const {
        mutate: deleteVocab,
        isLoading: deleteVocabIsLoading,
        variables,
    } = useDeleteVocabById();

    const refetchVocabs = () => {
        queryClient.invalidateQueries(getGetVocabsQueryKey());
    };
    const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> =
        event => {
            event.preventDefault();
            toggleOpen();
        };
    const [enqueueSnackbar] = useSnackbar();

    const handleClose = toggleOpen;
    const queryClient = useQueryClient();
    const { loading: languagesLoading, languages } = useLanguages();

    const handleDeleteVocab = (vocabId: string) => () => {
        handleClose();
        deleteVocab(
            {
                id: vocabId,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar(`Vocab deleted successfully.`);
                    /* refetchVocabs(); */

                    const allVocabs = queryClient.getQueryData(
                        getGetVocabsQueryKey()
                    ) as VocabSuccessResponseDto;

                    /* Removing deleted vocab from cache */
                    if (allVocabs?.result && isArray(allVocabs?.result)) {
                        queryClient.setQueryData(
                            getGetVocabsQueryKey(),
                            produce(allVocabs, draftState => {
                                draftState.result = draftState.result.filter(
                                    vocab => vocab.id !== vocabId
                                );
                            })
                        );
                    }
                },
                onError: () => {
                    enqueueSnackbar(`Something went wrong in deleting vocab`);
                },
                onSettled: () => {},
            }
        );
    };
    const isDeleting = (vocabId: string) => {
        return variables?.id === vocabId && deleteVocabIsLoading;
    };

    const renderLiteral = (vocab: Vocab) => {
        if (languagesLoading) {
            return <Fragment>Loading…</Fragment>;
        }

        const language = find(languages, {
            lang_code: vocab.language!,
        }) as LanguageSchema;

        const requiredWords = filter(vocab.linguistics, (token: any) =>
            map(vocab.required_words, 'id')?.includes(token.id)
        );

        const parts = highlightVocab({
            phrase: vocab.literal!,
            linguistics: vocab.linguistics!,
            requiredWords,
            language,
        });

        return parts.map(({ isVocab, literal }: HighlightedVocab, key) =>
            isVocab ? (
                <Highlight key={key}>{literal}</Highlight>
            ) : (
                <Fragment key={key}>{literal}</Fragment>
            )
        );
    };
    return (
        <TableRow
            key={vocab.id}
            component={deleteVocabIsLoading ? `div` : Link}
            to={createRoute('editVocab', {
                vocabId: vocab.id,
            })}
            sx={{ textDecoration: 'none' }}
        >
            <TableCell>
                <StatusIndicator status={vocab.status || 'draft'} />
            </TableCell>
            <TableCell
                component='th'
                scope='vocab'
                sx={{ whiteSpace: 'nowrap' }}
            >
                <AudioPlayer src={withBaseURL(`/${vocab.audio_path}`)} />
            </TableCell>
            <TableCell>{renderLiteral(vocab)}</TableCell>
            <TableCell>
                {vocab.language ? localeEmoji(vocab.language) : '-'}
            </TableCell>
            <TableCell>
                {vocab.definition?.literal} ({vocab.definition?.language})
            </TableCell>
            <TableCell>{renderObjectCell(vocab?.taxonomies)}</TableCell>
            <TableCell>
                <LoadingButton
                    variant='outlined'
                    color='error'
                    onClick={handleDeleteClick}
                    loading={isDeleting(vocab.id as string)}
                >
                    Delete
                </LoadingButton>
                <ConfirmModal
                    title='Are you sure?'
                    description="This action can't be undone"
                    onConfirm={handleDeleteVocab(vocab.id!)}
                    onCancel={handleClose}
                    open={open}
                />
            </TableCell>
        </TableRow>
    );
}
