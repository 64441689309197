import { Stack, Box, Tooltip } from '@mui/material';

type Props = {
    onChange: (value: string) => void;
    value?: string;
};

type ValueProps = {
    [key: string]: string;
};

export function ByStatusFilter({ onChange, value = 'all' }: Props) {
    if (!value.length) {
        value = 'all';
    }

    const states = ['all', 'draft', 'published'];
    const styles: ValueProps = {
        all: 'transparent',
        published: 'limegreen',
        draft: 'crimson',
    };

    const nextState = (states.indexOf(value) + 1) % states.length;

    return (
        <Box
            pl={2}
            pr={3}
            alignItems='center'
            sx={{ cursor: 'pointer', display: 'flex' }}
            onClick={() => onChange(states[nextState])}
        >
            <Tooltip title={`by status (${value})`}>
                <Box
                    sx={{
                        backgroundColor: styles[value] as string,
                        border: '1px solid #CCC',
                        display: 'inline-block',
                        borderRadius: '100%',
                        width: 20,
                        height: 20,
                    }}
                ></Box>
            </Tooltip>
        </Box>
    );
}
