/**
 * Generated by orval v6.2.3 🍺
 * Do not edit manually.
 * HeyLama
 * The heylama API description
 * OpenAPI spec version: 2.0
 */
import {
    useQuery,
    useMutation,
    UseQueryOptions,
    UseMutationOptions,
    QueryFunction,
    MutationFunction,
} from 'react-query';
export type UserErrorResponseDtoError = {};

export interface UserErrorResponseDto {
    success: boolean;
    error: UserErrorResponseDtoError;
}

export interface Language {
    learning: string[];
    speaking: string[];
}

export type PublicProfileStatus = 'joined' | 'invited' | 'disabled' | 'deleted';

export const PublicProfileStatus = {
    joined: 'joined' as PublicProfileStatus,
    invited: 'invited' as PublicProfileStatus,
    disabled: 'disabled' as PublicProfileStatus,
    deleted: 'deleted' as PublicProfileStatus,
};

export interface PublicProfile {
    email: string;
    email_verified?: boolean;
    name?: string;
    goals?: string[];
    nickname?: string;
    first_name?: string;
    full_name?: string;
    last_name?: string;
    profile_pic?: string;
    languages?: Language;
    status?: PublicProfileStatus;
}

export type UserStoryMemory = {};

export interface UserStory {
    storyName?: string;
    chapter?: string;
    turnIndex?: number;
    memory?: UserStoryMemory;
}

export type TelegramConnectionSessionStorage = {};

export interface TelegramConnection {
    user_id?: string;
    username?: string;
    token?: string;
    userStory?: UserStory;
    sessionStorage?: TelegramConnectionSessionStorage;
}

export type WhatsappConnectionSessionStorage = {};

export interface WhatsappConnection {
    user_id?: string;
    token?: string;
    userStory?: UserStory;
    sessionStorage?: WhatsappConnectionSessionStorage;
}

export interface UserConnections {
    telegram?: TelegramConnection;
    whatsapp?: WhatsappConnection;
}

export interface PrivateProfile {
    reset_password_token?: string;
    created?: string;
    userTimezone?: string;
    preferred_notification_times?: number[];
    connections?: UserConnections;
    environment?: string;
}

export interface UserProfile {
    public: PublicProfile;
    private?: PrivateProfile;
}

export type UserHistoryActions = {};

export interface UserHistory {
    actions?: UserHistoryActions;
}

export type UserRolesItem = 'student' | 'admin' | 'tutor' | 'demo' | 'tester';

export const UserRolesItem = {
    student: 'student' as UserRolesItem,
    admin: 'admin' as UserRolesItem,
    tutor: 'tutor' as UserRolesItem,
    demo: 'demo' as UserRolesItem,
    tester: 'tester' as UserRolesItem,
};

export type UserSettings = { [key: string]: any };

export interface User {
    id?: string;
    slug?: string;
    profile?: UserProfile;
    roles?: UserRolesItem[];
    is_new_user?: boolean;
    settings?: UserSettings;
    history?: UserHistory;
    connections?: UserConnections;
}

export interface UserSuccessResponseDto {
    success: boolean;
    result: User[];
}

export interface UserPublicProfileSuccessResponseDto {
    success: boolean;
    result: PublicProfile;
}

export type UserActionDtoAction = {};

export interface UserActionDto {
    action: UserActionDtoAction;
}

export interface CreateTelegramMessageDto {
    id?: string;
    messageId: number;
    turnName: string;
    user_id: string;
}

export type CreateUserDtoRolesItem =
    | 'admin'
    | 'demo'
    | 'student'
    | 'tester'
    | 'tutor';

export const CreateUserDtoRolesItem = {
    admin: 'admin' as CreateUserDtoRolesItem,
    demo: 'demo' as CreateUserDtoRolesItem,
    student: 'student' as CreateUserDtoRolesItem,
    tester: 'tester' as CreateUserDtoRolesItem,
    tutor: 'tutor' as CreateUserDtoRolesItem,
};

export interface CreateUserDto {
    email: string;
    name?: string;
    profile?: UserProfile;
    roles?: CreateUserDtoRolesItem[];
    password: string;
}

export interface AuthUser {
    user: User;
    /** expiration time */
    exp?: number;
    /** user access token */
    accessToken?: string;
}

export interface AuthUserSuccessResponseDto {
    success: boolean;
    result: AuthUser;
}

export type UpdateUserDtoSettings = { [key: string]: any };

export interface UpdateUserDto {
    profile?: UserProfile;
    roles?: string;
    settings?: UpdateUserDtoSettings;
    password?: string;
}

export interface UserEmail {
    id?: string;
    content?: string;
    email?: string;
    user_id?: string;
    created_at?: string;
    modified_at?: string;
    deleted_at?: string;
}

export interface UserEmailsSuccessResponseDto {
    success: boolean;
    result: UserEmail[];
}

export interface UploadUserAudioDto {
    audio: Blob;
}

export interface FileUploadDo {
    file_uri: string;
}

export interface UserFileUploadSuccessResponseDto {
    success: boolean;
    result: FileUploadDo;
}

export interface SignInDto {
    email: string;
    password: string;
    as_tester?: boolean;
    environment?: string;
    userTime?: string;
    userTimezone?: string;
    is_admin_login?: boolean;
}

export interface ImpersonateDto {
    user_id: string;
}

export interface SendResetPasswordLinkDto {
    email: string;
}

export interface SentResetPasswordLink {
    sent: boolean;
}

export interface SentResetPasswordLinkSuccessResponseDto {
    success: boolean;
    result: SentResetPasswordLink;
}

export interface ResetPasswordDto {
    token: string;
    password: string;
}

export interface PasswordlessSignInDto {
    email: string;
    as_tester?: boolean;
    environment?: string;
    userTime?: string;
    userTimezone?: string;
    name?: string;
}

export interface VerifyUserTokenDto {
    email?: string;
    id?: string;
    token: string;
}

export interface SingleUserSuccessResponseDto {
    success: boolean;
    result: User;
}

export interface MemoryPracticeData {
    user_vocab_id?: string;
    remembered?: boolean;
}

export interface PracticeUserMemoryChallengeTaskDto {
    practice_data: MemoryPracticeData;
}

export interface VocabDefinition {
    language?: string;
    literal?: string;
}

export type LinguisticTokenMorphological = {};

export interface LinguisticToken {
    id?: string;
    literal?: string;
    as_in?: string;
    key?: string;
    language?: string;
    audio_path?: string;
    lemma?: string;
    pos?: string;
    xpos?: string;
    morphological?: LinguisticTokenMorphological;
    created_at?: string;
    deprel: string;
    head: number;
    translation?: string;
}

export type WordMorphological = {};

export interface Word {
    id?: string;
    literal?: string;
    as_in?: string;
    key?: string;
    language?: string;
    audio_path?: string;
    lemma?: string;
    pos?: string;
    xpos?: string;
    morphological?: WordMorphological;
    created_at?: string;
}

export interface Creator {
    user_id?: string;
}

export interface SpacedRepetition {
    state?: number;
    due_date?: number;
    mastered?: string;
    turns?: boolean[];
}

export type UserVocabTaxonomies = {};

export interface UserVocab {
    id?: string;
    vocab_id?: string;
    student_id?: string;
    literal?: string;
    emoji?: string;
    categories?: string[];
    definition?: VocabDefinition;
    explanation?: string;
    example?: string;
    key?: string;
    length?: number;
    language?: string;
    linguistics?: LinguisticToken[];
    taxonomies?: UserVocabTaxonomies;
    required_words?: Word[];
    translation?: string;
    creator?: Creator;
    spaced_repetition?: SpacedRepetition;
    suggested_at?: string;
    seen?: boolean;
    practice_count?: number;
    audio_path?: string;
    image_path?: string;
    deleted_at?: string;
    created_at?: string;
    modified_at?: string;
}

export interface TranscriptionOverride {
    replace: string;
    by: string;
}

export interface Idiom {
    literal?: string;
    translation?: string;
}

export type VocabStatus = 'draft' | 'published';

export const VocabStatus = {
    draft: 'draft' as VocabStatus,
    published: 'published' as VocabStatus,
};

export type VocabTaxonomies = {};

export interface Vocab {
    id?: string;
    literal?: string;
    emoji?: string;
    categories?: string[];
    key?: string;
    status?: VocabStatus;
    definition?: VocabDefinition;
    explanation?: string;
    tts_text?: string;
    length?: number;
    language?: string;
    example?: string;
    linguistics?: LinguisticToken[];
    required_words?: Word[];
    related_vocabs?: Vocab[];
    taxonomies?: VocabTaxonomies;
    translation?: string;
    audio_path?: string;
    image_path?: string;
    transcription_overrides?: TranscriptionOverride[];
    times_used?: number;
    baseform?: string;
    sense?: string;
    wordclass?: string;
    syllables?: string[];
    ipa?: string;
    topic?: string;
    idioms?: Idiom[];
}

export type ExampleStatus = 'draft' | 'published';

export const ExampleStatus = {
    draft: 'draft' as ExampleStatus,
    published: 'published' as ExampleStatus,
};

export type ExampleExerciseTypeItem = 'vocab' | 'speak' | 'listen';

export const ExampleExerciseTypeItem = {
    vocab: 'vocab' as ExampleExerciseTypeItem,
    speak: 'speak' as ExampleExerciseTypeItem,
    listen: 'listen' as ExampleExerciseTypeItem,
};

export interface Example {
    id?: string;
    literal?: string;
    status?: ExampleStatus;
    cefr_level?: number;
    priority?: number;
    exercise_type?: ExampleExerciseTypeItem[];
    instructions?: string;
    instructions_translation?: string;
    tts_text?: string;
    length?: number;
    language?: string;
    linguistics?: LinguisticToken[];
    vocabs?: Vocab[];
    categories?: string[];
    translation?: string;
    audio_path?: string;
}

export type UserChallengeTaskExerciseOptions = {};

export type UserChallengeTaskType = 'listen' | 'vocab' | 'speak';

export const UserChallengeTaskType = {
    listen: 'listen' as UserChallengeTaskType,
    vocab: 'vocab' as UserChallengeTaskType,
    speak: 'speak' as UserChallengeTaskType,
};

export type UserChallengeTaskPracticeData = {};

export interface UserChallengeTask {
    id?: string;
    user_id?: string;
    user_challenge_id?: string;
    user_challenge_day_id?: string;
    title?: string;
    user_vocab: UserVocab;
    example: Example;
    exercise_options?: UserChallengeTaskExerciseOptions;
    type?: UserChallengeTaskType;
    is_last?: boolean;
    is_bonus?: boolean;
    is_correct?: boolean;
    started_at?: string;
    created_at?: string;
    modified_at?: string;
    deleted_at?: string;
    completed_at?: string;
    random_id?: number;
    practice_data?: UserChallengeTaskPracticeData;
}

export interface PracticeSuccessResponseDto {
    success: boolean;
    result: UserChallengeTask;
}

export type UserChallengeErrorResponseDtoError = {};

export interface UserChallengeErrorResponseDto {
    success: boolean;
    error: UserChallengeErrorResponseDtoError;
}

export interface VocabSuccessResponseDto {
    success: boolean;
    result: Vocab[];
}

export type VocabErrorResponseDtoError = {};

export interface VocabErrorResponseDto {
    success: boolean;
    error: VocabErrorResponseDtoError;
}

export interface PaginatedVocabs {
    items: Vocab[];
    hasMore: boolean;
}

export interface AdminPanelVocabSuccessResponseDto {
    success: boolean;
    result: PaginatedVocabs;
}

export interface SingleVocabSuccessResponseDto {
    success: boolean;
    result: Vocab;
}

export type CreateVocabDtoStatus = 'draft' | 'published';

export const CreateVocabDtoStatus = {
    draft: 'draft' as CreateVocabDtoStatus,
    published: 'published' as CreateVocabDtoStatus,
};

export type CreateVocabDtoTaxonomies = {};

export interface CreateVocabDto {
    literal: string;
    emoji?: string;
    key?: string;
    status?: CreateVocabDtoStatus;
    definition?: VocabDefinition;
    explanation?: string;
    language: string;
    example: string;
    tts_text?: string;
    taxonomies?: CreateVocabDtoTaxonomies;
    required_words?: string[];
    related_vocabs?: string[];
    transcription_overrides?: TranscriptionOverride[];
    translation?: string;
    image?: Blob;
    times_used?: number;
    baseform?: string;
    sense?: string;
    wordclass?: string;
    syllables?: string[];
    ipa?: string;
    topic?: string;
    idioms?: Idiom[];
}

export interface UpdateVocabLinguisticToken {
    tokenIndex?: number;
    data?: LinguisticToken;
}

export type UpdateVocabDtoStatus = 'draft' | 'published';

export const UpdateVocabDtoStatus = {
    draft: 'draft' as UpdateVocabDtoStatus,
    published: 'published' as UpdateVocabDtoStatus,
};

export type UpdateVocabDtoTaxonomies = {};

export interface UpdateVocabDto {
    literal?: string;
    key?: string;
    emoji?: string;
    status?: UpdateVocabDtoStatus;
    definition?: VocabDefinition;
    explanation?: string;
    language?: string;
    example?: string;
    tts_text?: string;
    cefr_level?: number;
    required_words?: string[];
    related_vocabs?: string[];
    linguistic?: UpdateVocabLinguisticToken;
    linguistics?: LinguisticToken[];
    translation?: string;
    transcription_overrides?: TranscriptionOverride[];
    taxonomies?: UpdateVocabDtoTaxonomies;
    image?: Blob;
    baseform?: string;
    sense?: string;
    wordclass?: string;
    syllables?: string[];
    ipa?: string;
    topic?: string;
    idioms?: Idiom[];
}

export interface ImportVocabFromCSVDto {
    path: string;
}

export interface TranslateLiteralDto {
    literal: string;
    sourceLanguage: string;
    targetLanguage: string;
}

export interface TranslationSuccessResponseDto {
    success: boolean;
    result: string[];
}

export interface WordSuccessResponseDto {
    success: boolean;
    result: Word[];
}

export type WordErrorResponseDtoError = {};

export interface WordErrorResponseDto {
    success: boolean;
    error: WordErrorResponseDtoError;
}

export type UpdateWordDtoMorphological = {};

export interface UpdateWordDto {
    translation?: string;
    language?: string;
    key?: string;
    lemma?: string;
    pos?: string;
    xpos?: string;
    morphological?: UpdateWordDtoMorphological;
    as_in?: string;
}

export interface ExamplesSuccessResponseDto {
    success: boolean;
    result: Example[];
}

export type ExampleErrorResponseDtoError = {};

export interface ExampleErrorResponseDto {
    success: boolean;
    error: ExampleErrorResponseDtoError;
}

export interface SingleExampleSuccessResponseDto {
    success: boolean;
    result: Example;
}

export type CreateExampleDtoStatus = 'draft' | 'published';

export const CreateExampleDtoStatus = {
    draft: 'draft' as CreateExampleDtoStatus,
    published: 'published' as CreateExampleDtoStatus,
};

export type CreateExampleDtoExerciseTypeItem = 'vocab' | 'speak' | 'listen';

export const CreateExampleDtoExerciseTypeItem = {
    vocab: 'vocab' as CreateExampleDtoExerciseTypeItem,
    speak: 'speak' as CreateExampleDtoExerciseTypeItem,
    listen: 'listen' as CreateExampleDtoExerciseTypeItem,
};

export interface CreateExampleDto {
    literal: string;
    status?: CreateExampleDtoStatus;
    cefr_level?: number;
    priority?: number;
    exercise_type: CreateExampleDtoExerciseTypeItem[];
    instructions?: string;
    instructions_translation?: string;
    language?: string;
    tts_text?: string;
    vocabs: string[];
    categories?: string[];
    translation: string;
}

export interface UpdateExampleLinguisticToken {
    tokenIndex?: number;
    data?: LinguisticToken;
}

export type UpdateExampleDtoStatus = 'draft' | 'published';

export const UpdateExampleDtoStatus = {
    draft: 'draft' as UpdateExampleDtoStatus,
    published: 'published' as UpdateExampleDtoStatus,
};

export type UpdateExampleDtoExerciseTypeItem = 'vocab' | 'speak' | 'listen';

export const UpdateExampleDtoExerciseTypeItem = {
    vocab: 'vocab' as UpdateExampleDtoExerciseTypeItem,
    speak: 'speak' as UpdateExampleDtoExerciseTypeItem,
    listen: 'listen' as UpdateExampleDtoExerciseTypeItem,
};

export interface UpdateExampleDto {
    literal?: string;
    status?: UpdateExampleDtoStatus;
    cefr_level?: number;
    priority?: number;
    exercise_type?: UpdateExampleDtoExerciseTypeItem[];
    instructions?: string;
    instructions_translation?: string;
    language?: string;
    tts_text?: string;
    vocabs?: string[];
    linguistic?: UpdateExampleLinguisticToken;
    linguistics?: LinguisticToken[];
    translation?: string;
}

export interface ParseExampleDto {
    text: string;
    language: string;
}

export interface ParsedExampleSuccessResponseDto {
    success: boolean;
    result: LinguisticToken[];
}

export interface UserVocabSuccessResponseDto {
    success: boolean;
    result: UserVocab[];
}

export type UserVocabErrorResponseDtoError = {};

export interface UserVocabErrorResponseDto {
    success: boolean;
    error: UserVocabErrorResponseDtoError;
}

export interface NextReviewDate {
    next_review_date: string;
    user_vocab_id: string;
    user_vocab?: UserVocab;
}

export interface NextReviewDateSuccessResponseDto {
    success: boolean;
    result: NextReviewDate;
}

export interface UserVocabsCount {
    count: number;
}

export interface UserVocabsCountSuccessResponseDto {
    success: boolean;
    result: UserVocabsCount;
}

export interface SingleUserVocabSuccessResponseDto {
    success: boolean;
    result: UserVocab;
}

export type CreateUserVocabDtoTaxonomies = {};

export interface CreateUserVocabDto {
    vocab_id?: string;
    student_id: string;
    literal: string;
    emoji?: string;
    definition?: VocabDefinition;
    explanation?: string;
    example?: string;
    key?: string;
    language?: string;
    linguistics?: string[];
    required_words?: string[];
    spaced_repetition?: SpacedRepetition;
    creator?: Creator;
    taxonomies?: CreateUserVocabDtoTaxonomies;
    seen?: boolean;
    translation?: string;
    audio_path?: string;
}

export interface CreateUserVocabsDto {
    vocabs: CreateUserVocabDto[];
}

export interface PickUserVocabByVocabIdDto {
    vocabs: string[];
}

export interface UpdateUserVocabDto {
    student_id?: string;
    literal?: string;
    definition?: VocabDefinition;
    example?: string;
    emoji?: string;
    explanation?: string;
    language?: string;
    translation?: string;
    spaced_repetition?: SpacedRepetition;
    audio_path?: string;
    image_path?: string;
}

export interface ImportUserVocabFromCSVDto {
    path: string;
}

export interface ImportFromArticleDto {
    article?: string;
    language?: string;
}

export type FlaggedTokenMorphological = {};

export interface FlaggedToken {
    id?: string;
    literal?: string;
    as_in?: string;
    key?: string;
    language?: string;
    audio_path?: string;
    lemma?: string;
    pos?: string;
    xpos?: string;
    morphological?: FlaggedTokenMorphological;
    created_at?: string;
    deprel: string;
    head: number;
    translation?: string;
    is_vocab: string;
}

export interface TokenSuccessResponseDto {
    success: boolean;
    result: FlaggedToken[];
}

export type TokenErrorResponseDtoError = {};

export interface TokenErrorResponseDto {
    success: boolean;
    error: TokenErrorResponseDtoError;
}

export interface CultureInsight {
    image_url?: string;
    blog_url?: string;
    description?: string;
}

export interface ChallengeDay {
    title?: string;
    instructions?: string;
    culture_insight?: CultureInsight;
    tasks_count?: number;
    filter?: string;
}

export type ChallengeExerciseTypesItem = 'vocab' | 'speak' | 'listen';

export const ChallengeExerciseTypesItem = {
    vocab: 'vocab' as ChallengeExerciseTypesItem,
    speak: 'speak' as ChallengeExerciseTypesItem,
    listen: 'listen' as ChallengeExerciseTypesItem,
};

export type ChallengeChallengeType = 'vocabs' | 'grammar';

export const ChallengeChallengeType = {
    vocabs: 'vocabs' as ChallengeChallengeType,
    grammar: 'grammar' as ChallengeChallengeType,
};

export type ChallengeStatus = 'draft' | 'published';

export const ChallengeStatus = {
    draft: 'draft' as ChallengeStatus,
    published: 'published' as ChallengeStatus,
};

export interface Challenge {
    id?: string;
    name?: string;
    description?: string;
    language?: string;
    cefr_level?: string;
    exercise_types?: ChallengeExerciseTypesItem[];
    challenge_type?: ChallengeChallengeType;
    examples_per_vocab?: number;
    vocabs_suggested_per_day?: number;
    vocab_picking_limit?: number;
    certificate_text?: string;
    certificate_id?: string;
    creator_id?: string;
    image_path?: string;
    days?: ChallengeDay[];
    status?: ChallengeStatus;
    created_at?: string;
    modified_at?: string;
}

export interface ChallengesSuccessResponseDto {
    success: boolean;
    result: Challenge[];
}

export type ChallengesErrorResponseDtoError = {};

export interface ChallengesErrorResponseDto {
    success: boolean;
    error: ChallengesErrorResponseDtoError;
}

export interface SingleChallengeSuccessResponseDto {
    success: boolean;
    result: Challenge;
}

export interface ChallengeReport {
    passed: boolean;
    messages: string[];
}

export interface ValidChallengeSuccessResponseDto {
    success: boolean;
    result: ChallengeReport;
}

export type CreateChallengeDtoExerciseTypesItem = 'vocab' | 'speak' | 'listen';

export const CreateChallengeDtoExerciseTypesItem = {
    vocab: 'vocab' as CreateChallengeDtoExerciseTypesItem,
    speak: 'speak' as CreateChallengeDtoExerciseTypesItem,
    listen: 'listen' as CreateChallengeDtoExerciseTypesItem,
};

export type CreateChallengeDtoChallengeType = 'vocabs' | 'grammar';

export const CreateChallengeDtoChallengeType = {
    vocabs: 'vocabs' as CreateChallengeDtoChallengeType,
    grammar: 'grammar' as CreateChallengeDtoChallengeType,
};

export type CreateChallengeDtoStatus = 'draft' | 'published';

export const CreateChallengeDtoStatus = {
    draft: 'draft' as CreateChallengeDtoStatus,
    published: 'published' as CreateChallengeDtoStatus,
};

export interface CreateChallengeDto {
    name?: string;
    language?: string;
    cefr_level?: string;
    description?: string;
    exercise_types?: CreateChallengeDtoExerciseTypesItem[];
    challenge_type?: CreateChallengeDtoChallengeType;
    examples_per_vocab?: number;
    vocab_picking_limit?: number;
    vocabs_suggested_per_day?: number;
    certificate_text?: string;
    certificate_id?: string;
    creator_id?: string;
    image_path?: string;
    status?: CreateChallengeDtoStatus;
    days?: ChallengeDay[];
    created_at?: string;
    modified_at?: string;
}

export type UpdateChallengeDtoExerciseTypesItem = 'vocab' | 'speak' | 'listen';

export const UpdateChallengeDtoExerciseTypesItem = {
    vocab: 'vocab' as UpdateChallengeDtoExerciseTypesItem,
    speak: 'speak' as UpdateChallengeDtoExerciseTypesItem,
    listen: 'listen' as UpdateChallengeDtoExerciseTypesItem,
};

export type UpdateChallengeDtoChallengeType = 'vocabs' | 'grammar';

export const UpdateChallengeDtoChallengeType = {
    vocabs: 'vocabs' as UpdateChallengeDtoChallengeType,
    grammar: 'grammar' as UpdateChallengeDtoChallengeType,
};

export type UpdateChallengeDtoStatus = 'draft' | 'published';

export const UpdateChallengeDtoStatus = {
    draft: 'draft' as UpdateChallengeDtoStatus,
    published: 'published' as UpdateChallengeDtoStatus,
};

export interface UpdateChallengeDto {
    name?: string;
    language?: string;
    cefr_level?: string;
    description?: string;
    exercise_types?: UpdateChallengeDtoExerciseTypesItem[];
    challenge_type?: UpdateChallengeDtoChallengeType;
    examples_per_vocab?: number;
    vocab_picking_limit?: number;
    vocabs_suggested_per_day?: number;
    certificate_text?: string;
    certificate_id?: string;
    creator_id?: string;
    image_path?: string;
    status?: UpdateChallengeDtoStatus;
    days?: ChallengeDay[];
    created_at?: string;
    modified_at?: string;
}

export interface ChallengeDeleted {
    deleted: boolean;
}

export interface DeletedChallengeSuccessResponseDto {
    success: boolean;
    result: ChallengeDeleted;
}

export interface SlackFieldEntity {
    title: string;
    value: string;
}

export interface SlackMessageDto {
    text: string;
    username?: string;
    severity?: string;
    error?: string;
    entity: SlackFieldEntity;
}

export interface SlackMessageSentSuccessfullyResponseDto {
    success: boolean;
    result: boolean;
}

export type SlackErrorResponseDtoError = {};

export interface SlackErrorResponseDto {
    success: boolean;
    error: SlackErrorResponseDtoError;
}

// tslint:disable-next-line:no-empty-interface
export interface TwilioPayload {}

export interface UserNotification {
    id?: string;
    entity?: string;
    entity_id?: string;
    user_id?: string;
    dismissable?: boolean;
    created_at?: string;
    dismissed_at?: string;
    completed_at?: string;
}

export interface UserNotificationSuccessResponseDto {
    success: boolean;
    result: UserNotification[];
}

export type UserNotificationErrorResponseDtoError = {};

export interface UserNotificationErrorResponseDto {
    success: boolean;
    error: UserNotificationErrorResponseDtoError;
}

export interface CreateUserNotificationDto {
    user_email?: string;
    entity?: string;
    entity_id?: string;
    user_id?: string;
    dismissable: boolean;
    link?: string;
}

export interface SingleUserNotificationSuccessResponseDto {
    success: boolean;
    result: UserNotification;
}

export interface TelegramMessage {
    id?: string;
    messageId?: number;
    turnName?: string;
    user_id?: string;
    created_at?: string;
}

export interface TelegramMessagesSuccessResponseDto {
    success: boolean;
    result: TelegramMessage[];
}

export type TelegramMessageErrorResponseDtoError = {};

export interface TelegramMessageErrorResponseDto {
    success: boolean;
    error: TelegramMessageErrorResponseDtoError;
}

export interface SendTelegramMessageDto {
    message: string;
    userId?: string;
    token: string;
}

export interface SingleTelegramMessageSuccessResponseDto {
    success: boolean;
    result: TelegramMessage;
}

export interface CreateSuggestionDto {
    literal: string;
    language: string;
    type: string;
    definition?: VocabDefinition;
    emoji?: string;
    example?: string;
    created_at?: string;
}

export interface ImportSuggestionDto {
    path: string;
    vocabLanguage: string;
    definitionLanguage: string;
}

export interface IntegrationSuccessResponseDto {
    success: boolean;
}

export type IntegrationErrorResponseDtoError = {};

export interface IntegrationErrorResponseDto {
    success: boolean;
    error: IntegrationErrorResponseDtoError;
}

export interface ForceUpdateSignalResponse {
    updated_at: string;
    is_admin_update: boolean;
}

export interface LatestUpdateSignalSuccessResponseDto {
    success: boolean;
    result: ForceUpdateSignalResponse;
}

export type LatestUpdateSignalErrorResponseDtoError = {};

export interface LatestUpdateSignalErrorResponseDto {
    success: boolean;
    error: LatestUpdateSignalErrorResponseDtoError;
}

export interface LanguageSchema {
    name: string;
    lang_code: string;
    aliases?: string[];
    scr_code?: string;
    regions?: string[];
    special_characters?: string[];
    case_sensitive?: boolean;
    emoji?: string;
}

export interface LanguageSuccessResponseDto {
    success: boolean;
    result: LanguageSchema[];
}

export type LanguageErrorResponseDtoError = {};

export interface LanguageErrorResponseDto {
    success: boolean;
    error: LanguageErrorResponseDtoError;
}

export type TaxonomyDataType = 'string' | 'enum' | 'number' | 'relation';

export const TaxonomyDataType = {
    string: 'string' as TaxonomyDataType,
    enum: 'enum' as TaxonomyDataType,
    number: 'number' as TaxonomyDataType,
    relation: 'relation' as TaxonomyDataType,
};

export interface Taxonomy {
    id?: string;
    key?: string;
    name?: string;
    data_type?: TaxonomyDataType;
    allowed_values?: string[];
    relation?: string;
    multi_selection?: boolean;
    created_at?: string;
}

export interface TaxonomySuccessResponseDto {
    success: boolean;
    result: Taxonomy[];
}

export type TaxonomyErrorResponseDtoError = {};

export interface TaxonomyErrorResponseDto {
    success: boolean;
    error: TaxonomyErrorResponseDtoError;
}

export interface TaxonomyValuesSuccessResponseDto {
    success: boolean;
    result: string[];
}

export type CreateTaxonomyDtoDataType =
    | 'string'
    | 'enum'
    | 'number'
    | 'relation';

export const CreateTaxonomyDtoDataType = {
    string: 'string' as CreateTaxonomyDtoDataType,
    enum: 'enum' as CreateTaxonomyDtoDataType,
    number: 'number' as CreateTaxonomyDtoDataType,
    relation: 'relation' as CreateTaxonomyDtoDataType,
};

export interface CreateTaxonomyDto {
    key?: string;
    name: string;
    data_type: CreateTaxonomyDtoDataType;
    allowed_values?: string[];
    relation?: string;
    multi_selection?: boolean;
}

export type UpdateTaxonomyDtoDataType =
    | 'string'
    | 'enum'
    | 'number'
    | 'relation';

export const UpdateTaxonomyDtoDataType = {
    string: 'string' as UpdateTaxonomyDtoDataType,
    enum: 'enum' as UpdateTaxonomyDtoDataType,
    number: 'number' as UpdateTaxonomyDtoDataType,
    relation: 'relation' as UpdateTaxonomyDtoDataType,
};

export interface UpdateTaxonomyDto {
    name?: string;
    data_type?: UpdateTaxonomyDtoDataType;
    allowed_values?: string[];
    relation?: string;
    multi_selection?: boolean;
}

export interface FileUploadDto {
    file: Blob;
    path?: string;
}

export type DeckPrivacy = 'private' | 'public';

export const DeckPrivacy = {
    private: 'private' as DeckPrivacy,
    public: 'public' as DeckPrivacy,
};

export interface Deck {
    id?: string;
    title: string;
    times_used?: number;
    language?: string;
    entries?: string[];
    owner_id?: string;
    privacy?: DeckPrivacy;
    created_at?: string;
    archived_at?: string;
    deleted_at?: string;
    rejected_at?: string;
}

export interface DeckSuccessResponseDto {
    success: boolean;
    result: Deck[];
}

export type DeckErrorResponseDtoError = {};

export interface DeckErrorResponseDto {
    success: boolean;
    error: DeckErrorResponseDtoError;
}

export interface SingleDeckSuccessResponseDto {
    success: boolean;
    result: Deck;
}

export interface CreateDeckDto {
    entries: string[];
    title: string;
    owner_id: string;
}

export interface UpdateDeckDto {
    entries?: string[];
    title?: string;
    owner_id?: string;
    language?: string;
    definitionLanguage?: string;
}

export interface PickVocabsDto {
    vocabs: Vocab[];
}

export type PopulatedUserDeckPrivacy = 'private' | 'public';

export const PopulatedUserDeckPrivacy = {
    private: 'private' as PopulatedUserDeckPrivacy,
    public: 'public' as PopulatedUserDeckPrivacy,
};

export type PopulatedUserDeckFlagged = 'REVIEW_INAPPROPRIATE';

export const PopulatedUserDeckFlagged = {
    REVIEW_INAPPROPRIATE: 'REVIEW_INAPPROPRIATE' as PopulatedUserDeckFlagged,
};

export interface PopulatedUserDeck {
    id?: string;
    deck_id?: string;
    title: string;
    emoji?: string;
    times_used?: number;
    likes?: number;
    language?: string;
    definitionLanguage?: string;
    owner_id?: string;
    privacy?: PopulatedUserDeckPrivacy;
    verified?: boolean;
    flagged?: PopulatedUserDeckFlagged;
    created_at?: string;
    modified_at?: string;
    deleted_at?: string;
    active?: boolean;
    owner_name?: string;
    similarity?: number;
    entries?: UserVocab[];
}

export interface UserDeckSuccessResponseDto {
    success: boolean;
    result: PopulatedUserDeck[];
}

export type UserDeckErrorResponseDtoError = {};

export interface UserDeckErrorResponseDto {
    success: boolean;
    error: UserDeckErrorResponseDtoError;
}

export interface SingleUserDeckSuccessResponseDto {
    success: boolean;
    result: PopulatedUserDeck;
}

export type CreateUserDeckDtoPrivacy = 'private' | 'public';

export const CreateUserDeckDtoPrivacy = {
    private: 'private' as CreateUserDeckDtoPrivacy,
    public: 'public' as CreateUserDeckDtoPrivacy,
};

export interface CreateUserDeckDto {
    deck_id?: string;
    entries: string[];
    title: string;
    emoji?: string;
    language?: string;
    definitionLanguage?: string;
    privacy?: CreateUserDeckDtoPrivacy;
    owner_id: string;
}

export type UpdateUserDeckDtoPrivacy = 'private' | 'public';

export const UpdateUserDeckDtoPrivacy = {
    private: 'private' as UpdateUserDeckDtoPrivacy,
    public: 'public' as UpdateUserDeckDtoPrivacy,
};

export interface UpdateUserDeckDto {
    entries?: string[];
    title?: string;
    emoji?: string;
    owner_id?: string;
    privacy?: UpdateUserDeckDtoPrivacy;
    likes?: number;
    times_used?: number;
    language?: string;
    definitionLanguage?: string;
}

export interface AddEntryDto {
    entries: UserVocab[];
}

export interface CopyUserDeckDto {
    vocabs?: string[];
}

export type GetUsersCountFilter = {};

export interface GetUsersCount {
    from: string;
    to: string;
    filter?: GetUsersCountFilter;
}

export type GetUserVocabsCountDtoFilter = {};

export interface GetUserVocabsCountDto {
    from: string;
    to: string;
    filter?: GetUserVocabsCountDtoFilter;
}

export type GetPracticeTurnsCountFilter = {};

export interface GetPracticeTurnsCount {
    from: string;
    to: string;
    filter?: GetPracticeTurnsCountFilter;
}

export interface AnalyticsQueryDto {
    usersCount?: GetUsersCount;
    masteredUserVocabsCount?: GetUserVocabsCountDto;
    userVocabsCount?: GetUserVocabsCountDto;
    practiceTurnsCount?: GetPracticeTurnsCount;
    usersCountWhoDidPractice?: GetUsersCount;
    usersCountWhoConnectedTGBot?: GetUsersCount;
    usersCountWhoConnectedWABot?: GetUsersCount;
}

export interface AnalyticsCountData {
    count: number;
}

export interface AnalyticsSuccessResponseDto {
    success: boolean;
    result: AnalyticsCountData;
}

export type AnalyticsErrorResponseDtoError = {};

export interface AnalyticsErrorResponseDto {
    success: boolean;
    error: AnalyticsErrorResponseDtoError;
}

export type GetUserEmailsParams = { token: string };

export type GetVocabsParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
    shuffle?: boolean;
};

export type GetVocabsFromPonsParams = { query: string; language?: string };

export type GetOnboardingVocabsParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
    shuffle?: boolean;
};

export type GetPublicVocabsParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
    shuffle?: boolean;
};

export type GetExamplesParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
};

export type GetExampleParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
};

export type GetUserVocabsParams = {
    hasTotal?: string;
    skip?: number;
    limit?: number;
    sort?: string;
    needPopulate?: boolean;
    populates?: string[];
    necessaryProjectionArray?: string[];
};

export type GetAllUserVocabsParams = {
    hasTotal?: string;
    skip?: number;
    limit?: number;
    sort?: string;
    needPopulate?: boolean;
    populates?: string[];
    necessaryProjectionArray?: string[];
};

export type GetChallengesParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
};

export type LookupDefinitionParams = {
    literal: string;
    language: string;
    definitionLanguage?: string;
};

export type GetUserNotificationsParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
};

export type GetUserVocabSuggestionsParams = {
    query: string;
    sourceLanguage: string;
    targetLanguage?: string;
};

export type GetLatestSignalParams = { is_admin: number };

export type CreateSignalParams = { is_admin: number };

export type GetDecksParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
};

export type GetUserDecksParams = {
    query?: string;
    skip?: number;
    limit?: number;
    cursor?: string;
};

export type SearchUserDeckParams = { query?: string };

import { xhrServiceInstance } from '../services/xhr/index';

type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
    ...args: any
) => Promise<infer R>
    ? R
    : any;

/**
 * @summary Fetches all users in CSV format
 */
export const getUsersAsCsv = () => {
    return xhrServiceInstance<string>({
        url: `/api/v2/users/csv`,
        method: 'get',
    });
};

export const getGetUsersAsCsvQueryKey = () => [`/api/v2/users/csv`];

export const useGetUsersAsCsv = <
    TData = AsyncReturnType<typeof getUsersAsCsv>,
    TError = UserErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getUsersAsCsv>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetUsersAsCsvQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getUsersAsCsv>> = () =>
        getUsersAsCsv();

    const query = useQuery<
        AsyncReturnType<typeof getUsersAsCsv>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches all users, optionally filtered by passing a query object
 */
export const getUsers = () => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users`,
        method: 'get',
    });
};

export const getGetUsersQueryKey = () => [`/api/v2/users`];

export const useGetUsers = <
    TData = AsyncReturnType<typeof getUsers>,
    TError = UserErrorResponseDto
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getUsers>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getUsers>> = () =>
        getUsers();

    const query = useQuery<AsyncReturnType<typeof getUsers>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Creates a new user
 */
export const createUser = (createUserDto: CreateUserDto) => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users`,
        method: 'post',
        data: createUserDto,
    });
};

export const useCreateUser = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createUser>,
        TError,
        { data: CreateUserDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createUser>,
        { data: CreateUserDto }
    > = props => {
        const { data } = props || {};

        return createUser(data);
    };

    return useMutation<
        AsyncReturnType<typeof createUser>,
        TError,
        { data: CreateUserDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Checks user's existence by `email`
 */
export const checkUserExistence = (email: string) => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users/exists/${email}`,
        method: 'get',
    });
};

export const getCheckUserExistenceQueryKey = (email: string) => [
    `/api/v2/users/exists/${email}`,
];

export const useCheckUserExistence = <
    TData = AsyncReturnType<typeof checkUserExistence>,
    TError = UserErrorResponseDto
>(
    email: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof checkUserExistence>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getCheckUserExistenceQueryKey(email);
    const queryFn: QueryFunction<AsyncReturnType<typeof checkUserExistence>> =
        () => checkUserExistence(email);

    const query = useQuery<
        AsyncReturnType<typeof checkUserExistence>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!email, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches a specific user by their `id`
 */
export const getUserById = (id: string) => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users/${id}`,
        method: 'get',
    });
};

export const getGetUserByIdQueryKey = (id: string) => [`/api/v2/users/${id}`];

export const useGetUserById = <
    TData = AsyncReturnType<typeof getUserById>,
    TError = UserErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserById>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserById>> = () =>
        getUserById(id);

    const query = useQuery<AsyncReturnType<typeof getUserById>, TError, TData>(
        queryKey,
        queryFn,
        { enabled: !!id, ...queryOptions }
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Patches individual fields of a user
 */
export const updateUserById = (id: string, updateUserDto: UpdateUserDto) => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users/${id}`,
        method: 'patch',
        data: updateUserDto,
    });
};

export const useUpdateUserById = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateUserById>,
        TError,
        { id: string; data: UpdateUserDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateUserById>,
        { id: string; data: UpdateUserDto }
    > = props => {
        const { id, data } = props || {};

        return updateUserById(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateUserById>,
        TError,
        { id: string; data: UpdateUserDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Deletes a user and all of their entities (user-vocabs, user-challenges, …). Proceed with caution ⚠️
 */
export const deleteUserById = (id: string) => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users/${id}`,
        method: 'delete',
    });
};

export const useDeleteUserById = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof deleteUserById>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof deleteUserById>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return deleteUserById(id);
    };

    return useMutation<
        AsyncReturnType<typeof deleteUserById>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches a specific user public profile by their `id`
 */
export const getUserPublicProfile = (id: string) => {
    return xhrServiceInstance<UserPublicProfileSuccessResponseDto>({
        url: `/api/v2/users/${id}/public-profile`,
        method: 'get',
    });
};

export const getGetUserPublicProfileQueryKey = (id: string) => [
    `/api/v2/users/${id}/public-profile`,
];

export const useGetUserPublicProfile = <
    TData = AsyncReturnType<typeof getUserPublicProfile>,
    TError = UserErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserPublicProfile>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetUserPublicProfileQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserPublicProfile>> =
        () => getUserPublicProfile(id);

    const query = useQuery<
        AsyncReturnType<typeof getUserPublicProfile>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Stores a user interaction in the user's history
 */
export const addActionToUser = (id: string, userActionDto: UserActionDto) => {
    return xhrServiceInstance<void>({
        url: `/api/v2/users/${id}/actions`,
        method: 'post',
        data: userActionDto,
    });
};

export const useAddActionToUser = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof addActionToUser>,
        TError,
        { id: string; data: UserActionDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof addActionToUser>,
        { id: string; data: UserActionDto }
    > = props => {
        const { id, data } = props || {};

        return addActionToUser(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof addActionToUser>,
        TError,
        { id: string; data: UserActionDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Adds a telegram message to the user
 */
export const addUserTelegramMessage = (
    createTelegramMessageDto: CreateTelegramMessageDto
) => {
    return xhrServiceInstance<void>({
        url: `/api/v2/users/add-tg-message`,
        method: 'post',
        data: createTelegramMessageDto,
    });
};

export const useAddUserTelegramMessage = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof addUserTelegramMessage>,
        TError,
        { data: CreateTelegramMessageDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof addUserTelegramMessage>,
        { data: CreateTelegramMessageDto }
    > = props => {
        const { data } = props || {};

        return addUserTelegramMessage(data);
    };

    return useMutation<
        AsyncReturnType<typeof addUserTelegramMessage>,
        TError,
        { data: CreateTelegramMessageDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Generates a demo user
 */
export const generateDemoUser = () => {
    return xhrServiceInstance<AuthUserSuccessResponseDto>({
        url: `/api/v2/users/generate-demo-user`,
        method: 'post',
        data: undefined,
    });
};

export const useGenerateDemoUser = <
    TError = UserErrorResponseDto,
    TVariables = void,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof generateDemoUser>,
        TError,
        TVariables,
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof generateDemoUser>,
        TVariables
    > = () => {
        return generateDemoUser();
    };

    return useMutation<
        AsyncReturnType<typeof generateDemoUser>,
        TError,
        TVariables,
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Deletes a all of entities (user-vocabs, user-challenges, …) of a user by email. Proceed with caution ⚠️
 */
export const clearUserHistory = (email: string) => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users/clear-user-history/${email}`,
        method: 'delete',
    });
};

export const useClearUserHistory = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof clearUserHistory>,
        TError,
        { email: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof clearUserHistory>,
        { email: string }
    > = props => {
        const { email } = props || {};

        return clearUserHistory(email);
    };

    return useMutation<
        AsyncReturnType<typeof clearUserHistory>,
        TError,
        { email: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Deletes a user and all of their entities (user-vocabs, user-challenges, …) by email. Proceed with caution ⚠️
 */
export const deleteUserByEmail = (email: string) => {
    return xhrServiceInstance<UserSuccessResponseDto>({
        url: `/api/v2/users/delete-by-email/${email}`,
        method: 'delete',
    });
};

export const useDeleteUserByEmail = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof deleteUserByEmail>,
        TError,
        { email: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof deleteUserByEmail>,
        { email: string }
    > = props => {
        const { email } = props || {};

        return deleteUserByEmail(email);
    };

    return useMutation<
        AsyncReturnType<typeof deleteUserByEmail>,
        TError,
        { email: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches emails of a specific user
 */
export const getUserEmails = (email: string, params?: GetUserEmailsParams) => {
    return xhrServiceInstance<UserEmailsSuccessResponseDto>({
        url: `/api/v2/users/emails/${email}`,
        method: 'get',
        params,
    });
};

export const getGetUserEmailsQueryKey = (
    email: string,
    params?: GetUserEmailsParams
) => [`/api/v2/users/emails/${email}`, ...(params ? [params] : [])];

export const useGetUserEmails = <
    TData = AsyncReturnType<typeof getUserEmails>,
    TError = UserErrorResponseDto
>(
    email: string,
    params?: GetUserEmailsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserEmails>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetUserEmailsQueryKey(email, params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserEmails>> = () =>
        getUserEmails(email, params);

    const query = useQuery<
        AsyncReturnType<typeof getUserEmails>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!email, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

export const uploadUserAudio = (uploadUserAudioDto: UploadUserAudioDto) => {
    const formData = new FormData();
    formData.append('audio', uploadUserAudioDto.audio);

    return xhrServiceInstance<UserFileUploadSuccessResponseDto>({
        url: `/api/v2/users/upload/audio`,
        method: 'post',
        data: formData,
    });
};

export const useUploadUserAudio = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof uploadUserAudio>,
        TError,
        { data: UploadUserAudioDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof uploadUserAudio>,
        { data: UploadUserAudioDto }
    > = props => {
        const { data } = props || {};

        return uploadUserAudio(data);
    };

    return useMutation<
        AsyncReturnType<typeof uploadUserAudio>,
        TError,
        { data: UploadUserAudioDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Creates a new user
 */
export const signUpUser = (createUserDto: CreateUserDto) => {
    return xhrServiceInstance<AuthUserSuccessResponseDto>({
        url: `/api/v2/users/auth/signup`,
        method: 'post',
        data: createUserDto,
    });
};

export const useSignUpUser = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof signUpUser>,
        TError,
        { data: CreateUserDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof signUpUser>,
        { data: CreateUserDto }
    > = props => {
        const { data } = props || {};

        return signUpUser(data);
    };

    return useMutation<
        AsyncReturnType<typeof signUpUser>,
        TError,
        { data: CreateUserDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Signs an existing user back in and returns them together with an access token
 */
export const signInUser = (signInDto: SignInDto) => {
    return xhrServiceInstance<AuthUserSuccessResponseDto>({
        url: `/api/v2/users/auth/signin`,
        method: 'post',
        data: signInDto,
    });
};

export const useSignInUser = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof signInUser>,
        TError,
        { data: SignInDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof signInUser>,
        { data: SignInDto }
    > = props => {
        const { data } = props || {};

        return signInUser(data);
    };

    return useMutation<
        AsyncReturnType<typeof signInUser>,
        TError,
        { data: SignInDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Signs an existing user back in and returns them together with an access token
 */
export const impersonateUser = (impersonateDto: ImpersonateDto) => {
    return xhrServiceInstance<AuthUserSuccessResponseDto>({
        url: `/api/v2/users/admin/impersonate`,
        method: 'post',
        data: impersonateDto,
    });
};

export const useImpersonateUser = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof impersonateUser>,
        TError,
        { data: ImpersonateDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof impersonateUser>,
        { data: ImpersonateDto }
    > = props => {
        const { data } = props || {};

        return impersonateUser(data);
    };

    return useMutation<
        AsyncReturnType<typeof impersonateUser>,
        TError,
        { data: ImpersonateDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Sends a password reset link to the user's email address
 */
export const sendResetPasswordLink = (
    sendResetPasswordLinkDto: SendResetPasswordLinkDto
) => {
    return xhrServiceInstance<SentResetPasswordLinkSuccessResponseDto>({
        url: `/api/v2/users/auth/send-reset-password-link`,
        method: 'post',
        data: sendResetPasswordLinkDto,
    });
};

export const useSendResetPasswordLink = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof sendResetPasswordLink>,
        TError,
        { data: SendResetPasswordLinkDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof sendResetPasswordLink>,
        { data: SendResetPasswordLinkDto }
    > = props => {
        const { data } = props || {};

        return sendResetPasswordLink(data);
    };

    return useMutation<
        AsyncReturnType<typeof sendResetPasswordLink>,
        TError,
        { data: SendResetPasswordLinkDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Resets the user password
 */
export const resetPassword = (resetPasswordDto: ResetPasswordDto) => {
    return xhrServiceInstance<AuthUserSuccessResponseDto>({
        url: `/api/v2/users/auth/reset-password`,
        method: 'post',
        data: resetPasswordDto,
    });
};

export const useResetPassword = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof resetPassword>,
        TError,
        { data: ResetPasswordDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof resetPassword>,
        { data: ResetPasswordDto }
    > = props => {
        const { data } = props || {};

        return resetPassword(data);
    };

    return useMutation<
        AsyncReturnType<typeof resetPassword>,
        TError,
        { data: ResetPasswordDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Creates a new user and sends them a passwordless login-link
 */
export const signInUserPasswordless = (
    passwordlessSignInDto: PasswordlessSignInDto
) => {
    return xhrServiceInstance<void>({
        url: `/api/v2/users/auth/signin/send-link`,
        method: 'post',
        data: passwordlessSignInDto,
    });
};

export const useSignInUserPasswordless = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof signInUserPasswordless>,
        TError,
        { data: PasswordlessSignInDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof signInUserPasswordless>,
        { data: PasswordlessSignInDto }
    > = props => {
        const { data } = props || {};

        return signInUserPasswordless(data);
    };

    return useMutation<
        AsyncReturnType<typeof signInUserPasswordless>,
        TError,
        { data: PasswordlessSignInDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Verifies a user's access token
 */
export const verifyUserToken = (verifyUserTokenDto: VerifyUserTokenDto) => {
    return xhrServiceInstance<AuthUserSuccessResponseDto>({
        url: `/api/v2/users/auth/verify`,
        method: 'post',
        data: verifyUserTokenDto,
    });
};

export const useVerifyUserToken = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof verifyUserToken>,
        TError,
        { data: VerifyUserTokenDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof verifyUserToken>,
        { data: VerifyUserTokenDto }
    > = props => {
        const { data } = props || {};

        return verifyUserToken(data);
    };

    return useMutation<
        AsyncReturnType<typeof verifyUserToken>,
        TError,
        { data: VerifyUserTokenDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches the currently authed user
 */
export const getMyAcount = () => {
    return xhrServiceInstance<AuthUserSuccessResponseDto>({
        url: `/api/v2/users/auth/my-account`,
        method: 'get',
    });
};

export const getGetMyAcountQueryKey = () => [`/api/v2/users/auth/my-account`];

export const useGetMyAcount = <
    TData = AsyncReturnType<typeof getMyAcount>,
    TError = UserErrorResponseDto
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getMyAcount>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetMyAcountQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getMyAcount>> = () =>
        getMyAcount();

    const query = useQuery<AsyncReturnType<typeof getMyAcount>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Connects a user to their Telegram account
 */
export const connectTelegram = (telegramConnection: TelegramConnection) => {
    return xhrServiceInstance<SingleUserSuccessResponseDto>({
        url: `/api/v2/users/auth/connect/telegram`,
        method: 'post',
        data: telegramConnection,
    });
};

export const useConnectTelegram = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof connectTelegram>,
        TError,
        { data: TelegramConnection },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof connectTelegram>,
        { data: TelegramConnection }
    > = props => {
        const { data } = props || {};

        return connectTelegram(data);
    };

    return useMutation<
        AsyncReturnType<typeof connectTelegram>,
        TError,
        { data: TelegramConnection },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Connects a user to their Whatsapp account
 */
export const connectWhatsapp = (whatsappConnection: WhatsappConnection) => {
    return xhrServiceInstance<SingleUserSuccessResponseDto>({
        url: `/api/v2/users/auth/connect/whatsapp`,
        method: 'post',
        data: whatsappConnection,
    });
};

export const useConnectWhatsapp = <
    TError = UserErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof connectWhatsapp>,
        TError,
        { data: WhatsappConnection },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof connectWhatsapp>,
        { data: WhatsappConnection }
    > = props => {
        const { data } = props || {};

        return connectWhatsapp(data);
    };

    return useMutation<
        AsyncReturnType<typeof connectWhatsapp>,
        TError,
        { data: WhatsappConnection },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Takes user input to complete (practice) a memory discovery exercise
 */
export const practiceUserChallengeTaskMemory = (
    practiceUserMemoryChallengeTaskDto: PracticeUserMemoryChallengeTaskDto
) => {
    return xhrServiceInstance<PracticeSuccessResponseDto>({
        url: `/api/v2/user-challenges/practice/memory`,
        method: 'post',
        data: practiceUserMemoryChallengeTaskDto,
    });
};

export const usePracticeUserChallengeTaskMemory = <
    TError = UserChallengeErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof practiceUserChallengeTaskMemory>,
        TError,
        { data: PracticeUserMemoryChallengeTaskDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof practiceUserChallengeTaskMemory>,
        { data: PracticeUserMemoryChallengeTaskDto }
    > = props => {
        const { data } = props || {};

        return practiceUserChallengeTaskMemory(data);
    };

    return useMutation<
        AsyncReturnType<typeof practiceUserChallengeTaskMemory>,
        TError,
        { data: PracticeUserMemoryChallengeTaskDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches vocabs optionally filtered by a query
 */
export const getVocabs = (params?: GetVocabsParams) => {
    return xhrServiceInstance<VocabSuccessResponseDto>({
        url: `/api/v2/vocabs`,
        method: 'get',
        params,
    });
};

export const getGetVocabsQueryKey = (params?: GetVocabsParams) => [
    `/api/v2/vocabs`,
    ...(params ? [params] : []),
];

export const useGetVocabs = <
    TData = AsyncReturnType<typeof getVocabs>,
    TError = VocabErrorResponseDto
>(
    params?: GetVocabsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getVocabs>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetVocabsQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getVocabs>> = () =>
        getVocabs(params);

    const query = useQuery<AsyncReturnType<typeof getVocabs>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Creates a new vocab
 */
export const createVocab = (createVocabDto: CreateVocabDto) => {
    const formData = new FormData();
    formData.append('literal', createVocabDto.literal);
    if (createVocabDto.emoji !== undefined) {
        formData.append('emoji', createVocabDto.emoji);
    }
    if (createVocabDto.key !== undefined) {
        formData.append('key', createVocabDto.key);
    }
    if (createVocabDto.status !== undefined) {
        formData.append('status', createVocabDto.status);
    }
    if (createVocabDto.definition !== undefined) {
        formData.append(
            'definition',
            JSON.stringify(createVocabDto.definition)
        );
    }
    if (createVocabDto.explanation !== undefined) {
        formData.append('explanation', createVocabDto.explanation);
    }
    formData.append('language', createVocabDto.language);
    formData.append('example', createVocabDto.example);
    if (createVocabDto.tts_text !== undefined) {
        formData.append('tts_text', createVocabDto.tts_text);
    }
    if (createVocabDto.taxonomies !== undefined) {
        formData.append(
            'taxonomies',
            JSON.stringify(createVocabDto.taxonomies)
        );
    }
    if (createVocabDto.required_words !== undefined) {
        formData.append(
            'required_words',
            JSON.stringify(createVocabDto.required_words)
        );
    }
    if (createVocabDto.related_vocabs !== undefined) {
        formData.append(
            'related_vocabs',
            JSON.stringify(createVocabDto.related_vocabs)
        );
    }
    if (createVocabDto.transcription_overrides !== undefined) {
        formData.append(
            'transcription_overrides',
            JSON.stringify(createVocabDto.transcription_overrides)
        );
    }
    if (createVocabDto.translation !== undefined) {
        formData.append('translation', createVocabDto.translation);
    }
    if (createVocabDto.image !== undefined) {
        formData.append('image', createVocabDto.image);
    }
    if (createVocabDto.times_used !== undefined) {
        formData.append('times_used', createVocabDto.times_used.toString());
    }
    if (createVocabDto.baseform !== undefined) {
        formData.append('baseform', createVocabDto.baseform);
    }
    if (createVocabDto.sense !== undefined) {
        formData.append('sense', createVocabDto.sense);
    }
    if (createVocabDto.wordclass !== undefined) {
        formData.append('wordclass', createVocabDto.wordclass);
    }
    if (createVocabDto.syllables !== undefined) {
        formData.append('syllables', JSON.stringify(createVocabDto.syllables));
    }
    if (createVocabDto.ipa !== undefined) {
        formData.append('ipa', createVocabDto.ipa);
    }
    if (createVocabDto.topic !== undefined) {
        formData.append('topic', createVocabDto.topic);
    }
    if (createVocabDto.idioms !== undefined) {
        formData.append('idioms', JSON.stringify(createVocabDto.idioms));
    }

    return xhrServiceInstance<SingleVocabSuccessResponseDto>({
        url: `/api/v2/vocabs`,
        method: 'post',
        data: formData,
    });
};

export const useCreateVocab = <
    TError = VocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createVocab>,
        TError,
        { data: CreateVocabDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createVocab>,
        { data: CreateVocabDto }
    > = props => {
        const { data } = props || {};

        return createVocab(data);
    };

    return useMutation<
        AsyncReturnType<typeof createVocab>,
        TError,
        { data: CreateVocabDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches vocabs from DB or (as fallback) from PONS API
 */
export const getVocabsFromPons = (
    language: string,
    params?: GetVocabsFromPonsParams
) => {
    return xhrServiceInstance<VocabSuccessResponseDto>({
        url: `/api/v2/vocabs/query/${language}`,
        method: 'get',
        params,
    });
};

export const getGetVocabsFromPonsQueryKey = (
    language: string,
    params?: GetVocabsFromPonsParams
) => [`/api/v2/vocabs/query/${language}`, ...(params ? [params] : [])];

export const useGetVocabsFromPons = <
    TData = AsyncReturnType<typeof getVocabsFromPons>,
    TError = VocabErrorResponseDto
>(
    language: string,
    params?: GetVocabsFromPonsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getVocabsFromPons>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetVocabsFromPonsQueryKey(language, params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getVocabsFromPons>> =
        () => getVocabsFromPons(language, params);

    const query = useQuery<
        AsyncReturnType<typeof getVocabsFromPons>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!language, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches onboarding vocabs by language
 */
export const getOnboardingVocabs = (
    language: string,
    params?: GetOnboardingVocabsParams
) => {
    return xhrServiceInstance<VocabSuccessResponseDto>({
        url: `/api/v2/vocabs/onboarding/${language}`,
        method: 'get',
        params,
    });
};

export const getGetOnboardingVocabsQueryKey = (
    language: string,
    params?: GetOnboardingVocabsParams
) => [`/api/v2/vocabs/onboarding/${language}`, ...(params ? [params] : [])];

export const useGetOnboardingVocabs = <
    TData = AsyncReturnType<typeof getOnboardingVocabs>,
    TError = VocabErrorResponseDto
>(
    language: string,
    params?: GetOnboardingVocabsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getOnboardingVocabs>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetOnboardingVocabsQueryKey(language, params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getOnboardingVocabs>> =
        () => getOnboardingVocabs(language, params);

    const query = useQuery<
        AsyncReturnType<typeof getOnboardingVocabs>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!language, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches vocabs optionally filtered by a query for public access
 */
export const getPublicVocabs = (params?: GetPublicVocabsParams) => {
    return xhrServiceInstance<AdminPanelVocabSuccessResponseDto>({
        url: `/api/v2/vocabs/public/vocabs`,
        method: 'get',
        params,
    });
};

export const getGetPublicVocabsQueryKey = (params?: GetPublicVocabsParams) => [
    `/api/v2/vocabs/public/vocabs`,
    ...(params ? [params] : []),
];

export const useGetPublicVocabs = <
    TData = AsyncReturnType<typeof getPublicVocabs>,
    TError = VocabErrorResponseDto
>(
    params?: GetPublicVocabsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getPublicVocabs>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetPublicVocabsQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getPublicVocabs>> =
        () => getPublicVocabs(params);

    const query = useQuery<
        AsyncReturnType<typeof getPublicVocabs>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches a speficic vocab by its `id`
 */
export const getVocabById = (id: string) => {
    return xhrServiceInstance<SingleVocabSuccessResponseDto>({
        url: `/api/v2/vocabs/${id}`,
        method: 'get',
    });
};

export const getGetVocabByIdQueryKey = (id: string) => [`/api/v2/vocabs/${id}`];

export const useGetVocabById = <
    TData = AsyncReturnType<typeof getVocabById>,
    TError = VocabErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getVocabById>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetVocabByIdQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getVocabById>> = () =>
        getVocabById(id);

    const query = useQuery<AsyncReturnType<typeof getVocabById>, TError, TData>(
        queryKey,
        queryFn,
        { enabled: !!id, ...queryOptions }
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Updates individual fields of a vocab
 */
export const updateVocabById = (id: string, updateVocabDto: UpdateVocabDto) => {
    const formData = new FormData();
    if (updateVocabDto.literal !== undefined) {
        formData.append('literal', updateVocabDto.literal);
    }
    if (updateVocabDto.key !== undefined) {
        formData.append('key', updateVocabDto.key);
    }
    if (updateVocabDto.emoji !== undefined) {
        formData.append('emoji', updateVocabDto.emoji);
    }
    if (updateVocabDto.status !== undefined) {
        formData.append('status', updateVocabDto.status);
    }
    if (updateVocabDto.definition !== undefined) {
        formData.append(
            'definition',
            JSON.stringify(updateVocabDto.definition)
        );
    }
    if (updateVocabDto.explanation !== undefined) {
        formData.append('explanation', updateVocabDto.explanation);
    }
    if (updateVocabDto.language !== undefined) {
        formData.append('language', updateVocabDto.language);
    }
    if (updateVocabDto.example !== undefined) {
        formData.append('example', updateVocabDto.example);
    }
    if (updateVocabDto.tts_text !== undefined) {
        formData.append('tts_text', updateVocabDto.tts_text);
    }
    if (updateVocabDto.cefr_level !== undefined) {
        formData.append('cefr_level', updateVocabDto.cefr_level.toString());
    }
    if (updateVocabDto.required_words !== undefined) {
        formData.append(
            'required_words',
            JSON.stringify(updateVocabDto.required_words)
        );
    }
    if (updateVocabDto.related_vocabs !== undefined) {
        formData.append(
            'related_vocabs',
            JSON.stringify(updateVocabDto.related_vocabs)
        );
    }
    if (updateVocabDto.linguistic !== undefined) {
        formData.append(
            'linguistic',
            JSON.stringify(updateVocabDto.linguistic)
        );
    }
    if (updateVocabDto.linguistics !== undefined) {
        formData.append(
            'linguistics',
            JSON.stringify(updateVocabDto.linguistics)
        );
    }
    if (updateVocabDto.translation !== undefined) {
        formData.append('translation', updateVocabDto.translation);
    }
    if (updateVocabDto.transcription_overrides !== undefined) {
        formData.append(
            'transcription_overrides',
            JSON.stringify(updateVocabDto.transcription_overrides)
        );
    }
    if (updateVocabDto.taxonomies !== undefined) {
        formData.append(
            'taxonomies',
            JSON.stringify(updateVocabDto.taxonomies)
        );
    }
    if (updateVocabDto.image !== undefined) {
        formData.append('image', updateVocabDto.image);
    }
    if (updateVocabDto.baseform !== undefined) {
        formData.append('baseform', updateVocabDto.baseform);
    }
    if (updateVocabDto.sense !== undefined) {
        formData.append('sense', updateVocabDto.sense);
    }
    if (updateVocabDto.wordclass !== undefined) {
        formData.append('wordclass', updateVocabDto.wordclass);
    }
    if (updateVocabDto.syllables !== undefined) {
        formData.append('syllables', JSON.stringify(updateVocabDto.syllables));
    }
    if (updateVocabDto.ipa !== undefined) {
        formData.append('ipa', updateVocabDto.ipa);
    }
    if (updateVocabDto.topic !== undefined) {
        formData.append('topic', updateVocabDto.topic);
    }
    if (updateVocabDto.idioms !== undefined) {
        formData.append('idioms', JSON.stringify(updateVocabDto.idioms));
    }

    return xhrServiceInstance<SingleVocabSuccessResponseDto>({
        url: `/api/v2/vocabs/${id}`,
        method: 'patch',
        data: formData,
    });
};

export const useUpdateVocabById = <
    TError = VocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateVocabById>,
        TError,
        { id: string; data: UpdateVocabDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateVocabById>,
        { id: string; data: UpdateVocabDto }
    > = props => {
        const { id, data } = props || {};

        return updateVocabById(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateVocabById>,
        TError,
        { id: string; data: UpdateVocabDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary [admin route] Deletes an individual vocab
 */
export const deleteVocabById = (id: string) => {
    return xhrServiceInstance<VocabSuccessResponseDto>({
        url: `/api/v2/vocabs/${id}`,
        method: 'delete',
    });
};

export const useDeleteVocabById = <
    TError = VocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof deleteVocabById>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof deleteVocabById>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return deleteVocabById(id);
    };

    return useMutation<
        AsyncReturnType<typeof deleteVocabById>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary [admin route] Imports vocabs from a csv file
 */
export const importVocabsByCsv = (
    importVocabFromCSVDto: ImportVocabFromCSVDto
) => {
    return xhrServiceInstance<VocabSuccessResponseDto>({
        url: `/api/v2/vocabs/import-from-csv`,
        method: 'post',
        data: importVocabFromCSVDto,
    });
};

export const useImportVocabsByCsv = <
    TError = VocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof importVocabsByCsv>,
        TError,
        { data: ImportVocabFromCSVDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof importVocabsByCsv>,
        { data: ImportVocabFromCSVDto }
    > = props => {
        const { data } = props || {};

        return importVocabsByCsv(data);
    };

    return useMutation<
        AsyncReturnType<typeof importVocabsByCsv>,
        TError,
        { data: ImportVocabFromCSVDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Translates a literal to another language
 */
export const translateLiteral = (translateLiteralDto: TranslateLiteralDto) => {
    return xhrServiceInstance<TranslationSuccessResponseDto>({
        url: `/api/v2/vocabs/translate`,
        method: 'post',
        data: translateLiteralDto,
    });
};

export const useTranslateLiteral = <
    TError = VocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof translateLiteral>,
        TError,
        { data: TranslateLiteralDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof translateLiteral>,
        { data: TranslateLiteralDto }
    > = props => {
        const { data } = props || {};

        return translateLiteral(data);
    };

    return useMutation<
        AsyncReturnType<typeof translateLiteral>,
        TError,
        { data: TranslateLiteralDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches all words, optionally filtered by a query
 */
export const getWords = () => {
    return xhrServiceInstance<WordSuccessResponseDto>({
        url: `/api/v2/words`,
        method: 'get',
    });
};

export const getGetWordsQueryKey = () => [`/api/v2/words`];

export const useGetWords = <
    TData = AsyncReturnType<typeof getWords>,
    TError = WordErrorResponseDto
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getWords>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetWordsQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getWords>> = () =>
        getWords();

    const query = useQuery<AsyncReturnType<typeof getWords>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Updates individual fields of a vocab
 */
export const updateWordById = (id: string, updateWordDto: UpdateWordDto) => {
    return xhrServiceInstance<WordSuccessResponseDto>({
        url: `/api/v2/words/${id}`,
        method: 'patch',
        data: updateWordDto,
    });
};

export const useUpdateWordById = <
    TError = WordErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateWordById>,
        TError,
        { id: string; data: UpdateWordDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateWordById>,
        { id: string; data: UpdateWordDto }
    > = props => {
        const { id, data } = props || {};

        return updateWordById(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateWordById>,
        TError,
        { id: string; data: UpdateWordDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches examples optionally filtered by a query
 */
export const getExamples = (params?: GetExamplesParams) => {
    return xhrServiceInstance<ExamplesSuccessResponseDto>({
        url: `/api/v2/examples`,
        method: 'get',
        params,
    });
};

export const getGetExamplesQueryKey = (params?: GetExamplesParams) => [
    `/api/v2/examples`,
    ...(params ? [params] : []),
];

export const useGetExamples = <
    TData = AsyncReturnType<typeof getExamples>,
    TError = ExampleErrorResponseDto
>(
    params?: GetExamplesParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getExamples>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetExamplesQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getExamples>> = () =>
        getExamples(params);

    const query = useQuery<AsyncReturnType<typeof getExamples>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Creates a new example
 */
export const createExample = (createExampleDto: CreateExampleDto) => {
    return xhrServiceInstance<SingleExampleSuccessResponseDto>({
        url: `/api/v2/examples`,
        method: 'post',
        data: createExampleDto,
    });
};

export const useCreateExample = <
    TError = ExampleErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createExample>,
        TError,
        { data: CreateExampleDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createExample>,
        { data: CreateExampleDto }
    > = props => {
        const { data } = props || {};

        return createExample(data);
    };

    return useMutation<
        AsyncReturnType<typeof createExample>,
        TError,
        { data: CreateExampleDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches an individual example given a query
 */
export const getExample = (params?: GetExampleParams) => {
    return xhrServiceInstance<SingleExampleSuccessResponseDto>({
        url: `/api/v2/examples/sample`,
        method: 'get',
        params,
    });
};

export const getGetExampleQueryKey = (params?: GetExampleParams) => [
    `/api/v2/examples/sample`,
    ...(params ? [params] : []),
];

export const useGetExample = <
    TData = AsyncReturnType<typeof getExample>,
    TError = ExampleErrorResponseDto
>(
    params?: GetExampleParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getExample>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetExampleQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getExample>> = () =>
        getExample(params);

    const query = useQuery<AsyncReturnType<typeof getExample>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Fetches a speficic example by its `id`
 */
export const getExampleById = (id: string) => {
    return xhrServiceInstance<SingleExampleSuccessResponseDto>({
        url: `/api/v2/examples/${id}`,
        method: 'get',
    });
};

export const getGetExampleByIdQueryKey = (id: string) => [
    `/api/v2/examples/${id}`,
];

export const useGetExampleById = <
    TData = AsyncReturnType<typeof getExampleById>,
    TError = ExampleErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getExampleById>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetExampleByIdQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getExampleById>> = () =>
        getExampleById(id);

    const query = useQuery<
        AsyncReturnType<typeof getExampleById>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Updates individual fields of an example
 */
export const updateExampleById = (
    id: string,
    updateExampleDto: UpdateExampleDto
) => {
    return xhrServiceInstance<SingleExampleSuccessResponseDto>({
        url: `/api/v2/examples/${id}`,
        method: 'patch',
        data: updateExampleDto,
    });
};

export const useUpdateExampleById = <
    TError = ExampleErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateExampleById>,
        TError,
        { id: string; data: UpdateExampleDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateExampleById>,
        { id: string; data: UpdateExampleDto }
    > = props => {
        const { id, data } = props || {};

        return updateExampleById(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateExampleById>,
        TError,
        { id: string; data: UpdateExampleDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary [admin route] Deletes an individual example
 */
export const deleteExampleById = (id: string) => {
    return xhrServiceInstance<SingleExampleSuccessResponseDto>({
        url: `/api/v2/examples/${id}`,
        method: 'delete',
    });
};

export const useDeleteExampleById = <
    TError = ExampleErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof deleteExampleById>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof deleteExampleById>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return deleteExampleById(id);
    };

    return useMutation<
        AsyncReturnType<typeof deleteExampleById>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Parses a text and returns its linguistic tokens
 */
export const parseText = (parseExampleDto: ParseExampleDto) => {
    return xhrServiceInstance<ParsedExampleSuccessResponseDto>({
        url: `/api/v2/examples/parse`,
        method: 'post',
        data: parseExampleDto,
    });
};

export const useParseText = <
    TError = ExampleErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof parseText>,
        TError,
        { data: ParseExampleDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof parseText>,
        { data: ParseExampleDto }
    > = props => {
        const { data } = props || {};

        return parseText(data);
    };

    return useMutation<
        AsyncReturnType<typeof parseText>,
        TError,
        { data: ParseExampleDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches all vocabs of a learner
 */
export const getUserVocabs = (params?: GetUserVocabsParams) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs`,
        method: 'get',
        params,
    });
};

export const getGetUserVocabsQueryKey = (params?: GetUserVocabsParams) => [
    `/api/v2/user-vocabs`,
    ...(params ? [params] : []),
];

export const useGetUserVocabs = <
    TData = AsyncReturnType<typeof getUserVocabs>,
    TError = UserVocabErrorResponseDto
>(
    params?: GetUserVocabsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserVocabs>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetUserVocabsQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserVocabs>> = () =>
        getUserVocabs(params);

    const query = useQuery<
        AsyncReturnType<typeof getUserVocabs>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Adds a vocab to a learner's vocab list
 */
export const createUserVocab = (createUserVocabDto: CreateUserVocabDto) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs`,
        method: 'post',
        data: createUserVocabDto,
    });
};

export const useCreateUserVocab = <
    TError = UserVocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createUserVocab>,
        TError,
        { data: CreateUserVocabDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createUserVocab>,
        { data: CreateUserVocabDto }
    > = props => {
        const { data } = props || {};

        return createUserVocab(data);
    };

    return useMutation<
        AsyncReturnType<typeof createUserVocab>,
        TError,
        { data: CreateUserVocabDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches all user vocabs
 */
export const getAllUserVocabs = (params?: GetAllUserVocabsParams) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/all`,
        method: 'get',
        params,
    });
};

export const getGetAllUserVocabsQueryKey = (
    params?: GetAllUserVocabsParams
) => [`/api/v2/user-vocabs/all`, ...(params ? [params] : [])];

export const useGetAllUserVocabs = <
    TData = AsyncReturnType<typeof getAllUserVocabs>,
    TError = UserVocabErrorResponseDto
>(
    params?: GetAllUserVocabsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getAllUserVocabs>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetAllUserVocabsQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getAllUserVocabs>> =
        () => getAllUserVocabs(params);

    const query = useQuery<
        AsyncReturnType<typeof getAllUserVocabs>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches all user vocabs of a specific learner given user_id
 */
export const getUserVocabsByUserId = (userid: string) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/${userid}/all`,
        method: 'get',
    });
};

export const getGetUserVocabsByUserIdQueryKey = (userid: string) => [
    `/api/v2/user-vocabs/${userid}/all`,
];

export const useGetUserVocabsByUserId = <
    TData = AsyncReturnType<typeof getUserVocabsByUserId>,
    TError = UserVocabErrorResponseDto
>(
    userid: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserVocabsByUserId>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetUserVocabsByUserIdQueryKey(userid);
    const queryFn: QueryFunction<
        AsyncReturnType<typeof getUserVocabsByUserId>
    > = () => getUserVocabsByUserId(userid);

    const query = useQuery<
        AsyncReturnType<typeof getUserVocabsByUserId>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!userid, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Gets the next vocab review date for a user
 */
export const getNextReviewDate = () => {
    return xhrServiceInstance<NextReviewDateSuccessResponseDto>({
        url: `/api/v2/user-vocabs/next_review_date`,
        method: 'get',
    });
};

export const getGetNextReviewDateQueryKey = () => [
    `/api/v2/user-vocabs/next_review_date`,
];

export const useGetNextReviewDate = <
    TData = AsyncReturnType<typeof getNextReviewDate>,
    TError = UserVocabErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getNextReviewDate>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetNextReviewDateQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getNextReviewDate>> =
        () => getNextReviewDate();

    const query = useQuery<
        AsyncReturnType<typeof getNextReviewDate>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Gets the number of pending vocabs of a learner
 */
export const getUserVocabsCountByUserId = (userid: string) => {
    return xhrServiceInstance<UserVocabsCountSuccessResponseDto>({
        url: `/api/v2/user-vocabs/${userid}/count`,
        method: 'get',
    });
};

export const getGetUserVocabsCountByUserIdQueryKey = (userid: string) => [
    `/api/v2/user-vocabs/${userid}/count`,
];

export const useGetUserVocabsCountByUserId = <
    TData = AsyncReturnType<typeof getUserVocabsCountByUserId>,
    TError = UserVocabErrorResponseDto
>(
    userid: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserVocabsCountByUserId>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetUserVocabsCountByUserIdQueryKey(userid);
    const queryFn: QueryFunction<
        AsyncReturnType<typeof getUserVocabsCountByUserId>
    > = () => getUserVocabsCountByUserId(userid);

    const query = useQuery<
        AsyncReturnType<typeof getUserVocabsCountByUserId>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!userid, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Gets the total number of vocabs of a learner
 */
export const getUserVocabsTotalCountByUserId = (userid: string) => {
    return xhrServiceInstance<UserVocabsCountSuccessResponseDto>({
        url: `/api/v2/user-vocabs/${userid}/count/total`,
        method: 'get',
    });
};

export const getGetUserVocabsTotalCountByUserIdQueryKey = (userid: string) => [
    `/api/v2/user-vocabs/${userid}/count/total`,
];

export const useGetUserVocabsTotalCountByUserId = <
    TData = AsyncReturnType<typeof getUserVocabsTotalCountByUserId>,
    TError = UserVocabErrorResponseDto
>(
    userid: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserVocabsTotalCountByUserId>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetUserVocabsTotalCountByUserIdQueryKey(userid);
    const queryFn: QueryFunction<
        AsyncReturnType<typeof getUserVocabsTotalCountByUserId>
    > = () => getUserVocabsTotalCountByUserId(userid);

    const query = useQuery<
        AsyncReturnType<typeof getUserVocabsTotalCountByUserId>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!userid, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Gets the user vocabs up for review of a learner
 */
export const getReviewUserVocabsByUserId = () => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/review`,
        method: 'get',
    });
};

export const getGetReviewUserVocabsByUserIdQueryKey = () => [
    `/api/v2/user-vocabs/review`,
];

export const useGetReviewUserVocabsByUserId = <
    TData = AsyncReturnType<typeof getReviewUserVocabsByUserId>,
    TError = UserVocabErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getReviewUserVocabsByUserId>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetReviewUserVocabsByUserIdQueryKey();
    const queryFn: QueryFunction<
        AsyncReturnType<typeof getReviewUserVocabsByUserId>
    > = () => getReviewUserVocabsByUserId();

    const query = useQuery<
        AsyncReturnType<typeof getReviewUserVocabsByUserId>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches a specific vocab of a learner
 */
export const getUserVocabById = (id: string) => {
    return xhrServiceInstance<SingleUserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/${id}`,
        method: 'get',
    });
};

export const getGetUserVocabByIdQueryKey = (id: string) => [
    `/api/v2/user-vocabs/${id}`,
];

export const useGetUserVocabById = <
    TData = AsyncReturnType<typeof getUserVocabById>,
    TError = UserVocabErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserVocabById>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetUserVocabByIdQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserVocabById>> =
        () => getUserVocabById(id);

    const query = useQuery<
        AsyncReturnType<typeof getUserVocabById>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Updates individual fields of a learner's vocab
 */
export const updateUserVocabById = (
    id: string,
    updateUserVocabDto: UpdateUserVocabDto
) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/${id}`,
        method: 'patch',
        data: updateUserVocabDto,
    });
};

export const useUpdateUserVocabById = <
    TError = UserVocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateUserVocabById>,
        TError,
        { id: string; data: UpdateUserVocabDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateUserVocabById>,
        { id: string; data: UpdateUserVocabDto }
    > = props => {
        const { id, data } = props || {};

        return updateUserVocabById(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateUserVocabById>,
        TError,
        { id: string; data: UpdateUserVocabDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Deletes a specific vocab of a learner
 */
export const deleteUserVocabById = (id: string) => {
    return xhrServiceInstance<SingleUserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/${id}`,
        method: 'delete',
    });
};

export const useDeleteUserVocabById = <
    TError = UserVocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof deleteUserVocabById>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof deleteUserVocabById>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return deleteUserVocabById(id);
    };

    return useMutation<
        AsyncReturnType<typeof deleteUserVocabById>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Adds several vocabs to a learner's vocab list
 */
export const createUserVocabs = (createUserVocabsDto: CreateUserVocabsDto) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/bulk`,
        method: 'post',
        data: createUserVocabsDto,
    });
};

export const useCreateUserVocabs = <
    TError = UserVocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createUserVocabs>,
        TError,
        { data: CreateUserVocabsDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createUserVocabs>,
        { data: CreateUserVocabsDto }
    > = props => {
        const { data } = props || {};

        return createUserVocabs(data);
    };

    return useMutation<
        AsyncReturnType<typeof createUserVocabs>,
        TError,
        { data: CreateUserVocabsDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Picks a vocab for a learner's review
 */
export const pickUserVocab = (
    pickUserVocabByVocabIdDto: PickUserVocabByVocabIdDto
) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/pick`,
        method: 'post',
        data: pickUserVocabByVocabIdDto,
    });
};

export const usePickUserVocab = <
    TError = UserVocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof pickUserVocab>,
        TError,
        { data: PickUserVocabByVocabIdDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof pickUserVocab>,
        { data: PickUserVocabByVocabIdDto }
    > = props => {
        const { data } = props || {};

        return pickUserVocab(data);
    };

    return useMutation<
        AsyncReturnType<typeof pickUserVocab>,
        TError,
        { data: PickUserVocabByVocabIdDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary [admin route] Imports vocabs from a csv file
 */
export const importUserVocabsByCsv = (
    importUserVocabFromCSVDto: ImportUserVocabFromCSVDto
) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/user-vocabs/import-from-csv`,
        method: 'post',
        data: importUserVocabFromCSVDto,
    });
};

export const useImportUserVocabsByCsv = <
    TError = UserVocabErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof importUserVocabsByCsv>,
        TError,
        { data: ImportUserVocabFromCSVDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof importUserVocabsByCsv>,
        { data: ImportUserVocabFromCSVDto }
    > = props => {
        const { data } = props || {};

        return importUserVocabsByCsv(data);
    };

    return useMutation<
        AsyncReturnType<typeof importUserVocabsByCsv>,
        TError,
        { data: ImportUserVocabFromCSVDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Parses vocabs from an article (natural text)
 */
export const importFromArticle = (
    importFromArticleDto: ImportFromArticleDto
) => {
    return xhrServiceInstance<TokenSuccessResponseDto>({
        url: `/api/v2/user-vocabs/import/article`,
        method: 'post',
        data: importFromArticleDto,
    });
};

export const useImportFromArticle = <
    TError = TokenErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof importFromArticle>,
        TError,
        { data: ImportFromArticleDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof importFromArticle>,
        { data: ImportFromArticleDto }
    > = props => {
        const { data } = props || {};

        return importFromArticle(data);
    };

    return useMutation<
        AsyncReturnType<typeof importFromArticle>,
        TError,
        { data: ImportFromArticleDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches challenges optionally filtered by a query
 */
export const getChallenges = (params?: GetChallengesParams) => {
    return xhrServiceInstance<ChallengesSuccessResponseDto>({
        url: `/api/v2/challenges`,
        method: 'get',
        params,
    });
};

export const getGetChallengesQueryKey = (params?: GetChallengesParams) => [
    `/api/v2/challenges`,
    ...(params ? [params] : []),
];

export const useGetChallenges = <
    TData = AsyncReturnType<typeof getChallenges>,
    TError = ChallengesErrorResponseDto
>(
    params?: GetChallengesParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getChallenges>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetChallengesQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getChallenges>> = () =>
        getChallenges(params);

    const query = useQuery<
        AsyncReturnType<typeof getChallenges>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Creates a new challenge
 */
export const createChallenge = (createChallengeDto: CreateChallengeDto) => {
    const formData = new FormData();
    if (createChallengeDto.name !== undefined) {
        formData.append('name', createChallengeDto.name);
    }
    if (createChallengeDto.language !== undefined) {
        formData.append('language', createChallengeDto.language);
    }
    if (createChallengeDto.cefr_level !== undefined) {
        formData.append('cefr_level', createChallengeDto.cefr_level);
    }
    if (createChallengeDto.description !== undefined) {
        formData.append('description', createChallengeDto.description);
    }
    if (createChallengeDto.exercise_types !== undefined) {
        formData.append(
            'exercise_types',
            JSON.stringify(createChallengeDto.exercise_types)
        );
    }
    if (createChallengeDto.challenge_type !== undefined) {
        formData.append('challenge_type', createChallengeDto.challenge_type);
    }
    if (createChallengeDto.examples_per_vocab !== undefined) {
        formData.append(
            'examples_per_vocab',
            createChallengeDto.examples_per_vocab.toString()
        );
    }
    if (createChallengeDto.vocab_picking_limit !== undefined) {
        formData.append(
            'vocab_picking_limit',
            createChallengeDto.vocab_picking_limit.toString()
        );
    }
    if (createChallengeDto.vocabs_suggested_per_day !== undefined) {
        formData.append(
            'vocabs_suggested_per_day',
            createChallengeDto.vocabs_suggested_per_day.toString()
        );
    }
    if (createChallengeDto.certificate_text !== undefined) {
        formData.append(
            'certificate_text',
            createChallengeDto.certificate_text
        );
    }
    if (createChallengeDto.certificate_id !== undefined) {
        formData.append('certificate_id', createChallengeDto.certificate_id);
    }
    if (createChallengeDto.creator_id !== undefined) {
        formData.append('creator_id', createChallengeDto.creator_id);
    }
    if (createChallengeDto.image_path !== undefined) {
        formData.append('image_path', createChallengeDto.image_path);
    }
    if (createChallengeDto.status !== undefined) {
        formData.append('status', createChallengeDto.status);
    }
    if (createChallengeDto.days !== undefined) {
        formData.append('days', JSON.stringify(createChallengeDto.days));
    }
    if (createChallengeDto.created_at !== undefined) {
        formData.append('created_at', createChallengeDto.created_at);
    }
    if (createChallengeDto.modified_at !== undefined) {
        formData.append('modified_at', createChallengeDto.modified_at);
    }

    return xhrServiceInstance<SingleChallengeSuccessResponseDto>({
        url: `/api/v2/challenges`,
        method: 'post',
        data: formData,
    });
};

export const useCreateChallenge = <
    TError = ChallengesErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createChallenge>,
        TError,
        { data: CreateChallengeDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createChallenge>,
        { data: CreateChallengeDto }
    > = props => {
        const { data } = props || {};

        return createChallenge(data);
    };

    return useMutation<
        AsyncReturnType<typeof createChallenge>,
        TError,
        { data: CreateChallengeDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches a challenge by id
 */
export const getChallenge = (id: string) => {
    return xhrServiceInstance<SingleChallengeSuccessResponseDto>({
        url: `/api/v2/challenges/${id}`,
        method: 'get',
    });
};

export const getGetChallengeQueryKey = (id: string) => [
    `/api/v2/challenges/${id}`,
];

export const useGetChallenge = <
    TData = AsyncReturnType<typeof getChallenge>,
    TError = ChallengesErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getChallenge>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetChallengeQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getChallenge>> = () =>
        getChallenge(id);

    const query = useQuery<AsyncReturnType<typeof getChallenge>, TError, TData>(
        queryKey,
        queryFn,
        { enabled: !!id, ...queryOptions }
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Updates a challenge by id
 */
export const updateChallenge = (
    id: string,
    updateChallengeDto: UpdateChallengeDto
) => {
    const formData = new FormData();
    if (updateChallengeDto.name !== undefined) {
        formData.append('name', updateChallengeDto.name);
    }
    if (updateChallengeDto.language !== undefined) {
        formData.append('language', updateChallengeDto.language);
    }
    if (updateChallengeDto.cefr_level !== undefined) {
        formData.append('cefr_level', updateChallengeDto.cefr_level);
    }
    if (updateChallengeDto.description !== undefined) {
        formData.append('description', updateChallengeDto.description);
    }
    if (updateChallengeDto.exercise_types !== undefined) {
        formData.append(
            'exercise_types',
            JSON.stringify(updateChallengeDto.exercise_types)
        );
    }
    if (updateChallengeDto.challenge_type !== undefined) {
        formData.append('challenge_type', updateChallengeDto.challenge_type);
    }
    if (updateChallengeDto.examples_per_vocab !== undefined) {
        formData.append(
            'examples_per_vocab',
            updateChallengeDto.examples_per_vocab.toString()
        );
    }
    if (updateChallengeDto.vocab_picking_limit !== undefined) {
        formData.append(
            'vocab_picking_limit',
            updateChallengeDto.vocab_picking_limit.toString()
        );
    }
    if (updateChallengeDto.vocabs_suggested_per_day !== undefined) {
        formData.append(
            'vocabs_suggested_per_day',
            updateChallengeDto.vocabs_suggested_per_day.toString()
        );
    }
    if (updateChallengeDto.certificate_text !== undefined) {
        formData.append(
            'certificate_text',
            updateChallengeDto.certificate_text
        );
    }
    if (updateChallengeDto.certificate_id !== undefined) {
        formData.append('certificate_id', updateChallengeDto.certificate_id);
    }
    if (updateChallengeDto.creator_id !== undefined) {
        formData.append('creator_id', updateChallengeDto.creator_id);
    }
    if (updateChallengeDto.image_path !== undefined) {
        formData.append('image_path', updateChallengeDto.image_path);
    }
    if (updateChallengeDto.status !== undefined) {
        formData.append('status', updateChallengeDto.status);
    }
    if (updateChallengeDto.days !== undefined) {
        formData.append('days', JSON.stringify(updateChallengeDto.days));
    }
    if (updateChallengeDto.created_at !== undefined) {
        formData.append('created_at', updateChallengeDto.created_at);
    }
    if (updateChallengeDto.modified_at !== undefined) {
        formData.append('modified_at', updateChallengeDto.modified_at);
    }

    return xhrServiceInstance<SingleChallengeSuccessResponseDto>({
        url: `/api/v2/challenges/${id}`,
        method: 'patch',
        data: formData,
    });
};

export const useUpdateChallenge = <
    TError = ChallengesErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateChallenge>,
        TError,
        { id: string; data: UpdateChallengeDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateChallenge>,
        { id: string; data: UpdateChallengeDto }
    > = props => {
        const { id, data } = props || {};

        return updateChallenge(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateChallenge>,
        TError,
        { id: string; data: UpdateChallengeDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Deletes a challenge by id
 */
export const deleteChallenge = (id: string) => {
    return xhrServiceInstance<DeletedChallengeSuccessResponseDto>({
        url: `/api/v2/challenges/${id}`,
        method: 'delete',
    });
};

export const useDeleteChallenge = <
    TError = ChallengesErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof deleteChallenge>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof deleteChallenge>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return deleteChallenge(id);
    };

    return useMutation<
        AsyncReturnType<typeof deleteChallenge>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Creates a challenge report given a challenge's id
 */
export const checkChallenge = (id: string) => {
    return xhrServiceInstance<ValidChallengeSuccessResponseDto>({
        url: `/api/v2/challenges/${id}/check`,
        method: 'get',
    });
};

export const getCheckChallengeQueryKey = (id: string) => [
    `/api/v2/challenges/${id}/check`,
];

export const useCheckChallenge = <
    TData = AsyncReturnType<typeof checkChallenge>,
    TError = ChallengesErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof checkChallenge>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getCheckChallengeQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof checkChallenge>> = () =>
        checkChallenge(id);

    const query = useQuery<
        AsyncReturnType<typeof checkChallenge>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Invalidates data cache for a specific service
 */
export const invalidateCache = (servicename: string) => {
    return xhrServiceInstance<void>({
        url: `/api/v2/data-cache/invalidate/${servicename}`,
        method: 'post',
        data: undefined,
    });
};

export const useInvalidateCache = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof invalidateCache>,
        TError,
        { servicename: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof invalidateCache>,
        { servicename: string }
    > = props => {
        const { servicename } = props || {};

        return invalidateCache(servicename);
    };

    return useMutation<
        AsyncReturnType<typeof invalidateCache>,
        TError,
        { servicename: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Looks up definitions of vocabs
 */
export const lookupDefinition = (params?: LookupDefinitionParams) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/lookup`,
        method: 'get',
        params,
    });
};

export const getLookupDefinitionQueryKey = (
    params?: LookupDefinitionParams
) => [`/api/v2/lookup`, ...(params ? [params] : [])];

export const useLookupDefinition = <
    TData = AsyncReturnType<typeof lookupDefinition>,
    TError = UserVocabErrorResponseDto
>(
    params?: LookupDefinitionParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof lookupDefinition>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getLookupDefinitionQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof lookupDefinition>> =
        () => lookupDefinition(params);

    const query = useQuery<
        AsyncReturnType<typeof lookupDefinition>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Sends a message to the "errors-frontend" Slack channel
 */
export const sendMessageToFrontend = (slackMessageDto: SlackMessageDto) => {
    return xhrServiceInstance<SlackMessageSentSuccessfullyResponseDto>({
        url: `/api/v2/slack/send-message/frontend`,
        method: 'post',
        data: slackMessageDto,
    });
};

export const useSendMessageToFrontend = <
    TError = SlackErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof sendMessageToFrontend>,
        TError,
        { data: SlackMessageDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof sendMessageToFrontend>,
        { data: SlackMessageDto }
    > = props => {
        const { data } = props || {};

        return sendMessageToFrontend(data);
    };

    return useMutation<
        AsyncReturnType<typeof sendMessageToFrontend>,
        TError,
        { data: SlackMessageDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Sends a message to the "errors-content" Slack channel
 */
export const sendMessageToContent = (slackMessageDto: SlackMessageDto) => {
    return xhrServiceInstance<SlackMessageSentSuccessfullyResponseDto>({
        url: `/api/v2/slack/send-message/content`,
        method: 'post',
        data: slackMessageDto,
    });
};

export const useSendMessageToContent = <
    TError = SlackErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof sendMessageToContent>,
        TError,
        { data: SlackMessageDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof sendMessageToContent>,
        { data: SlackMessageDto }
    > = props => {
        const { data } = props || {};

        return sendMessageToContent(data);
    };

    return useMutation<
        AsyncReturnType<typeof sendMessageToContent>,
        TError,
        { data: SlackMessageDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Sends a message to the "errors-tg" Slack channel
 */
export const sendMessageToTgBot = (slackMessageDto: SlackMessageDto) => {
    return xhrServiceInstance<SlackMessageSentSuccessfullyResponseDto>({
        url: `/api/v2/slack/send-message/tg-bot`,
        method: 'post',
        data: slackMessageDto,
    });
};

export const useSendMessageToTgBot = <
    TError = SlackErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof sendMessageToTgBot>,
        TError,
        { data: SlackMessageDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof sendMessageToTgBot>,
        { data: SlackMessageDto }
    > = props => {
        const { data } = props || {};

        return sendMessageToTgBot(data);
    };

    return useMutation<
        AsyncReturnType<typeof sendMessageToTgBot>,
        TError,
        { data: SlackMessageDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Sends a message to the "errors-tg" Slack channel, acting as Twilio webhook
 */
export const twilioWebhook = (twilioPayload: TwilioPayload) => {
    return xhrServiceInstance<SlackMessageSentSuccessfullyResponseDto>({
        url: `/api/v2/slack/send-message/wa-bot`,
        method: 'post',
        data: twilioPayload,
    });
};

export const useTwilioWebhook = <
    TError = SlackErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof twilioWebhook>,
        TError,
        { data: TwilioPayload },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof twilioWebhook>,
        { data: TwilioPayload }
    > = props => {
        const { data } = props || {};

        return twilioWebhook(data);
    };

    return useMutation<
        AsyncReturnType<typeof twilioWebhook>,
        TError,
        { data: TwilioPayload },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Sends a message to the "customer-obsession" Slack channel
 */
export const sendMessageToCustomerObsession = (
    slackMessageDto: SlackMessageDto
) => {
    return xhrServiceInstance<SlackMessageSentSuccessfullyResponseDto>({
        url: `/api/v2/slack/send-message/customer-obsession`,
        method: 'post',
        data: slackMessageDto,
    });
};

export const useSendMessageToCustomerObsession = <
    TError = SlackErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof sendMessageToCustomerObsession>,
        TError,
        { data: SlackMessageDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof sendMessageToCustomerObsession>,
        { data: SlackMessageDto }
    > = props => {
        const { data } = props || {};

        return sendMessageToCustomerObsession(data);
    };

    return useMutation<
        AsyncReturnType<typeof sendMessageToCustomerObsession>,
        TError,
        { data: SlackMessageDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches all user notifications, optionally filtered by a query
 */
export const getUserNotifications = (params?: GetUserNotificationsParams) => {
    return xhrServiceInstance<UserNotificationSuccessResponseDto>({
        url: `/api/v2/user-notifications`,
        method: 'get',
        params,
    });
};

export const getGetUserNotificationsQueryKey = (
    params?: GetUserNotificationsParams
) => [`/api/v2/user-notifications`, ...(params ? [params] : [])];

export const useGetUserNotifications = <
    TData = AsyncReturnType<typeof getUserNotifications>,
    TError = UserNotificationErrorResponseDto
>(
    params?: GetUserNotificationsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserNotifications>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetUserNotificationsQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserNotifications>> =
        () => getUserNotifications(params);

    const query = useQuery<
        AsyncReturnType<typeof getUserNotifications>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Creates a user notification
 */
export const createUserNotification = (
    createUserNotificationDto: CreateUserNotificationDto
) => {
    return xhrServiceInstance<SingleUserNotificationSuccessResponseDto>({
        url: `/api/v2/user-notifications`,
        method: 'post',
        data: createUserNotificationDto,
    });
};

export const useCreateUserNotification = <
    TError = UserNotificationErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createUserNotification>,
        TError,
        { data: CreateUserNotificationDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createUserNotification>,
        { data: CreateUserNotificationDto }
    > = props => {
        const { data } = props || {};

        return createUserNotification(data);
    };

    return useMutation<
        AsyncReturnType<typeof createUserNotification>,
        TError,
        { data: CreateUserNotificationDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches all uncompleted user notifications
 */
export const getUncompletedUserNotifications = () => {
    return xhrServiceInstance<UserNotificationSuccessResponseDto>({
        url: `/api/v2/user-notifications/uncompleted`,
        method: 'get',
    });
};

export const getGetUncompletedUserNotificationsQueryKey = () => [
    `/api/v2/user-notifications/uncompleted`,
];

export const useGetUncompletedUserNotifications = <
    TData = AsyncReturnType<typeof getUncompletedUserNotifications>,
    TError = UserNotificationErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getUncompletedUserNotifications>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetUncompletedUserNotificationsQueryKey();
    const queryFn: QueryFunction<
        AsyncReturnType<typeof getUncompletedUserNotifications>
    > = () => getUncompletedUserNotifications();

    const query = useQuery<
        AsyncReturnType<typeof getUncompletedUserNotifications>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Dismisses a user notification
 */
export const dismissUserNotification = (id: string) => {
    return xhrServiceInstance<SingleUserNotificationSuccessResponseDto>({
        url: `/api/v2/user-notifications/${id}/dismiss`,
        method: 'patch',
        data: undefined,
    });
};

export const useDismissUserNotification = <
    TError = UserNotificationErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof dismissUserNotification>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof dismissUserNotification>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return dismissUserNotification(id);
    };

    return useMutation<
        AsyncReturnType<typeof dismissUserNotification>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Completes a user notification
 */
export const completeUserNotification = (id: string) => {
    return xhrServiceInstance<SingleUserNotificationSuccessResponseDto>({
        url: `/api/v2/user-notifications/${id}/complete`,
        method: 'patch',
        data: undefined,
    });
};

export const useCompleteUserNotification = <
    TError = UserNotificationErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof completeUserNotification>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof completeUserNotification>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return completeUserNotification(id);
    };

    return useMutation<
        AsyncReturnType<typeof completeUserNotification>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Ping telegram bot
 */
export const ping = () => {
    return xhrServiceInstance<void>({
        url: `/api/v2/telegram/ping`,
        method: 'get',
    });
};

export const getPingQueryKey = () => [`/api/v2/telegram/ping`];

export const usePing = <
    TData = AsyncReturnType<typeof ping>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof ping>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getPingQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof ping>> = () => ping();

    const query = useQuery<AsyncReturnType<typeof ping>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches all telegram messages, optionally filtered by a query
 */
export const getTelegramMessages = () => {
    return xhrServiceInstance<TelegramMessagesSuccessResponseDto>({
        url: `/api/v2/telegram/messages`,
        method: 'get',
    });
};

export const getGetTelegramMessagesQueryKey = () => [
    `/api/v2/telegram/messages`,
];

export const useGetTelegramMessages = <
    TData = AsyncReturnType<typeof getTelegramMessages>,
    TError = TelegramMessageErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getTelegramMessages>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetTelegramMessagesQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getTelegramMessages>> =
        () => getTelegramMessages();

    const query = useQuery<
        AsyncReturnType<typeof getTelegramMessages>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Sends a telegram message
 */
export const sendTelegramMessage = (
    sendTelegramMessageDto: SendTelegramMessageDto
) => {
    return xhrServiceInstance<SingleTelegramMessageSuccessResponseDto>({
        url: `/api/v2/telegram/messages/send`,
        method: 'post',
        data: sendTelegramMessageDto,
    });
};

export const useSendTelegramMessage = <
    TError = TelegramMessageErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof sendTelegramMessage>,
        TError,
        { data: SendTelegramMessageDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof sendTelegramMessage>,
        { data: SendTelegramMessageDto }
    > = props => {
        const { data } = props || {};

        return sendTelegramMessage(data);
    };

    return useMutation<
        AsyncReturnType<typeof sendTelegramMessage>,
        TError,
        { data: SendTelegramMessageDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Sends a telegram message
 */
export const sendTelegramMessageToAll = (
    sendTelegramMessageDto: SendTelegramMessageDto
) => {
    return xhrServiceInstance<SingleTelegramMessageSuccessResponseDto>({
        url: `/api/v2/telegram/messages/send-to-all`,
        method: 'post',
        data: sendTelegramMessageDto,
    });
};

export const useSendTelegramMessageToAll = <
    TError = TelegramMessageErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof sendTelegramMessageToAll>,
        TError,
        { data: SendTelegramMessageDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof sendTelegramMessageToAll>,
        { data: SendTelegramMessageDto }
    > = props => {
        const { data } = props || {};

        return sendTelegramMessageToAll(data);
    };

    return useMutation<
        AsyncReturnType<typeof sendTelegramMessageToAll>,
        TError,
        { data: SendTelegramMessageDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Get suggestions for userVocabs given a search query (seeks in both `literal` and `baseform`)
 */
export const getUserVocabSuggestions = (
    params?: GetUserVocabSuggestionsParams
) => {
    return xhrServiceInstance<UserVocabSuccessResponseDto>({
        url: `/api/v2/suggestions/user-vocabs`,
        method: 'get',
        params,
    });
};

export const getGetUserVocabSuggestionsQueryKey = (
    params?: GetUserVocabSuggestionsParams
) => [`/api/v2/suggestions/user-vocabs`, ...(params ? [params] : [])];

export const useGetUserVocabSuggestions = <
    TData = AsyncReturnType<typeof getUserVocabSuggestions>,
    TError = UserVocabErrorResponseDto
>(
    params?: GetUserVocabSuggestionsParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserVocabSuggestions>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetUserVocabSuggestionsQueryKey(params);
    const queryFn: QueryFunction<
        AsyncReturnType<typeof getUserVocabSuggestions>
    > = () => getUserVocabSuggestions(params);

    const query = useQuery<
        AsyncReturnType<typeof getUserVocabSuggestions>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

export const addUserVocabSuggestion = (
    createSuggestionDto: CreateSuggestionDto
) => {
    return xhrServiceInstance<void>({
        url: `/api/v2/suggestions/user-vocabs`,
        method: 'post',
        data: createSuggestionDto,
    });
};

export const useAddUserVocabSuggestion = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof addUserVocabSuggestion>,
        TError,
        { data: CreateSuggestionDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof addUserVocabSuggestion>,
        { data: CreateSuggestionDto }
    > = props => {
        const { data } = props || {};

        return addUserVocabSuggestion(data);
    };

    return useMutation<
        AsyncReturnType<typeof addUserVocabSuggestion>,
        TError,
        { data: CreateSuggestionDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary [admin route] Imports user vocab suggestions from a csv file
 */
export const importUserVocabSuggestions = (
    importSuggestionDto: ImportSuggestionDto
) => {
    return xhrServiceInstance<void>({
        url: `/api/v2/suggestions/user-vocabs/import/csv`,
        method: 'post',
        data: importSuggestionDto,
    });
};

export const useImportUserVocabSuggestions = <
    TError = unknown,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof importUserVocabSuggestions>,
        TError,
        { data: ImportSuggestionDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof importUserVocabSuggestions>,
        { data: ImportSuggestionDto }
    > = props => {
        const { data } = props || {};

        return importUserVocabSuggestions(data);
    };

    return useMutation<
        AsyncReturnType<typeof importUserVocabSuggestions>,
        TError,
        { data: ImportSuggestionDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Webhook for Videoask integration
 */
export const videoaskHook = () => {
    return xhrServiceInstance<IntegrationSuccessResponseDto>({
        url: `/api/v2/integrations/videoask`,
        method: 'post',
        data: undefined,
    });
};

export const useVideoaskHook = <
    TError = IntegrationErrorResponseDto,
    TVariables = void,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof videoaskHook>,
        TError,
        TVariables,
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof videoaskHook>,
        TVariables
    > = () => {
        return videoaskHook();
    };

    return useMutation<
        AsyncReturnType<typeof videoaskHook>,
        TError,
        TVariables,
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Returns the latest forced update signal timestamp
 */
export const getLatestSignal = (params?: GetLatestSignalParams) => {
    return xhrServiceInstance<LatestUpdateSignalSuccessResponseDto>({
        url: `/api/v2/force-update`,
        method: 'get',
        params,
    });
};

export const getGetLatestSignalQueryKey = (params?: GetLatestSignalParams) => [
    `/api/v2/force-update`,
    ...(params ? [params] : []),
];

export const useGetLatestSignal = <
    TData = AsyncReturnType<typeof getLatestSignal>,
    TError = LatestUpdateSignalErrorResponseDto
>(
    params?: GetLatestSignalParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getLatestSignal>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetLatestSignalQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getLatestSignal>> =
        () => getLatestSignal(params);

    const query = useQuery<
        AsyncReturnType<typeof getLatestSignal>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Sends a forced update signal
 */
export const createSignal = (params?: CreateSignalParams) => {
    return xhrServiceInstance<LatestUpdateSignalSuccessResponseDto>({
        url: `/api/v2/force-update`,
        method: 'post',
        data: undefined,
        params,
    });
};

export const useCreateSignal = <
    TError = LatestUpdateSignalErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createSignal>,
        TError,
        { params?: CreateSignalParams },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createSignal>,
        { params?: CreateSignalParams }
    > = props => {
        const { params } = props || {};

        return createSignal(params);
    };

    return useMutation<
        AsyncReturnType<typeof createSignal>,
        TError,
        { params?: CreateSignalParams },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches all languages
 */
export const getLanguages = () => {
    return xhrServiceInstance<LanguageSuccessResponseDto>({
        url: `/api/v2/languages`,
        method: 'get',
    });
};

export const getGetLanguagesQueryKey = () => [`/api/v2/languages`];

export const useGetLanguages = <
    TData = AsyncReturnType<typeof getLanguages>,
    TError = LanguageErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getLanguages>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetLanguagesQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getLanguages>> = () =>
        getLanguages();

    const query = useQuery<AsyncReturnType<typeof getLanguages>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches info about a specific language
 */
export const getLanguage = (languagecode: string) => {
    return xhrServiceInstance<LanguageSuccessResponseDto>({
        url: `/api/v2/languages/${languagecode}`,
        method: 'get',
    });
};

export const getGetLanguageQueryKey = (languagecode: string) => [
    `/api/v2/languages/${languagecode}`,
];

export const useGetLanguage = <
    TData = AsyncReturnType<typeof getLanguage>,
    TError = LanguageErrorResponseDto
>(
    languagecode: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getLanguage>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetLanguageQueryKey(languagecode);
    const queryFn: QueryFunction<AsyncReturnType<typeof getLanguage>> = () =>
        getLanguage(languagecode);

    const query = useQuery<AsyncReturnType<typeof getLanguage>, TError, TData>(
        queryKey,
        queryFn,
        { enabled: !!languagecode, ...queryOptions }
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches taxonomies optionally filtered by a query
 */
export const getTaxonomies = () => {
    return xhrServiceInstance<TaxonomySuccessResponseDto>({
        url: `/api/v2/taxonomies`,
        method: 'get',
    });
};

export const getGetTaxonomiesQueryKey = () => [`/api/v2/taxonomies`];

export const useGetTaxonomies = <
    TData = AsyncReturnType<typeof getTaxonomies>,
    TError = TaxonomyErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getTaxonomies>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetTaxonomiesQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getTaxonomies>> = () =>
        getTaxonomies();

    const query = useQuery<
        AsyncReturnType<typeof getTaxonomies>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Creates a new taxonomy
 */
export const createTaxonomy = (createTaxonomyDto: CreateTaxonomyDto) => {
    return xhrServiceInstance<TaxonomySuccessResponseDto>({
        url: `/api/v2/taxonomies`,
        method: 'post',
        data: createTaxonomyDto,
    });
};

export const useCreateTaxonomy = <
    TError = TaxonomyErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createTaxonomy>,
        TError,
        { data: CreateTaxonomyDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createTaxonomy>,
        { data: CreateTaxonomyDto }
    > = props => {
        const { data } = props || {};

        return createTaxonomy(data);
    };

    return useMutation<
        AsyncReturnType<typeof createTaxonomy>,
        TError,
        { data: CreateTaxonomyDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches taxonomy values for a given taxonomy key
 */
export const getTaxonomiesValuesByKey = (key: string) => {
    return xhrServiceInstance<TaxonomyValuesSuccessResponseDto>({
        url: `/api/v2/taxonomies/values/${key}`,
        method: 'get',
    });
};

export const getGetTaxonomiesValuesByKeyQueryKey = (key: string) => [
    `/api/v2/taxonomies/values/${key}`,
];

export const useGetTaxonomiesValuesByKey = <
    TData = AsyncReturnType<typeof getTaxonomiesValuesByKey>,
    TError = TaxonomyErrorResponseDto
>(
    key: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getTaxonomiesValuesByKey>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getGetTaxonomiesValuesByKeyQueryKey(key);
    const queryFn: QueryFunction<
        AsyncReturnType<typeof getTaxonomiesValuesByKey>
    > = () => getTaxonomiesValuesByKey(key);

    const query = useQuery<
        AsyncReturnType<typeof getTaxonomiesValuesByKey>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!key, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Updates individual fields of a taxonomy
 */
export const updateTaxonomyById = (
    id: string,
    updateTaxonomyDto: UpdateTaxonomyDto
) => {
    return xhrServiceInstance<TaxonomySuccessResponseDto>({
        url: `/api/v2/taxonomies/${id}`,
        method: 'patch',
        data: updateTaxonomyDto,
    });
};

export const useUpdateTaxonomyById = <
    TError = TaxonomyErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateTaxonomyById>,
        TError,
        { id: string; data: UpdateTaxonomyDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateTaxonomyById>,
        { id: string; data: UpdateTaxonomyDto }
    > = props => {
        const { id, data } = props || {};

        return updateTaxonomyById(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateTaxonomyById>,
        TError,
        { id: string; data: UpdateTaxonomyDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Uploads an image
 */
export const uploadImage = (fileUploadDto: FileUploadDto) => {
    const formData = new FormData();
    formData.append('file', fileUploadDto.file);
    if (fileUploadDto.path !== undefined) {
        formData.append('path', fileUploadDto.path);
    }

    return xhrServiceInstance<string>({
        url: `/api/v2/uploads/image`,
        method: 'post',
        data: formData,
    });
};

export const useUploadImage = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof uploadImage>,
        TError,
        { data: FileUploadDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof uploadImage>,
        { data: FileUploadDto }
    > = props => {
        const { data } = props || {};

        return uploadImage(data);
    };

    return useMutation<
        AsyncReturnType<typeof uploadImage>,
        TError,
        { data: FileUploadDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Uploads a csv file
 */
export const uploadCsv = (fileUploadDto: FileUploadDto) => {
    const formData = new FormData();
    formData.append('file', fileUploadDto.file);
    if (fileUploadDto.path !== undefined) {
        formData.append('path', fileUploadDto.path);
    }

    return xhrServiceInstance<string>({
        url: `/api/v2/uploads/csv`,
        method: 'post',
        data: formData,
    });
};

export const useUploadCsv = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof uploadCsv>,
        TError,
        { data: FileUploadDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof uploadCsv>,
        { data: FileUploadDto }
    > = props => {
        const { data } = props || {};

        return uploadCsv(data);
    };

    return useMutation<
        AsyncReturnType<typeof uploadCsv>,
        TError,
        { data: FileUploadDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Uploads an audio
 */
export const uploadAudio = (fileUploadDto: FileUploadDto) => {
    const formData = new FormData();
    formData.append('file', fileUploadDto.file);
    if (fileUploadDto.path !== undefined) {
        formData.append('path', fileUploadDto.path);
    }

    return xhrServiceInstance<string>({
        url: `/api/v2/uploads/audio`,
        method: 'post',
        data: formData,
    });
};

export const useUploadAudio = <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof uploadAudio>,
        TError,
        { data: FileUploadDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof uploadAudio>,
        { data: FileUploadDto }
    > = props => {
        const { data } = props || {};

        return uploadAudio(data);
    };

    return useMutation<
        AsyncReturnType<typeof uploadAudio>,
        TError,
        { data: FileUploadDto },
        TContext
    >(mutationFn, mutationOptions);
};

export const pingBackend = () => {
    return xhrServiceInstance<void>({
        url: `/api/v2/monitoring/ping`,
        method: 'get',
    });
};

export const getPingBackendQueryKey = () => [`/api/v2/monitoring/ping`];

export const usePingBackend = <
    TData = AsyncReturnType<typeof pingBackend>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof pingBackend>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getPingBackendQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof pingBackend>> = () =>
        pingBackend();

    const query = useQuery<AsyncReturnType<typeof pingBackend>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

export const pingParsing = () => {
    return xhrServiceInstance<void>({
        url: `/api/v2/monitoring/ping/parsing`,
        method: 'get',
    });
};

export const getPingParsingQueryKey = () => [`/api/v2/monitoring/ping/parsing`];

export const usePingParsing = <
    TData = AsyncReturnType<typeof pingParsing>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof pingParsing>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getPingParsingQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof pingParsing>> = () =>
        pingParsing();

    const query = useQuery<AsyncReturnType<typeof pingParsing>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

export const pingRanking = () => {
    return xhrServiceInstance<void>({
        url: `/api/v2/monitoring/ping/ranking`,
        method: 'get',
    });
};

export const getPingRankingQueryKey = () => [`/api/v2/monitoring/ping/ranking`];

export const usePingRanking = <
    TData = AsyncReturnType<typeof pingRanking>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof pingRanking>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getPingRankingQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof pingRanking>> = () =>
        pingRanking();

    const query = useQuery<AsyncReturnType<typeof pingRanking>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

export const pingWordnet = () => {
    return xhrServiceInstance<void>({
        url: `/api/v2/monitoring/ping/wordnet`,
        method: 'get',
    });
};

export const getPingWordnetQueryKey = () => [`/api/v2/monitoring/ping/wordnet`];

export const usePingWordnet = <
    TData = AsyncReturnType<typeof pingWordnet>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof pingWordnet>, TError, TData>;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getPingWordnetQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof pingWordnet>> = () =>
        pingWordnet();

    const query = useQuery<AsyncReturnType<typeof pingWordnet>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches decks optionally filtered by a query
 */
export const getDecks = (params?: GetDecksParams) => {
    return xhrServiceInstance<DeckSuccessResponseDto>({
        url: `/api/v2/decks`,
        method: 'get',
        params,
    });
};

export const getGetDecksQueryKey = (params?: GetDecksParams) => [
    `/api/v2/decks`,
    ...(params ? [params] : []),
];

export const useGetDecks = <
    TData = AsyncReturnType<typeof getDecks>,
    TError = DeckErrorResponseDto
>(
    params?: GetDecksParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getDecks>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetDecksQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getDecks>> = () =>
        getDecks(params);

    const query = useQuery<AsyncReturnType<typeof getDecks>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary [admin route] Creates a new deck
 */
export const createDeck = (createDeckDto: CreateDeckDto) => {
    return xhrServiceInstance<SingleDeckSuccessResponseDto>({
        url: `/api/v2/decks`,
        method: 'post',
        data: createDeckDto,
    });
};

export const useCreateDeck = <
    TError = DeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createDeck>,
        TError,
        { data: CreateDeckDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createDeck>,
        { data: CreateDeckDto }
    > = props => {
        const { data } = props || {};

        return createDeck(data);
    };

    return useMutation<
        AsyncReturnType<typeof createDeck>,
        TError,
        { data: CreateDeckDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches all public decks
 */
export const getPubliDecks = () => {
    return xhrServiceInstance<DeckSuccessResponseDto>({
        url: `/api/v2/decks/public`,
        method: 'get',
    });
};

export const getGetPubliDecksQueryKey = () => [`/api/v2/decks/public`];

export const useGetPubliDecks = <
    TData = AsyncReturnType<typeof getPubliDecks>,
    TError = DeckErrorResponseDto
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getPubliDecks>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetPubliDecksQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getPubliDecks>> = () =>
        getPubliDecks();

    const query = useQuery<
        AsyncReturnType<typeof getPubliDecks>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches a speficic deck by its `id`
 */
export const getDeckById = (id: string) => {
    return xhrServiceInstance<SingleDeckSuccessResponseDto>({
        url: `/api/v2/decks/${id}`,
        method: 'get',
    });
};

export const getGetDeckByIdQueryKey = (id: string) => [`/api/v2/decks/${id}`];

export const useGetDeckById = <
    TData = AsyncReturnType<typeof getDeckById>,
    TError = DeckErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getDeckById>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetDeckByIdQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getDeckById>> = () =>
        getDeckById(id);

    const query = useQuery<AsyncReturnType<typeof getDeckById>, TError, TData>(
        queryKey,
        queryFn,
        { enabled: !!id, ...queryOptions }
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Updates a specific deck
 */
export const updateDeck = (id: string, updateDeckDto: UpdateDeckDto) => {
    return xhrServiceInstance<SingleDeckSuccessResponseDto>({
        url: `/api/v2/decks/${id}`,
        method: 'patch',
        data: updateDeckDto,
    });
};

export const useUpdateDeck = <
    TError = DeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateDeck>,
        TError,
        { id: string; data: UpdateDeckDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateDeck>,
        { id: string; data: UpdateDeckDto }
    > = props => {
        const { id, data } = props || {};

        return updateDeck(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateDeck>,
        TError,
        { id: string; data: UpdateDeckDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Picks vocabs from a deck
 */
export const pickVocabs = (id: string, pickVocabsDto: PickVocabsDto) => {
    return xhrServiceInstance<SingleDeckSuccessResponseDto>({
        url: `/api/v2/decks/${id}/pick`,
        method: 'post',
        data: pickVocabsDto,
    });
};

export const usePickVocabs = <
    TError = DeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof pickVocabs>,
        TError,
        { id: string; data: PickVocabsDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof pickVocabs>,
        { id: string; data: PickVocabsDto }
    > = props => {
        const { id, data } = props || {};

        return pickVocabs(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof pickVocabs>,
        TError,
        { id: string; data: PickVocabsDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Fetches user decks optionally filtered by a query
 */
export const getUserDecks = (params?: GetUserDecksParams) => {
    return xhrServiceInstance<UserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks`,
        method: 'get',
        params,
    });
};

export const getGetUserDecksQueryKey = (params?: GetUserDecksParams) => [
    `/api/v2/user-decks`,
    ...(params ? [params] : []),
];

export const useGetUserDecks = <
    TData = AsyncReturnType<typeof getUserDecks>,
    TError = UserDeckErrorResponseDto
>(
    params?: GetUserDecksParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserDecks>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetUserDecksQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserDecks>> = () =>
        getUserDecks(params);

    const query = useQuery<AsyncReturnType<typeof getUserDecks>, TError, TData>(
        queryKey,
        queryFn,
        queryOptions
    );

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Creates a new user deck
 */
export const createUserDeck = (createUserDeckDto: CreateUserDeckDto) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks`,
        method: 'post',
        data: createUserDeckDto,
    });
};

export const useCreateUserDeck = <
    TError = UserDeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof createUserDeck>,
        TError,
        { data: CreateUserDeckDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof createUserDeck>,
        { data: CreateUserDeckDto }
    > = props => {
        const { data } = props || {};

        return createUserDeck(data);
    };

    return useMutation<
        AsyncReturnType<typeof createUserDeck>,
        TError,
        { data: CreateUserDeckDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Searches for decks given an input string
 */
export const searchUserDeck = (params?: SearchUserDeckParams) => {
    return xhrServiceInstance<UserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/search`,
        method: 'get',
        params,
    });
};

export const getSearchUserDeckQueryKey = (params?: SearchUserDeckParams) => [
    `/api/v2/user-decks/search`,
    ...(params ? [params] : []),
];

export const useSearchUserDeck = <
    TData = AsyncReturnType<typeof searchUserDeck>,
    TError = UserDeckErrorResponseDto
>(
    params?: SearchUserDeckParams,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof searchUserDeck>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey =
        queryOptions?.queryKey ?? getSearchUserDeckQueryKey(params);
    const queryFn: QueryFunction<AsyncReturnType<typeof searchUserDeck>> = () =>
        searchUserDeck(params);

    const query = useQuery<
        AsyncReturnType<typeof searchUserDeck>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Fetches a speficic deck by its `id`
 */
export const getUserDeckById = (id: string) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/${id}`,
        method: 'get',
    });
};

export const getGetUserDeckByIdQueryKey = (id: string) => [
    `/api/v2/user-decks/${id}`,
];

export const useGetUserDeckById = <
    TData = AsyncReturnType<typeof getUserDeckById>,
    TError = UserDeckErrorResponseDto
>(
    id: string,
    options?: {
        query?: UseQueryOptions<
            AsyncReturnType<typeof getUserDeckById>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetUserDeckByIdQueryKey(id);
    const queryFn: QueryFunction<AsyncReturnType<typeof getUserDeckById>> =
        () => getUserDeckById(id);

    const query = useQuery<
        AsyncReturnType<typeof getUserDeckById>,
        TError,
        TData
    >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Updates a specific user deck
 */
export const updateUserDeck = (
    id: string,
    updateUserDeckDto: UpdateUserDeckDto
) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/${id}`,
        method: 'patch',
        data: updateUserDeckDto,
    });
};

export const useUpdateUserDeck = <
    TError = UserDeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof updateUserDeck>,
        TError,
        { id: string; data: UpdateUserDeckDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof updateUserDeck>,
        { id: string; data: UpdateUserDeckDto }
    > = props => {
        const { id, data } = props || {};

        return updateUserDeck(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof updateUserDeck>,
        TError,
        { id: string; data: UpdateUserDeckDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Soft-deletes a user deck
 */
export const deleteUserDeck = (id: string) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/${id}`,
        method: 'delete',
    });
};

export const useDeleteUserDeck = <
    TError = UserDeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof deleteUserDeck>,
        TError,
        { id: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof deleteUserDeck>,
        { id: string }
    > = props => {
        const { id } = props || {};

        return deleteUserDeck(id);
    };

    return useMutation<
        AsyncReturnType<typeof deleteUserDeck>,
        TError,
        { id: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Adds an existing deck as user deck
 */
export const addUserDeck = (deckid: string) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/${deckid}`,
        method: 'post',
        data: undefined,
    });
};

export const useAddUserDeck = <
    TError = UserDeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof addUserDeck>,
        TError,
        { deckid: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof addUserDeck>,
        { deckid: string }
    > = props => {
        const { deckid } = props || {};

        return addUserDeck(deckid);
    };

    return useMutation<
        AsyncReturnType<typeof addUserDeck>,
        TError,
        { deckid: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Adds one or more user vocabs entry to a user deck
 */
export const addEntry = (id: string, addEntryDto: AddEntryDto) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/${id}/add-entries`,
        method: 'post',
        data: addEntryDto,
    });
};

export const useAddEntry = <
    TError = UserDeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof addEntry>,
        TError,
        { id: string; data: AddEntryDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof addEntry>,
        { id: string; data: AddEntryDto }
    > = props => {
        const { id, data } = props || {};

        return addEntry(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof addEntry>,
        TError,
        { id: string; data: AddEntryDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Removes a user vocab entry from a user deck
 */
export const removeEntry = (id: string, entry: string) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/${id}/remove-entry/${entry}`,
        method: 'delete',
    });
};

export const useRemoveEntry = <
    TError = UserDeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof removeEntry>,
        TError,
        { id: string; entry: string },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof removeEntry>,
        { id: string; entry: string }
    > = props => {
        const { id, entry } = props || {};

        return removeEntry(id, entry);
    };

    return useMutation<
        AsyncReturnType<typeof removeEntry>,
        TError,
        { id: string; entry: string },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Copies a public user deck over to another user
 */
export const copyDeck = (id: string, copyUserDeckDto: CopyUserDeckDto) => {
    return xhrServiceInstance<SingleUserDeckSuccessResponseDto>({
        url: `/api/v2/user-decks/${id}/copy`,
        method: 'post',
        data: copyUserDeckDto,
    });
};

export const useCopyDeck = <
    TError = UserDeckErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof copyDeck>,
        TError,
        { id: string; data: CopyUserDeckDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof copyDeck>,
        { id: string; data: CopyUserDeckDto }
    > = props => {
        const { id, data } = props || {};

        return copyDeck(id, data);
    };

    return useMutation<
        AsyncReturnType<typeof copyDeck>,
        TError,
        { id: string; data: CopyUserDeckDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Returns analytics data
 */
export const getAllAnalytics = (analyticsQueryDto: AnalyticsQueryDto) => {
    return xhrServiceInstance<AnalyticsSuccessResponseDto>({
        url: `/api/v2/analytics`,
        method: 'post',
        data: analyticsQueryDto,
    });
};

export const useGetAllAnalytics = <
    TError = AnalyticsErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof getAllAnalytics>,
        TError,
        { data: AnalyticsQueryDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof getAllAnalytics>,
        { data: AnalyticsQueryDto }
    > = props => {
        const { data } = props || {};

        return getAllAnalytics(data);
    };

    return useMutation<
        AsyncReturnType<typeof getAllAnalytics>,
        TError,
        { data: AnalyticsQueryDto },
        TContext
    >(mutationFn, mutationOptions);
};

export const getAllAnalyticsTemp = () => {
    return xhrServiceInstance<void>({
        url: `/api/v2/analytics`,
        method: 'get',
    });
};

export const getGetAllAnalyticsTempQueryKey = () => [`/api/v2/analytics`];

export const useGetAllAnalyticsTemp = <
    TData = AsyncReturnType<typeof getAllAnalyticsTemp>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<
        AsyncReturnType<typeof getAllAnalyticsTemp>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options || {};

    const queryKey = queryOptions?.queryKey ?? getGetAllAnalyticsTempQueryKey();
    const queryFn: QueryFunction<AsyncReturnType<typeof getAllAnalyticsTemp>> =
        () => getAllAnalyticsTemp();

    const query = useQuery<
        AsyncReturnType<typeof getAllAnalyticsTemp>,
        TError,
        TData
    >(queryKey, queryFn, queryOptions);

    return {
        queryKey,
        ...query,
    };
};

/**
 * @summary Returns count of new users within two dates who connected to TG bot
 */
export const getNewUsersTGCount = (analyticsQueryDto: AnalyticsQueryDto) => {
    return xhrServiceInstance<AnalyticsSuccessResponseDto>({
        url: `/api/v2/analytics/tg`,
        method: 'post',
        data: analyticsQueryDto,
    });
};

export const useGetNewUsersTGCount = <
    TError = AnalyticsErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof getNewUsersTGCount>,
        TError,
        { data: AnalyticsQueryDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof getNewUsersTGCount>,
        { data: AnalyticsQueryDto }
    > = props => {
        const { data } = props || {};

        return getNewUsersTGCount(data);
    };

    return useMutation<
        AsyncReturnType<typeof getNewUsersTGCount>,
        TError,
        { data: AnalyticsQueryDto },
        TContext
    >(mutationFn, mutationOptions);
};

/**
 * @summary Returns count of vocab practices within two dates
 */
export const getPractices = (analyticsQueryDto: AnalyticsQueryDto) => {
    return xhrServiceInstance<AnalyticsSuccessResponseDto>({
        url: `/api/v2/analytics/practices`,
        method: 'post',
        data: analyticsQueryDto,
    });
};

export const useGetPractices = <
    TError = AnalyticsErrorResponseDto,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        AsyncReturnType<typeof getPractices>,
        TError,
        { data: AnalyticsQueryDto },
        TContext
    >;
}) => {
    const { mutation: mutationOptions } = options || {};

    const mutationFn: MutationFunction<
        AsyncReturnType<typeof getPractices>,
        { data: AnalyticsQueryDto }
    > = props => {
        const { data } = props || {};

        return getPractices(data);
    };

    return useMutation<
        AsyncReturnType<typeof getPractices>,
        TError,
        { data: AnalyticsQueryDto },
        TContext
    >(mutationFn, mutationOptions);
};
