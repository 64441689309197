import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { UploadSingleFile } from './upload/UploadSingleFile';
import { FormikProvider, useFormik } from 'formik';
import { FormikSubmitFunction } from 'types/formik';
import { useSnackbar } from 'hooks/useSnackbar';
import { uploadCsv, useImportVocabsByCsv } from 'api';
import { createRoute } from 'utils/createRoute';
import { useNavigate } from 'react-router-dom';

const initialValues: { csv?: any } = {};

export function CSVUploadForm() {
    const { mutate: importCSV } = useImportVocabsByCsv();
    const navigate = useNavigate();
    const [enqueueSnackbar] = useSnackbar();

    const onSubmit: FormikSubmitFunction<typeof initialValues> = (
        data,
        { resetForm, setStatus, setSubmitting }
    ) => {
        const path = data.csv;
        importCSV(
            { data: { path } },
            {
                onSuccess: response => {
                    if (response.success && response.result) {
                        const vocabs = response.result;
                        enqueueSnackbar(
                            `Successfully imported ${
                                vocabs?.length || 0
                            } vocabs. Redirecting…`
                        );
                        setTimeout(() => navigate(createRoute('vocabs')), 2000);
                    }
                    resetForm();
                    setSubmitting(false);
                },
            }
        );
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        initialStatus: {
            error: '',
            sent: false,
        },
    });

    const {
        isSubmitting,
        handleSubmit,
        status,
        setFieldValue,
        values,
        touched,
        errors,
    } = formik;

    const handleDrop = useCallback(
        async acceptedFiles => {
            const file = acceptedFiles[0];
            if (file) {
                const { result } = (await uploadCsv({
                    file,
                })) as any;
                setFieldValue('csv', result?.path);
                handleSubmit();
            }
        },
        [setFieldValue]
    );

    return (
        <FormikProvider value={formik}>
            <Typography>CSV:</Typography>
            <UploadSingleFile
                isUploading={isSubmitting}
                withPreview={false}
                maxSize={2_000_000 /* 2 MB */}
                // accept='text/csv'
                file={values?.csv as any}
                onDrop={handleDrop}
                error={Boolean(touched.csv && errors.csv)}
                sx={{ width: 500 }}
            />
        </FormikProvider>
    );
}
