import { createContext, useContext, ReactNode, useState } from 'react';
import { getLanguage, LanguageSchema } from 'api';
import { useEffectOnce } from 'react-use';
import { get } from 'lodash';

const supportedLanguages = ['de', 'en', 'fr', 'es', 'it', 'ru'];

type Context = { loading: boolean; languages: LanguageSchema[] };

const LanguagesContext = createContext<Context>({
    loading: false,
    languages: [],
});

type ProviderProps = {
    children: ReactNode;
};
const promises = supportedLanguages.map(language => {
    return getLanguage(language);
});

export const LanguagesProvider = ({ children }: ProviderProps) => {
    const [loading, setLoading] = useState(true);
    const [languages, setLanguages] = useState([]);

    const load = async () => {
        const result = await Promise.all(promises);
        return result.map(item => item.result);
    };

    useEffectOnce(() => {
        load().then(langs => {
            setLanguages(langs as any);
            setLoading(false);
        });
    });

    return (
        <LanguagesContext.Provider value={{ loading, languages: languages }}>
            {children}
        </LanguagesContext.Provider>
    );
};

export const useLanguages = () => useContext(LanguagesContext);
