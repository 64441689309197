import { SidebarItemsType } from '../../types/sidebar';

import {
    Activity,
    Heart,
    List,
    MessageCircle,
    MousePointer,
    Plus,
    Settings,
    Sliders,
    RefreshCw,
    UploadCloud,
    User,
} from 'react-feather';
import { createRoute } from 'utils/createRoute';

/*  children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
    ], */

const pagesSection = [
    // {
    //     href: '/dashboard',
    //     icon: Sliders,
    //     title: 'Dashboard',
    // },
    {
        href: '/dashboard/vocabs',
        icon: Heart,
        title: 'Vocabs',
        children: [
            {
                href: '/dashboard/vocabs',
                icon: List,
                title: 'All',
            },
            {
                href: '/dashboard/vocabs/add',
                icon: Plus,
                title: 'Add Vocab',
            },
            {
                href: '/dashboard/vocabs/import/csv',
                icon: UploadCloud,
                title: 'Import CSV',
            },
        ],
    },
    {
        href: '/dashboard/users',
        icon: User,
        title: 'Users',
    },
    {
        href: '/dashboard/testing',
        icon: MousePointer,
        title: 'Testing',
    },
    {
        href: '/dashboard/settings',
        icon: Settings,
        title: 'Settings',
        children: [
            {
                href: `/dashboard/settings/force-update`,
                icon: RefreshCw,
                title: 'Force Update',
            },
        ],
    },
] as SidebarItemsType[];

const navItems = [
    {
        title: 'Pages',
        pages: pagesSection,
    },
];

export default navItems;
