import { Box, useTheme, styled } from '@mui/material';
import { useAudio } from 'react-use';
import { Play, Volume2 } from 'react-feather';

type Props = {
    src: string;
};
const Icon = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

export function AudioPlayer({ src }: Props) {
    const [audio, state, controls] = useAudio({ src });

    const play: React.MouseEventHandler<HTMLDivElement> = e => {
        e.preventDefault();

        controls.play();
    };

    const pause: React.MouseEventHandler<HTMLDivElement> = e => {
        e.preventDefault();
        controls.pause();
    };

    return (
        <>
            {audio}
            {state.paused ? (
                <Icon component={Play} onClick={play} />
            ) : (
                <Icon component={Volume2} onClick={pause} />
            )}
        </>
    );
}
