import { Stack } from '@mui/material';
import { useUpdateVocabById, Vocab, Word, getGetVocabByIdQueryKey } from 'api';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'hooks/useSnackbar';
import produce from 'immer';
import { cloneDeep, isString, omit, map, sortBy } from 'lodash';
import { useQueryClient } from 'react-query';
import { FormikSubmitFunction } from 'types/formik';
import { editVocabCacheUpdate } from 'utils/cache/vocab';
import { getErrorMessage } from 'utils/getErrorMessage';
import { isJsonString } from 'utils/isJsonString';
import { withUGCBase } from 'utils/withUGCBase';
import { convertWordsIntoSelectableOptions } from './AddVocabForm';
import { AddVocabFormView as EditVocabFormView } from './AddVocabForm/AddVocabFormView';
import { AddVocabValidationSchema } from './AddVocabForm/AddVocabValidationSchema';

type Props = { vocab: Vocab; vocabs: Vocab[] };

const decideToWhetherPassIdOrGetValue = (arg: any[]) => {
    return arg.map((id: any) => {
        if (isString(id)) return id;
        return id.value;
    });
};
const isFile = (arg: any) => typeof arg?.name == 'string';
export const isStringArray = (arg: any[]) => arg.every(isString);

export function EditVocabForm({ vocab, vocabs }: Props) {
    const { mutate: updateVocab, isSuccess } = useUpdateVocabById();
    const [enqueueSnackbar] = useSnackbar();
    const queryClient = useQueryClient();
    const createInitValues = () =>
        produce<any>(vocab, ds => {
            if (isString(ds.image_path)) {
                ds.image = withUGCBase(`/${ds.image_path}`);
            }

            if (isJsonString(vocab.transcription_overrides)) {
                ds.transcription_overrides = JSON.parse(
                    vocab.transcription_overrides as any
                );
            }

            if (isJsonString(vocab.taxonomies)) {
                ds.taxonomies = JSON.parse(vocab.taxonomies as any);
            }

            if (isJsonString(vocab.definition)) {
                ds.definition = JSON.parse(vocab.definition as any);
            }

            /*  if (isJsonString(vocab.categories)) {
                ds.categories = JSON.parse(vocab.categories as any);
            } */

            /* related vocabs normalization */

            if (!isStringArray(ds.related_vocabs)) {
                ds.related_vocabs = convertWordsIntoSelectableOptions(
                    ds.related_vocabs
                );
            } else {
                ds.related_vocabs = convertWordsIntoSelectableOptions(
                    ds.related_vocabs.map((relatedVocabId: string) =>
                        vocabs.find(vocab => vocab.id === relatedVocabId)
                    )
                );
            }

            /* related vocabs normalization */
            if (!isStringArray(ds.required_words)) {
                ds.required_words = convertWordsIntoSelectableOptions(
                    ds.required_words
                );
            } else {
                ds.required_words = convertWordsIntoSelectableOptions(
                    ds.required_words.map((required_word_id: string) =>
                        vocab.linguistics?.find(
                            word => word.id === required_word_id
                        )
                    )
                );
            }

            ds.status = ds.status || 'draft';
        });
    const showSuccessMessage = () => {
        enqueueSnackbar(`Vocab edited successfully.`);
    };

    const refetchEditedVocab = () => {
        queryClient.invalidateQueries(getGetVocabByIdQueryKey(vocab.id!));
    };
    const onSubmit: FormikSubmitFunction<ReturnType<typeof createInitValues>> =
        (data, { setStatus, setSubmitting }) => {
            const getData = () => {
                const cloned = cloneDeep(data);

                cloned.required_words = decideToWhetherPassIdOrGetValue(
                    data.required_words
                );

                if (!isStringArray(cloned.related_vocabs)) {
                    cloned.related_vocabs = cloned.related_vocabs.map(
                        (item: Record<string, unknown>) => item.value
                    );
                }

                if (!isStringArray(cloned.required_words)) {
                    cloned.required_words = cloned.required_words.map(
                        (item: Record<string, unknown>) => item.value
                    );
                }

                if (isFile(data?.image?.file)) {
                    cloned.image = data?.image?.file;
                }

                return omit(cloned, ['linguistics']);
            };

            updateVocab(
                {
                    data: getData(),
                    id: vocab.id!,
                },
                {
                    onSuccess: _updatedVocab => {
                        setStatus({ sent: true, error: '' });
                        setSubmitting(false);
                        showSuccessMessage();
                        editVocabCacheUpdate(_updatedVocab);
                        refetchEditedVocab();
                    },
                    onError: (error: any) => {
                        setStatus({
                            sent: false,
                            error: getErrorMessage(error),
                        });
                        setSubmitting(false);
                    },
                }
            );
        };

    const formik = useFormik({
        initialValues: createInitValues(),
        validationSchema: AddVocabValidationSchema,
        onSubmit,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        enableReinitialize: false,
        initialStatus: {
            sent: isSuccess,
            error: '',
        },
    });

    const wordOptions = sortBy(
        convertWordsIntoSelectableOptions(vocab.linguistics!),
        ['label']
    );

    const _normalizedVocabs = convertWordsIntoSelectableOptions(vocabs);

    return (
        <Stack my={6}>
            <FormikProvider value={formik}>
                <EditVocabFormView
                    vocabs={_normalizedVocabs}
                    words={wordOptions}
                    isEdit
                />
            </FormikProvider>
        </Stack>
    );
}
