import { LanguageSchema, LinguisticToken, Word } from 'api';

export type HighlightedVocab = {
    isVocab: boolean;
    literal: string;
    index: number;
};

type Props = {
    phrase: string;
    linguistics: LinguisticToken[];
    requiredWords: any[];
    language: LanguageSchema;
};

export const highlightVocab = ({
    phrase,
    linguistics,
    requiredWords,
    language,
}: Props) => {
    const parts: HighlightedVocab[] = [];
    linguistics?.forEach((linguisticToken: Word, index: number) => {
        let { literal, lemma } = linguisticToken;
        let phrase_lower: string = phrase.toLocaleLowerCase();
        const literal_lower: string = literal!.toLocaleLowerCase();
        const currentLemma: string = language?.case_sensitive
            ? lemma!
            : lemma!.toLocaleLowerCase();

        const isVocab: boolean = requiredWords
            .map(
                (item: LinguisticToken) =>
                    (language.case_sensitive
                        ? item.lemma
                        : item.lemma?.toLocaleLowerCase()) || ''
            )
            .includes(currentLemma);

        let startsAt = Math.max(0, phrase_lower.indexOf(literal_lower!));
        const endsAt = startsAt + literal!.length;

        if (startsAt) {
            parts.push({
                isVocab: false,
                literal: phrase.slice(0, startsAt),
                index: parts.length,
            });
        }
        literal = phrase.slice(startsAt, endsAt);
        phrase = phrase.slice(endsAt);
        phrase_lower = phrase.toLowerCase();

        parts.push({ isVocab, literal, index: parts.length });
    });

    if (phrase.length) {
        parts.push({ isVocab: false, literal: phrase, index: parts.length });
    }
    return parts;
};
