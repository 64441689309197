import { TextField as OriginalTextField, TextFieldProps } from '@mui/material';
import { FieldHookConfig, useField } from 'formik';

type Props = FieldHookConfig<any> & TextFieldProps;

export function FormTextField({ name, helperText, ...props }: Props) {
    const [field, { touched, error }] = useField(name);

    return (
        <OriginalTextField
            {...field}
            fullWidth
            error={Boolean(touched && error)}
            helperText={(touched && error) || helperText}
            {...props}
        />
    );
}
