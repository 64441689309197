import { useState } from 'react';
import styled from 'styled-components/macro';
import { Grid, Paper as MuiPaper, Stack, Typography } from '@mui/material';
import { FormTextField as TextField, LemmaBar } from 'components';
import { LoadingButton } from '@mui/lab';
import { spacing, SpacingProps } from '@mui/system';
import { useFormik, FormikProvider } from 'formik';
import { getErrorMessage } from 'utils/getErrorMessage';
import type { FormikSubmitFunction } from 'types/formik';
import { parseText } from 'api';

const Paper = styled(MuiPaper)<SpacingProps>(spacing);

const initialValues = {
    literal: '',
};

export function TestParsing() {
    const [tokens, setTokens] = useState<any[]>([]);
    const onSubmit: FormikSubmitFunction<typeof initialValues> = async (
        data,
        { setStatus, setSubmitting }
    ) => {
        setSubmitting(true);
        try {
            if (!data.literal) {
                alert('Gotta type something in!');
            } else {
                const { result } = await parseText({
                    text: data.literal,
                    language: 'de',
                });
                setTokens(result);
            }
        } catch (error) {
            setStatus({
                sent: false,
                error: getErrorMessage(error),
            });
        }
        setSubmitting(false);
    };

    const formik = useFormik({
        onSubmit,
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validateOnMount: false,
        initialStatus: {
            error: '',
            sent: false,
        },
    });

    const { handleSubmit, handleChange, isSubmitting, values } = formik;

    const onChange = (event: any) => {
        if (event.target.value.trim() === '') {
            setTokens([]);
        }
        handleChange(event);
    };

    return (
        <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
                <Stack>
                    <Paper p={8}>
                        <Typography variant='h3' mb={4} gutterBottom>
                            Parsing
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <TextField
                                    name='literal'
                                    onChange={onChange}
                                    placeholder='Ein Lama isst keinen Papayasalat.'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    fullWidth
                                    loading={isSubmitting}
                                    disabled={!values.literal.trim().length}
                                    variant='contained'
                                    type='submit'
                                    sx={{ height: '100%' }}
                                >
                                    Parse!
                                </LoadingButton>
                            </Grid>
                            {tokens && (
                                <Grid item>
                                    <LemmaBar linguistics={tokens} />
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Stack>
            </form>
        </FormikProvider>
    );
}
