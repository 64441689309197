import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Card as MuiCard,
    Paper as MuiPaper,
} from '@mui/material';
import { spacing } from '@mui/system';

type Props = {
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    description: string;
    open: boolean;
};

export function ConfirmModal({
    onCancel,
    onConfirm,
    title,
    open,
    description,
}: Props) {
    return (
        <Dialog
            onClick={event => event.preventDefault()}
            onClose={onCancel}
            open={open}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant='outlined' color='primary'>
                    Cancel
                </Button>
                <Button
                    onClick={onConfirm}
                    variant='outlined'
                    color='error'
                    autoFocus
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
